import React, { lazy } from 'react';
import { Switch, Route,Redirect ,useRouteMatch} from 'react-router-dom';
import withAdminLayout from '../../layout/withAdminLayout';

const Dashboard                 = lazy(() => import('../../container/datamanagement/Dashboard'));
const DefectiveStock            = lazy(() => import('../../container/datamanagement/DefectiveStock'));
const ViewDefectiveStock        = lazy(() => import('../../container/datamanagement/ViewDefectiveStock'));

const DemoHappy                 = lazy(() => import('../../container/datamanagement/DemoHappy'));
const DemoHappySap              = lazy(() => import('../../container/datamanagement/DemoHappySAP'));
const ComplaintHappy            = lazy(() => import('../../container/datamanagement/ComplaintHappy'));
const FurnitureComplaintHappy   = lazy(() => import('../../container/datamanagement/FurnitureComplaintHappy'));
const LegalHappy                = lazy(() => import('../../container/datamanagement/LegalHappy'));
const ViewHappyDetails          = lazy(() => import('../../container/datamanagement/ViewHappyDetails'));


const RegisteredList            = lazy(() => import('../../container/datamanagement/RegisteredList'));
const DemoRegisteredList        = lazy(() => import('../../container/datamanagement/DemoRegisteredList'));
const ComplaintRegisteredList   = lazy(() => import('../../container/datamanagement/ComplaintRegisteredList'));
const ViewDetails               = lazy(() => import('../../container/datamanagement/ViewDetails'));
const EditDetails               = lazy(() => import('../../container/datamanagement/EditComplaintDetails'))

const UploadDump                = lazy(() => import('../../container/datamanagement/UploadDump'))
const UploadDumpStatus          = lazy(() => import('../../container/datamanagement/UploadReport'))

const FollowSchedule            = lazy(() => import('../../container/datamanagement/FollowSchedule'));
const FollowPriority            = lazy(() => import('../../container/datamanagement/FollowPriority'));

const DemoHappySAPPriority      = lazy(() => import('../../container/datamanagement/DemoHappySAPPriority'));
const DemoHappySAPSchedule      = lazy(() => import('../../container/datamanagement/DemoHappySAPSchedule'));
const ViewHappySAPDetails       = lazy(() => import('../../container/datamanagement/ViewHappySAPDetails')); 


const FollowLegal               = lazy(() => import('../../container/datamanagement/FollowLegal'));
const ViewFollowDetails         = lazy(() => import('../../container/datamanagement/ViewFollowDetails'));

const AccessoriesTeleSalesSAP   = lazy(() => import('../../container/datamanagement/AccessoriesTeleSalesSAP'))
const ViewAccessoriesTeleSales  = lazy(() => import('../../container/datamanagement/ViewAccessoriesTeleSales'));

const MandatoryService          = lazy(() => import('../../container/datamanagement/MandatoryService'));
const ViewMandatoryService      = lazy(() => import('../../container/datamanagement/ViewMandatoryService'));

const AbandonedCart             = lazy(() => import('../../container/datamanagement/AbandonedCart'));
const ViewAbandonedCart         = lazy(() => import('../../container/datamanagement/ViewAbandonedCart'));


const Report                    = lazy(() => import('../../container/datamanagement/NewReport'));
const DownloadReport            = lazy(() => import('../../container/datamanagement/DownloadReport'));


function NotFound() {
    return <Redirect to="/admin" />;
}

function DataRoutes() {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={`${path}`} component={Dashboard} />
			<Route exact path={`${path}/dashboard`} component={Dashboard} />

            <Route exact path={`${path}/follow/:id`} component={RegisteredList} />
            <Route exact path={`${path}/follow/:id/view`} component={ViewDetails} />
            <Route exact path={`${path}/follow/:id/edit`} component={EditDetails} />

            <Route exact path={`${path}/complaint_register/:id`} component={ComplaintRegisteredList} />
            <Route exact path={`${path}/complaint_register/:id/view`} component={ViewDetails} />
            <Route exact path={`${path}/complaint_register/:id/edit`} component={EditDetails} />


            <Route exact path={`${path}/demo_register/:id`} component={DemoRegisteredList} />
            <Route exact path={`${path}/demo_register/:id/view`} component={ViewDetails} />
            <Route exact path={`${path}/demo_register/:id/edit`} component={EditDetails} />

            
            <Route exact path={`${path}/defective_stock`} component={DefectiveStock} />
            <Route exact path={`${path}/defective_stock/view`} component={ViewDefectiveStock} />
            
            <Route exact path={`${path}/mandatory_service`} component={MandatoryService} />
            <Route exact path={`${path}/mandatory_service/view`} component={ViewMandatoryService} />
            
            <Route exact path={`${path}/abandoned_cart`} component={AbandonedCart} />
            <Route exact path={`${path}/abandoned_cart/view`} component={ViewAbandonedCart} />

            <Route exact path={`${path}/accessories_tele_sales_sap`} component={AccessoriesTeleSalesSAP} />
            <Route exact path={`${path}/accessories_tele_sales_sap/view`} component={ViewAccessoriesTeleSales} />
            
            <Route exact path={`${path}/demo_happy`} component={DemoHappy} />
            <Route exact path={`${path}/demo_happy/view`} component={ViewHappyDetails} />

            <Route exact path={`${path}/demo_happy_sap`} component={DemoHappySap} />
            <Route exact path={`${path}/demo_happy_sap/view`} component={ViewHappySAPDetails} />
            
            <Route exact path={`${path}/complaint_happy`} component={ComplaintHappy} />
            <Route exact path={`${path}/complaint_happy/view`} component={ViewHappyDetails} />

            <Route exact path={`${path}/furniture_complaint_happy`} component={FurnitureComplaintHappy} />
            <Route exact path={`${path}/furniture_complaint_happy/view`} component={ViewHappyDetails} />

            <Route exact path={`${path}/legal_case_happy`} component={LegalHappy} />
            <Route exact path={`${path}/legal_case_happy/view`} component={ViewHappyDetails} />

            <Route exact path={`${path}/legal_case_log`} component={FollowLegal} />
            <Route exact path={`${path}/legal_case_log/view`} component={ViewFollowDetails} />

            <Route exact path={`${path}/schedule_log`} component={FollowSchedule} />
            <Route exact path={`${path}/schedule_log/view`} component={ViewFollowDetails} />

            <Route exact path={`${path}/priority_log`} component={FollowPriority} />
            <Route exact path={`${path}/priority_log/view`} component={ViewFollowDetails} />

            <Route exact path={`${path}/sap_schedule_log`} component={DemoHappySAPSchedule} />
            <Route exact path={`${path}/sap_schedule_log/view`} component={ViewHappySAPDetails} />

            <Route exact path={`${path}/sap_priority_log`} component={DemoHappySAPPriority} />
            <Route exact path={`${path}/sap_priority_log/view`} component={ViewHappySAPDetails} />

            <Route exact path={`${path}/upload_dump`} component={UploadDump} />
            <Route exact path={`${path}/upload_dump_status`} component={UploadDumpStatus} />


            
            
            
            
            <Route exact path={`${path}/report_register`} component={Report} />
            <Route exact path={`${path}/report_happy`} component={Report} />
            <Route exact path={`${path}/report_follow`} component={Report} />
            <Route exact path={`${path}/report_tele`} component={Report} />


            <Route exact path={`${path}/available_report`} component={DownloadReport} />
            <Route path="*" exact={true} component={NotFound} />
        </Switch>
    );
}

export default withAdminLayout(DataRoutes);