import React, { lazy } from 'react';
import { Switch, Route,useRouteMatch,Redirect } from 'react-router-dom';
import withAdminLayout from '../../layout/withAdminLayout';

const Profile           = lazy(() => import('../../container/profile/Index'));
const Account           = lazy(() => import('../../container/profile/Account'));
const ChangePassword    = lazy(() => import('../../container/profile/ChangePassword'));
const Notification      = lazy(() => import('../../container/profile/Notification'));


function NotFound() {
    return <Redirect to="/admin" />;
}

function ProfileRoutes() {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={`${path}`} component={Profile} />
            <Route exact path={`${path}/profile`} component={Profile} />
            <Route exact path={`${path}/setting`} component={Account} />
            <Route exact path={`${path}/change_password`} component={ChangePassword} />
            <Route exact path={`${path}/notification`} component={Notification} />
			<Route path="*" exact={true} component={NotFound} />
        </Switch>
    );
}

export default withAdminLayout(ProfileRoutes);
