import React, { useState, useEffect } from 'react';

import {InstallPopupDiv}  from './style';

const InstallPopup = () => {
    const [showPopup, setShowPopup] = useState(false);
    const [deferredPrompt, setDeferredPrompt] = useState(null);

    useEffect(() => {
        const handleBeforeInstallPrompt = (event) => {
            event.preventDefault();
            setDeferredPrompt(event);
            setShowPopup(true);
        };

        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

        return () => {
            window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        };
    }, []);

    const handleInstallClick = () => {
        if (deferredPrompt) {
            deferredPrompt.prompt();
            deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    // console.log('User accepted the install prompt');
                }
                setShowPopup(false);
            });
        }
    };

    return (
        <InstallPopupDiv>
            <div className={`install-popup  ${showPopup ? 'show' : ''}`}>
                <div className="popup-content">
                    <p>Install this app to your home screen.</p>
                    <button onClick={handleInstallClick}>Install</button>
                    <button onClick={() => setShowPopup(false)}>Dismiss</button>
                </div>
            </div>
        </InstallPopupDiv>
    );
};

export default InstallPopup;
