import React, {useEffect, useState} from 'react';
import { Row, Col, Select, Form,  notification, Table, Modal, Input, Alert } from 'antd';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Cards } from '../../components/cards/frame/cards-frame';
import { Button } from '../../components/buttons/buttons';
import { Main, BasicFormWrapper, HorizontalFormStyleWrap, TableWrapper} from '../styled';
import { getBranchList, getCategoryList } from '../../redux/common/actionCreator';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getEmployeeReport, getEmployeeReportSave } from '../../redux/employee/actionCreator';
import { capitalize } from '../../utility/localStorageControl';

const Report = () => {
    const [form] = Form.useForm();
    
    const dispatch = useDispatch();

    useEffect(() => {
        if (getBranchList) {
            dispatch(getBranchList());
            dispatch(getCategoryList());
        }
    }, [dispatch]);

    const branchDetails      = useSelector((state) => state.branch.data);
    const categoryDetails       = useSelector((state) => state.category.data);

    const employeeReportData 	 = useSelector((state) => state.employeeReport);
    const walkoutReportSaveData 	 = useSelector((state) => state.walkoutReportSave);
    // eslint-disable-next-line

    const [loading, setLoading] = useState(false); 

    const handleSubmit = (value) => {
        try {
            dispatch(getEmployeeReport(value));
        } catch (error) {
            notification.error({
                message: error,
            });
            console.error('Form submission failed:', error);
        } finally {
            setLoading(false); // Reset loading state after form submission is complete
        }
    }

    const [tableData,setTableData] = useState([]);

    const columns = [
        {
            title: 'Employee ID',
            dataIndex: 'employeeId',
            key: 'employeeId',
            defaultSortOrder: 'ascend',
            sorter: (a, b) => a.employeeId <  b.employeeId ? -1 : 1,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Employee Name',
            dataIndex: 'employeeName',
            key: 'employeeName',
        },
        {
            title: 'Mobile',
            dataIndex: 'mobile',
            key: 'mobile'
        },
        {
            title: 'Branch',
            dataIndex: 'branch',
            key: 'branch'
        },
        {
            title: 'Category',
            dataIndex: 'category',
            key: 'category',
            sorter: (a, b) => a.category <  b.category ? -1 : 1,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'User Type',
            dataIndex: 'userType',
            key: 'userType'
        },
        {
            title:'Is Login',
            dataIndex: 'is_login',
            key: 'is_login'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status'
        }
    ];

    useEffect(() => {
        const dataTable = [];
        if (employeeReportData.data && employeeReportData.data.object_list ){
            employeeReportData.data.object_list.map((value) => {
                const { id, email,  employee_id, is_login,mobile,name,user_type,username,status,branch,employee_category,sap_status } = value;
                return dataTable.push({
                    key: id,
                    employeeId: employee_id,
                    employeeName: capitalize(name),
                    mobile: mobile,
                    email: email,
                    category: employee_category?.name,
                    branch:branch?.name+' - '+branch?.code,
                    userType:capitalize(user_type),
                    is_login : is_login,
                    status: status
                });
            });
        }
        if(dataTable.length > 0){
            setTableData(dataTable);
        }
    },[employeeReportData])


    const [visible, setVisible] = useState(false);

	const showModal = () => {
		setVisible(true);
	};
	
	const handleOk = () => {
		form.validateFields().then((values) => {
            dispatch(getEmployeeReportSave(values));
		  // Call API to submit form data
		  // After successful submission, close the modal
		  setVisible(false);
		}).catch((errorInfo) => {
		  	console.log('Validation failed:', errorInfo);
		});
	};
	
	const handleCancel = () => {
		setVisible(false);
	};

    
    return (
        <>
            <PageHeader ghost title="Employee Report"/>
            <Main>
                <Row gutter={25}>
                    <Col xs={24}>
                        <Cards headless>
                            <BasicFormWrapper>
                                <HorizontalFormStyleWrap>
                                    <Form name="horizontal-form" className="ant-form-horizontal" form={form} layout="vertical" onFinish={handleSubmit} >
                                        <Row gutter={30}>
                                            <Col md={6} xs={24}>
                                                <Form.Item name="category" label="Category">
                                                    <Select 
                                                        mode="multiple"
                                                        placeholder="Please choose the Category" 
                                                        options={categoryDetails && categoryDetails.map(data => ({ label: data.title, value: data.id }))}
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => ((option?.label).toLowerCase() ?? '').includes(input.toLowerCase())}
                                                        filterSort={(optionA, optionB) =>
                                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                                        }
                                                        showSearch
                                                        showArrow={true}
                                                        notFoundContent={null}
                                                        allowClear
                                                    >
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col md={6} xs={24}>
                                                <Form.Item name="branch" label="Branch">
                                                    <Select 
                                                        mode="multiple"
                                                        placeholder="Please choose the Branch" 
                                                        options={branchDetails && branchDetails.map(data => ({ label: data.name, value: data.id }))}
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => ((option?.label).toLowerCase() ?? '').includes(input.toLowerCase())}
                                                        filterSort={(optionA, optionB) =>
                                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                                        }
                                                        showSearch
                                                        showArrow={true}
                                                        notFoundContent={null}
                                                        allowClear
                                                    >
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col md={2} xs={24}>
                                                <div className="mt-30">
                                                    <button type="submit" className="ant-btn ant-btn-primary ant-btn-lg" disabled={loading}>
                                                        Submit
                                                    </button>
                                                </div>
                                            </Col>
                                            { employeeReportData.data?.object_list && employeeReportData.data?.object_list.length > 0 && (
                                            <Col md={2} xs={24}>
                                                <div className="mt-30">
                                                    <button className="ant-btn ant-btn-info"  onClick={showModal}>
                                                        <FontAwesomeIcon icon="fa-solid fa-download" /> Download
                                                    </button>
                                                </div>
                                            </Col>
                                            )}
                                            { employeeReportData?.data?.object_list && employeeReportData.data?.object_list.length === 0 && (
                                                <Col md={24} xs={24}>
                                                    <Alert message="Error" description="No Record Found" type="error" />
                                                </Col>
                                            )}
                                        </Row>
                                    </Form>
                                </HorizontalFormStyleWrap>
                            </BasicFormWrapper>
                        </Cards>
                    </Col>
                </Row>

                { employeeReportData.data?.object_list && employeeReportData.data?.object_list.length > 0 && (
                    <>
                        <Row gutter={25}>
                            <Col sm={24} md={24}>
                                <TableWrapper className="table-responsive">
                                    <Table  columns={columns} pagination={true} dataSource={tableData} />
                                </TableWrapper>
                            </Col>
                        </Row>
                    </>
                )}

                <Modal
					open={visible}
					onOk={handleOk}
					onCancel={handleCancel}
				>
					<Form form={form} layout="vertical">
						<Form.Item name="name" label="File Name"
							rules={[
								{
									required: true,
									message: 'please enter File Name',
								},
							]}
						>
							<Input placeholder="Please enter File Name" />
						</Form.Item>
					</Form>
				</Modal>

            </Main>
        </>
    )
}

export default Report