import actions from './actions';

const { 
    DATA_BEGIN, DATA_SUCCESS, DATA_ERR,
    SALES_REPORT_DATA_BEGIN, SALES_REPORT_DATA_SUCCESS, SALES_REPORT_DATA_ERR,
    SALES_REPORT_SAVE_DATA_BEGIN, SALES_REPORT_SAVE_DATA_SUCCESS, SALES_REPORT_SAVE_DATA_ERR, 
    SALES_REPORT_DOWNLOAD_DATA_BEGIN, SALES_REPORT_DOWNLOAD_DATA_SUCCESS, SALES_REPORT_DOWNLOAD_DATA_ERR, 
} = actions;

const initialState = { data: [], loading: false, error: null, };

const salesReducer = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case DATA_SUCCESS:
            return {
                ...initialState,
                data,
                error: null,
                loading: false,
            };
        case DATA_ERR:
            return {
                ...initialState,
                error: err,
                data: null,
                loading: false,
            };
        default:
            return state;
    }
};


const salesReportReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case SALES_REPORT_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case SALES_REPORT_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case SALES_REPORT_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const salesReportSaveReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case SALES_REPORT_SAVE_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case SALES_REPORT_SAVE_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case SALES_REPORT_SAVE_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const salesReportDownloadReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case SALES_REPORT_DOWNLOAD_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case SALES_REPORT_DOWNLOAD_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case SALES_REPORT_DOWNLOAD_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};


export { salesReducer,salesReportReducers,salesReportSaveReducers, salesReportDownloadReducers };
