import React from 'react';
import { NavLink } from 'react-router-dom';
import { Main } from '../container/styled';
import { Button } from '../components/buttons/buttons';
import { ErrorWrapper } from './style';
import Heading from '../components/heading/heading';


const NotFound = () => (
    <>
        <Main>
            <ErrorWrapper>
                <img src={require(`../static/img/pages/404.svg`).default} alt="404" />
                <Heading className="error-text" as="h3">
                    404
                </Heading>
                <p>Sorry! the page you are looking for does n`t exist.</p>
                <NavLink to="/admin">
                <Button size="default" type="primary" to="/admin">
                    Return Home
                </Button>
                </NavLink>
            </ErrorWrapper>
        </Main>
    </>
    
);

export default NotFound;
