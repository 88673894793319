import Cookies from 'js-cookie';
import actions from './actions';
import { GuestService } from '../../config/data/guestService';
import { DataService } from '../../config/data/dataService';
const { initial,loginBegin, loginSuccess, loginErr, logoutBegin, logoutSuccess, logoutErr, forgotBegin, forgotSuccess, forgotErr, otpBegin, otpSuccess, otpErr, changeBegin, changeSuccess, changeErr } = actions;

const login = (data) => {
    return async dispatch => {
        try {
            dispatch(loginBegin());
            const response = await GuestService.post('/login', data);
            if (response.status === 200 && response.data.status === true) {
                setTimeout(() => {
                    Cookies.set('loggedIn', true);
                    Cookies.set('loggedData', JSON.stringify(response.data));
                    dispatch(loginSuccess(true,response.data));
                },0);
            } else {
                dispatch(loginErr(response.data.message));
            }
        } catch (err) {
            dispatch(loginErr(err));
        }
    };
};

const sendOtpForgot = (data) => {
    return async dispatch => {
        try {
            dispatch(forgotBegin());
            const response = await GuestService.post('/sendForgotOTP', data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(forgotSuccess(true,data.username));
            } else {
                dispatch(forgotErr(response.data.message));
            }
        } catch (err) {
            dispatch(forgotErr(err));
        }
    };
}

const verifyOtpForgot = (data) => {
    return async dispatch => {
        try {
            dispatch(otpBegin());
            const response = await GuestService.post('/sendVerifiedOTP', data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(otpSuccess(true));
            } else {
                dispatch(otpErr(response.data.message));
            }
        } catch (err) {
            dispatch(otpErr(err));
        }
    };
}

const changePassword = (data) => {
    return async dispatch => {
        try {
            dispatch(changeBegin());
            const response = await GuestService.post('/changePasswordforgot', data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(changeSuccess(true));
            } else {
                dispatch(changeErr(response.data.message));
            }
        } catch (err) {
            dispatch(changeErr(err));
        }
    };
}

const logOut = () => {
    return async (dispatch) => {
        try {
            dispatch(logoutBegin());
            await GuestService.delete('/logout').then((response) => {
                if(response.data.status === true){
                    Cookies.remove('loggedIn');
                    Cookies.remove('loggedData');
                    Cookies.remove('agentStatus');
                    dispatch(logoutSuccess(true));
                }
            })
            .catch((error) => {
                dispatch(logoutErr(error));
            })
            
        } catch (err) {
            dispatch(logoutErr(err));
        }
    };
};

const firebaseTokenUpdate = (data) => {
    return async dispatch => {
        try {
            await DataService.post('/firebaseTokenUpdate', data);
        } catch (err) {
            // dispatch(loginErr(err));
        }
    };
}


const forgotInitialState = () =>{
    return async dispatch => {
        dispatch(initial());
    };
}

export { login, logOut,firebaseTokenUpdate, sendOtpForgot, verifyOtpForgot, changePassword,forgotInitialState};
