const actions = {
    DATA_BEGIN: 'DATA_BEGIN',
    DATA_SUCCESS: 'DATA_SUCCESS',
    DATA_ERR: 'DATA_ERR',

    FILTER_BEGIN: 'FILTER_BEGIN',
    FILTER_SUCCESS: 'FILTER_SUCCESS',
    FILTER_ERR: 'FILTER_ERR',

    SORTING_BEGIN: 'SORTING_BEGIN',
    SORTING_SUCCESS: 'SORTING_SUCCESS',
    SORTING_ERR: 'SORTING_ERR',

    STORE_REPORT_DATA_BEGIN : 'STORE_REPORT_DATA_BEGIN',
    STORE_REPORT_DATA_SUCCESS: 'STORE_REPORT_DATA_SUCCESS',
    STORE_REPORT_DATA_ERR : 'STORE_REPORT_DATA_ERR',

    STORE_REPORT_SAVE_DATA_BEGIN : 'STORE_REPORT_SAVE_DATA_BEGIN',
    STORE_REPORT_SAVE_DATA_SUCCESS: 'STORE_REPORT_SAVE_DATA_SUCCESS',
    STORE_REPORT_SAVE_DATA_ERR : 'STORE_REPORT_SAVE_DATA_ERR',

    STORE_REPORT_DOWNLOAD_DATA_BEGIN : 'STORE_REPORT_DOWNLOAD_DATA_BEGIN',
    STORE_REPORT_DOWNLOAD_DATA_SUCCESS: 'STORE_REPORT_DOWNLOAD_DATA_SUCCESS',
    STORE_REPORT_DOWNLOAD_DATA_ERR : 'STORE_REPORT_DOWNLOAD_DATA_ERR',
    

    dataBegin: () => {
        return {
            type: actions.DATA_BEGIN,
        };
    },

    dataSuccess: (data) => {
        return {
            type: actions.DATA_SUCCESS,
            data,
        };
    },

    dataErr: (err) => {
        return {
            type: actions.DATA_ERR,
            err,
        };
    },

    storeReportDataBegin: () => {
        return {
            type: actions.STORE_REPORT_DATA_BEGIN,
        };
    },

    storeReportDataSuccess: (data) => {
        return {
            type: actions.STORE_REPORT_DATA_SUCCESS,
            data,
        };
    },

    storeReportDataErr: (err) => {
        return {
            type: actions.STORE_REPORT_DATA_ERR,
            err,
        };
    },

    storeReportSaveDataBegin: () => {
        return {
            type: actions.STORE_REPORT_SAVE_DATA_BEGIN,
        };
    },

    storeReportSaveDataSuccess: (data) => {
        return {
            type: actions.STORE_REPORT_SAVE_DATA_SUCCESS,
            data,
        };
    },

    storeReportSaveDataErr: (err) => {
        return {
            type: actions.STORE_REPORT_SAVE_DATA_ERR,
            err,
        };
    },

    storeReportDownloadDataBegin: () => {
        return {
            type: actions.STORE_REPORT_DOWNLOAD_DATA_BEGIN,
        };
    },

    storeReportDownloadDataSuccess: (data) => {
        return {
            type: actions.STORE_REPORT_DOWNLOAD_DATA_SUCCESS,
            data,
        };
    },

    storeReportDownloadDataErr: (err) => {
        return {
            type: actions.STORE_REPORT_DOWNLOAD_DATA_ERR,
            err,
        };
    },
};


export default actions;
