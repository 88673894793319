import React, { useEffect,useState }  from 'react';
import { Avatar } from 'antd';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import FeatherIcon from 'feather-icons-react';
import { InfoWraper, UserDropDwon } from './auth-info-style';
import { Popover } from '../../popup/popup';
import { logOut } from '../../../redux/authentication/actionCreator';
import Heading from '../../heading/heading';
import Notification from './notification';
import CallStatus from './callstatus';

function AuthInfo() {
    const dispatch = useDispatch();
    
    const SignOut = e => {
        e.preventDefault();
        dispatch(logOut());
    };

    const userDetails = useSelector((state) => state.auth.loginData);
    useEffect(() => {
        if(!userDetails.name){
            dispatch(logOut());
        }
        // eslint-disable-next-line
    },[userDetails])


    const userContent = (
        <UserDropDwon>
            <div className="user-dropdwon">
                <figure className="user-dropdwon__info">
                    <img src={require(`../../../static/img/logo.jpeg`)} alt="User" width={30} />
                    <figcaption>
                        <Heading as="h5">{userDetails?.name ? userDetails.name.toUpperCase():''}</Heading>
                        {/* <p>UI Expert</p> */}
                    </figcaption>
                </figure>
                <ul className="user-dropdwon__links">
                    {/* <li>
                        <Link to="/admin/profile">
                        <FeatherIcon icon="user" /> Profile
                        </Link>
                    </li> */}
                    <li>
                        <Link to="/admin/profile/change_password">
                            <FeatherIcon icon="lock" /> Change Password  
                        </Link>
                    </li>
                </ul>
                <Link className="user-dropdwon__bottomAction" onClick={SignOut} to="#">
                    <FeatherIcon icon="log-out" /> Sign Out
                </Link>
            </div>
        </UserDropDwon>
    );

    const agentStatus = useSelector((state) => state.agentStatus.data);

    const [agentSignIn,setAgentSignIn]  = useState();

    useEffect(() => {
        setAgentSignIn(agentStatus)
    },[agentStatus])

    return (
        <InfoWraper>
            { agentSignIn && (agentSignIn.type === 'in-bound' || agentSignIn.type === 'out-bound')  && agentSignIn.current_status === 'Available' && ( 
                <CallStatus agentStatus={agentStatus} />
            )}
            <Notification />
            <div className="nav-author">
                <Popover placement="bottomRight" content={userContent} action="click">
                    <Link to="#" className="head-example">
                        <Avatar src={require(`../../../static/img/logo.jpeg`)} alt="admin user" />
                    </Link>
                </Popover>
            </div>
        </InfoWraper>
    );
}

export default AuthInfo;
