import React, { lazy } from 'react';
import { Switch, Route,useRouteMatch, Redirect } from 'react-router-dom';
import withAdminLayout from '../../layout/withAdminLayout';

const Dashboard         = lazy(() => import('../../container/store/Dashboard'));
const StoreList      = lazy(() => import('../../container/store/StoreList'));
const Report            = lazy(() => import('../../container/store/Report'));
const DownloadReport    = lazy(() => import('../../container/store/DownloadReport'));

function NotFound() {
    return <Redirect to="/admin" />;
}


function StoreRoutes() {
    const { path } = useRouteMatch();
    return (
        <Switch>
			<Route exact path={`${path}/dashboard`} component={Dashboard} />
            <Route exact path={`${path}/store_list`} component={StoreList} />
            <Route exact path={`${path}/report`} component={Report} />
            <Route exact path={`${path}/available_report`} component={DownloadReport} />
            <Route path="*" exact={true} component={NotFound} />
        </Switch>
    );
}

export default withAdminLayout(StoreRoutes);
