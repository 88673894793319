import { theme, darkTheme } from './theme/themeVariables';

const config = {
    darkMode: false,
    topMenu: true,
    rtl: false,
    rtlData: false,
    theme,
    darkTheme,
    auth0: true,
};

export default config;
