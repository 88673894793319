const actions = {

    CHANNEL_BEGIN: 'CHANNEL_BEGIN',
    CHANNEL_SUCCESS: 'CHANNEL_SUCCESS',
    CHANNEL_ERR: 'CHANNEL_ERR',

    LIVE_CALL_LIST_BEGIN: 'LIVE_CALL_LIST_BEGIN',
    LIVE_CALL_LIST_SUCCESS: 'LIVE_CALL_LIST_SUCCESS',
    LIVE_CALL_LIST_ERR: 'LIVE_CALL_LIST_ERR',

    CALL_MONITER_REPORT_BEGIN: 'CALL_MONITER_REPORT_BEGIN',
    CALL_MONITER_REPORT_SUCCESS: 'CALL_MONITER_REPORT_SUCCESS',
    CALL_MONITER_REPORT_ERR: 'CALL_MONITER_REPORT_ERR',

    CALL_MARGE_REPORT_BEGIN: 'CALL_MARGE_REPORT_BEGIN',
    CALL_MARGE_REPORT_SUCCESS: 'CALL_MARGE_REPORT_SUCCESS',
    CALL_MARGE_REPORT_ERR: 'CALL_MARGE_REPORT_ERR',

    INCOMING_VOIP_REPORT_BEGIN: 'INCOMING_VOIP_REPORT_BEGIN',
    INCOMING_VOIP_REPORT_SUCCESS: 'INCOMING_VOIP_REPORT_SUCCESS',
    INCOMING_VOIP_REPORT_ERR: 'INCOMING_VOIP_REPORT_ERR',

    SIGNIN_SIGNOUT_REPORT_BEGIN: 'SIGNIN_SIGNOUT_REPORT_BEGIN',
    SIGNIN_SIGNOUT_REPORT_SUCCESS: 'SIGNIN_SIGNOUT_REPORT_SUCCESS',
    SIGNIN_SIGNOUT_REPORT_ERR: 'SIGNIN_SIGNOUT_REPORT_ERR',

    CALL_ACTION_DATA_BEGIN: 'CALL_ACTION_DATA_BEGIN',
    CALL_ACTION_DATA_SUCCESS: 'CALL_ACTION_DATA_SUCCESS',
    CALL_ACTION_DATA_ERR: 'CALL_ACTION_DATA_ERR',

    

    HOURS_REPORT_BEGIN: 'HOURS_REPORT_BEGIN',
    HOURS_REPORT_SUCCESS: 'HOURS_REPORT_SUCCESS',
    HOURS_REPORT_ERR: 'HOURS_REPORT_ERR',

    REQUEST_TYPE_BEGIN: 'REQUEST_TYPE_BEGIN',
    REQUEST_TYPE_SUCCESS: 'REQUEST_TYPE_SUCCESS',
    REQUEST_TYPE_ERR: 'REQUEST_TYPE_ERR',

    REQUEST_TYPE_SUBMIT_BEGIN: 'REQUEST_TYPE_SUBMIT_BEGIN',
    REQUEST_TYPE_SUBMIT_SUCCESS: 'REQUEST_TYPE_SUBMIT_SUCCESS',
    REQUEST_TYPE_SUBMIT_ERR: 'REQUEST_TYPE_SUBMIT_ERR',

    SUB_REQUEST_TYPE_BEGIN: 'SUB_REQUEST_TYPE_BEGIN',
    SUB_REQUEST_TYPE_SUCCESS: 'SUB_REQUEST_TYPE_SUCCESS',
    SUB_REQUEST_TYPE_ERR: 'SUB_REQUEST_TYPE_ERR',

    SUB_REQUEST_TYPE_SUBMIT_BEGIN: 'SUB_REQUEST_TYPE_SUBMIT_BEGIN',
    SUB_REQUEST_TYPE_SUBMIT_SUCCESS: 'SUB_REQUEST_TYPE_SUBMIT_SUCCESS',
    SUB_REQUEST_TYPE_SUBMIT_ERR: 'SUB_REQUEST_TYPE_SUBMIT_ERR',

    AGENT_FORCE_LOGOUT_BEGIN: 'AGENT_FORCE_LOGOUT_BEGIN',
    AGENT_FORCE_LOGOUT_SUCCESS: 'AGENT_FORCE_LOGOUT_SUCCESS',
    AGENT_FORCE_LOGOUT_ERR: 'AGENT_FORCE_LOGOUT_ERR',
    AGENT_FORCE_LOGOUT_RESET:'AGENT_FORCE_LOGOUT_RESET',
    
    channelBegin: () => {
        return {
            type: actions.CHANNEL_BEGIN,
        };
    },

    channelSuccess: (data) => {
        return {
            type: actions.CHANNEL_SUCCESS,
            data,
        };
    },

    channelErr: (err) => {
        return {
            type: actions.CHANNEL_ERR,
            err,
        };
    },

    liveCallListBegin: () => {
        return {
            type: actions.LIVE_CALL_LIST_BEGIN,
        };
    },

    liveCallListSuccess: (data) => {
        return {
            type: actions.LIVE_CALL_LIST_SUCCESS,
            data,
        };
    },

    liveCallListErr: (err) => {
        return {
            type: actions.LIVE_CALL_LIST_ERR,
            err,
        };
    },

    callMoniterReportBegin: () => {
        return {
            type: actions.CALL_MONITER_REPORT_BEGIN,
        };
    },

    callMoniterReportSuccess: (data) => {
        return {
            type: actions.CALL_MONITER_REPORT_SUCCESS,
            data,
        };
    },

    callMoniterReportErr: (err) => {
        return {
            type: actions.CALL_MONITER_REPORT_ERR,
            err,
        };
    },

    callMargeReportBegin: () => {
        return {
            type: actions.CALL_MARGE_REPORT_BEGIN,
        };
    },

    callMargeReportSuccess: (data) => {
        return {
            type: actions.CALL_MARGE_REPORT_SUCCESS,
            data,
        };
    },

    callMargeReportErr: (err) => {
        return {
            type: actions.CALL_MARGE_REPORT_ERR,
            err,
        };
    },

    incomingVOIPReportBegin : () => {
        return {
            type: actions.INCOMING_VOIP_REPORT_BEGIN,
        };
    },

    incomingVOIPReportSuccess: (data) => {
        return {
            type: actions.INCOMING_VOIP_REPORT_SUCCESS,
            data,
        };
    },

    incomingVOIPReportErr: (err) => {
        return {
            type: actions.INCOMING_VOIP_REPORT_ERR,
            err,
        };
    },

    signInSignOutReportBegin: () => {
        return {
            type: actions.SIGNIN_SIGNOUT_REPORT_BEGIN,
        };
    },

    signInSignOutReportSuccess: (data) => {
        return {
            type: actions.SIGNIN_SIGNOUT_REPORT_SUCCESS,
            data,
        };
    },

    signInSignOutReportErr: (err) => {
        return {
            type: actions.SIGNIN_SIGNOUT_REPORT_ERR,
            err,
        };
    },

    callActionDataBegin: () => {
        return {
            type: actions.CALL_ACTION_DATA_BEGIN,
        };
    },

    callActionDataSuccess: (data) => {
        return {
            type: actions.CALL_ACTION_DATA_SUCCESS,
            data,
        };
    },

    callActionDataErr: (err) => {
        return {
            type: actions.CALL_ACTION_DATA_ERR,
            err,
        };
    },

    hoursReportBegin: () => {
        return {
            type: actions.HOURS_REPORT_BEGIN,
        };
    },

    hoursReportSuccess: (data) => {
        return {
            type: actions.HOURS_REPORT_SUCCESS,
            data,
        };
    },

    hoursReportErr: (err) => {
        return {
            type: actions.HOURS_REPORT_ERR,
            err,
        };
    },

    requestTypeBegin: () => {
        return {
            type: actions.REQUEST_TYPE_BEGIN,
        };
    },

    requestTypeSuccess: (data) => {
        return {
            type: actions.REQUEST_TYPE_SUCCESS,
            data,
        };
    },

    requestTypeErr: (err) => {
        return {
            type: actions.REQUEST_TYPE_ERR,
            err,
        };
    },

    requestTypeSubmitBegin: () => {
        return {
            type: actions.REQUEST_TYPE_SUBMIT_BEGIN,
        };
    },

    requestTypeSubmitSuccess: (data) => {
        return {
            type: actions.REQUEST_TYPE_SUBMIT_SUCCESS,
            data,
        };
    },

    requestTypeSubmitErr: (err) => {
        return {
            type: actions.REQUEST_TYPE_SUBMIT_ERR,
            err,
        };
    },

    requestSubTypeBegin: () => {
        return {
            type: actions.SUB_REQUEST_TYPE_BEGIN,
        };
    },

    requestSubTypeSuccess: (data) => {
        return {
            type: actions.SUB_REQUEST_TYPE_SUCCESS,
            data,
        };
    },

    requestSubTypeErr: (err) => {
        return {
            type: actions.SUB_REQUEST_TYPE_ERR,
            err,
        };
    },

    requestSubTypeSubmitBegin: () => {
        return {
            type: actions.SUB_REQUEST_TYPE_SUBMIT_BEGIN,
        };
    },

    requestSubTypeSubmitSuccess: (data) => {
        return {
            type: actions.SUB_REQUEST_TYPE_SUBMIT_SUCCESS,
            data,
        };
    },

    requestSubTypeSubmitErr: (err) => {
        return {
            type: actions.SUB_REQUEST_TYPE_SUBMIT_ERR,
            err,
        };
    },
    
    agentForceLogoutBegin: () => {
        return {
            type: actions.AGENT_FORCE_LOGOUT_BEGIN,
        };
    },

    agentForceLogoutSuccess: (data) => {
        return {
            type: actions.AGENT_FORCE_LOGOUT_SUCCESS,
            data,
        };
    },

    agentForceLogoutErr: (err) => {
        return {
            type: actions.AGENT_FORCE_LOGOUT_ERR,
            err,
        };
    },

    agentForceLogoutReset: (err) => {
        return {
            type: actions.AGENT_FORCE_LOGOUT_RESET,
        };
    },
    
};

export default actions;
