const actions = {

    INVENTORY_DATA_BEGIN : 'INVENTORY_DATA_BEGIN',
    INVENTORY_DATA_SUCCESS: 'INVENTORY_DATA_SUCCESS',
    INVENTORY_DATA_ERR : 'INVENTORY_DATA_ERR',

    INVENTORY_REQUEST_DATA_BEGIN : 'INVENTORY_REQUEST_DATA_BEGIN',
    INVENTORY_REQUEST_DATA_SUCCESS: 'INVENTORY_REQUEST_DATA_SUCCESS',
    INVENTORY_REQUEST_DATA_ERR : 'INVENTORY_REQUEST_DATA_ERR',

    INVENTORY_REPORT_DATA_BEGIN : 'INVENTORY_REPORT_DATA_BEGIN',
    INVENTORY_REPORT_DATA_SUCCESS: 'INVENTORY_REPORT_DATA_SUCCESS',
    INVENTORY_REPORT_DATA_ERR : 'INVENTORY_REPORT_DATA_ERR',

    INVENTORY_REPORT_SAVE_DATA_BEGIN : 'INVENTORY_REPORT_SAVE_DATA_BEGIN',
    INVENTORY_REPORT_SAVE_DATA_SUCCESS: 'INVENTORY_REPORT_SAVE_DATA_SUCCESS',
    INVENTORY_REPORT_SAVE_DATA_ERR : 'INVENTORY_REPORT_SAVE_DATA_ERR',

    INVENTORY_REPORT_DOWNLOAD_DATA_BEGIN : 'INVENTORY_REPORT_DOWNLOAD_DATA_BEGIN',
    INVENTORY_REPORT_DOWNLOAD_DATA_SUCCESS: 'INVENTORY_REPORT_DOWNLOAD_DATA_SUCCESS',
    INVENTORY_REPORT_DOWNLOAD_DATA_ERR : 'INVENTORY_REPORT_DOWNLOAD_DATA_ERR',

    

    inventoryDataBegin: () => {
        return {
            type: actions.INVENTORY_DATA_BEGIN,
        };
    },

    inventoryDataSuccess: (data) => {
        return {
            type: actions.INVENTORY_DATA_SUCCESS,
            data,
        };
    },

    inventoryDataErr: (err) => {
        return {
            type: actions.INVENTORY_DATA_ERR,
            err,
        };
    },

   
    inventoryRequestDataBegin: () => {
        return {
            type: actions.INVENTORY_REQUEST_DATA_BEGIN,
        };
    },

    inventoryRequestDataSuccess: (data) => {
        return {
            type: actions.INVENTORY_REQUEST_DATA_SUCCESS,
            data,
        };
    },

    inventoryRequestDataErr: (err) => {
        return {
            type: actions.INVENTORY_REQUEST_DATA_ERR,
            err,
        };
    },
    
    inventoryReportDataBegin: () => {
        return {
            type: actions.INVENTORY_REPORT_DATA_BEGIN,
        };
    },

    inventoryReportDataSuccess: (data) => {
        return {
            type: actions.INVENTORY_REPORT_DATA_SUCCESS,
            data,
        };
    },

    inventoryReportDataErr: (err) => {
        return {
            type: actions.INVENTORY_REPORT_DATA_ERR,
            err,
        };
    },

    inventoryReportSaveDataBegin: () => {
        return {
            type: actions.INVENTORY_REPORT_SAVE_DATA_BEGIN,
        };
    },

    inventoryReportSaveDataSuccess: (data) => {
        return {
            type: actions.INVENTORY_REPORT_SAVE_DATA_SUCCESS,
            data,
        };
    },

    inventoryReportSaveDataErr: (err) => {
        return {
            type: actions.INVENTORY_REPORT_SAVE_DATA_ERR,
            err,
        };
    },

    inventoryReportDownloadDataBegin: () => {
        return {
            type: actions.INVENTORY_REPORT_DOWNLOAD_DATA_BEGIN,
        };
    },

    inventoryReportDownloadDataSuccess: (data) => {
        return {
            type: actions.INVENTORY_REPORT_DOWNLOAD_DATA_SUCCESS,
            data,
        };
    },

    inventoryReportDownloadDataErr: (err) => {
        return {
            type: actions.INVENTORY_REPORT_DOWNLOAD_DATA_ERR,
            err,
        };
    },
};

export default actions;
