import actions from './actions';
import chartContent from '../../demoData/dashboardChartContent.json';
const { performance, topSale, closedDeals,recentDeal, income, cashFlow } = chartContent;

const {
    closeDealBegin,
    closeDealSuccess,
    closeDealErr,

    performanceBegin,
    performanceSuccess,
    performanceErr,

    topSaleBegin,
    topSaleSuccess,
    topSaleErr,

    recentDealBegin,
    recentDealSuccess,
    recentDealErr,

    incomeBegin,
    incomeSuccess,
    incomeErr,

    cashFlowBegin,
    cashFlowSuccess,
    cashFlowErr,
} = actions;


const performanceGetData = () => {
    return async (dispatch) => {
        const { year } = performance;
        try {
            dispatch(performanceBegin());
            dispatch(performanceSuccess(year));
        } catch (err) {
            dispatch(performanceErr(err));
        }
    };
};

const performanceFilterData = (value) => {
    return async (dispatch) => {
        try {
            dispatch(performanceBegin());
            setTimeout(() => {
                dispatch(performanceSuccess(performance[value]));
            }, 100);
        } catch (err) {
            dispatch(performanceErr(err));
        }
    };
};

const closeDealGetData = () => {
    return async (dispatch) => {
        const { year } = closedDeals;
        try {
            dispatch(closeDealBegin());
            dispatch(closeDealSuccess(year));
        } catch (err) {
            dispatch(closeDealErr(err));
        }
    };
};

const closeDealFilterData = (value) => {
    return async (dispatch) => {
        try {
            dispatch(closeDealBegin());
            setTimeout(() => {
                dispatch(closeDealSuccess(closedDeals[value]));
            }, 100);
        } catch (err) {
            dispatch(closeDealErr(err));
        }
    };
};
  

const topSaleFilterData = (value) => {
    return async (dispatch) => {
        try {
        dispatch(topSaleBegin());
        setTimeout(() => {
            dispatch(topSaleSuccess(topSale[value]));
        }, 100);
        } catch (err) {
        dispatch(topSaleErr(err));
        }
    };
};


const recentDealGetData = () => {
    return async (dispatch) => {
        const { year } = recentDeal;
        try {
            dispatch(recentDealBegin());
            dispatch(recentDealSuccess(year));
        } catch (err) {
            dispatch(recentDealErr(err));
        }
    };
};
  
const recentDealFilterData = (value) => {
    return async (dispatch) => {
        try {
            dispatch(recentDealBegin());
            setTimeout(() => {
            dispatch(recentDealSuccess(recentDeal[value]));
            }, 100);
        } catch (err) {
            dispatch(recentDealErr(err));
        }
    };
};

const topSaleGetData = () => {
    return async (dispatch) => {
        const { year } = topSale;
        try {
            dispatch(topSaleBegin());
            dispatch(topSaleSuccess(year));
        } catch (err) {
            dispatch(topSaleErr(err));
        }
    };
};

const incomeGetData = () => {
    return async (dispatch) => {
        const { today } = income;
        try {
            dispatch(incomeBegin());
            dispatch(incomeSuccess(today));
        } catch (err) {
            dispatch(incomeErr(err));
        }
    };
};
  
const incomeFilterData = (value) => {
    return async (dispatch) => {
      try {
            dispatch(incomeBegin());
            setTimeout(() => {
                dispatch(incomeSuccess(income[value]));
            }, 100);
        } catch (err) {
            dispatch(incomeErr(err));
        }
    };
};
 
const cashFlowGetData = () => {
    return async (dispatch) => {
        const { today } = cashFlow;
        try {
            dispatch(cashFlowBegin());
            dispatch(cashFlowSuccess(today));
        } catch (err) {
            dispatch(cashFlowErr(err));
        }
    };
};
  
const cashFlowFilterData = (value) => {
    return async (dispatch) => {
        try {
            dispatch(cashFlowBegin());
            setTimeout(() => {
                dispatch(cashFlowSuccess(cashFlow[value]));
            }, 100);
        } catch (err) {
            dispatch(cashFlowErr(err));
        }
    };
};

export {
    topSaleFilterData,
    performanceFilterData,
    performanceGetData,
    closeDealGetData,
    closeDealFilterData,
    recentDealGetData,
    recentDealFilterData,
    topSaleGetData,
    incomeGetData,
    incomeFilterData,
    cashFlowGetData,
    cashFlowFilterData
};
