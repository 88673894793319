import actions from './actions';

const { 
    CUSTOMER_DATA_BEGIN, CUSTOMER_DATA_SUCCESS, CUSTOMER_DATA_ERR, 
    CUSTOMER_SALES_DATA_BEGIN, CUSTOMER_SALES_DATA_SUCCESS, CUSTOMER_SALES_DATA_ERR,
    CUSTOMER_REPORT_DATA_BEGIN, CUSTOMER_REPORT_DATA_SUCCESS, CUSTOMER_REPORT_DATA_ERR,
    CUSTOMER_REPORT_SAVE_DATA_BEGIN, CUSTOMER_REPORT_SAVE_DATA_SUCCESS, CUSTOMER_REPORT_SAVE_DATA_ERR, 
    CUSTOMER_REPORT_DOWNLOAD_DATA_BEGIN, CUSTOMER_REPORT_DOWNLOAD_DATA_SUCCESS, CUSTOMER_REPORT_DOWNLOAD_DATA_ERR, 

} = actions;

const initialStateFilter = { data: '', loading: false, error: null, };
const initialState = { data: [], loading: false, error: null, };

const customerReducer = (state = initialStateFilter, action) => {
    const { type, data, err } = action;
    switch (type) {
        case CUSTOMER_DATA_BEGIN:
            return {
                ...initialStateFilter,
                loading: true,
            };
        case CUSTOMER_DATA_SUCCESS:
            return {
                ...initialStateFilter,
                data,
                error: null,
                loading: false,
            };
        case CUSTOMER_DATA_ERR:
            return {
                ...initialStateFilter,
                error: err,
                data: null,
                loading: false,
            };

        default:
            return state;
    }
};

const customerSalesReducer = (state = initialStateFilter, action) => {
    const { type, data, err } = action;
    switch (type) {
        
        case CUSTOMER_SALES_DATA_BEGIN:
            return {
                ...initialStateFilter,
                loading: true,
            };
        case CUSTOMER_SALES_DATA_SUCCESS:
            return {
                ...initialStateFilter,
                data,
                error: null,
                loading: false,
            };
        case CUSTOMER_SALES_DATA_ERR:
            return {
                ...initialStateFilter,
                error: err,
                data: null,
                loading: false,
            };
            
        default:
            return state;
    }
};

const customerReportReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case CUSTOMER_REPORT_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case CUSTOMER_REPORT_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case CUSTOMER_REPORT_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const customerReportSaveReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case CUSTOMER_REPORT_SAVE_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case CUSTOMER_REPORT_SAVE_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case CUSTOMER_REPORT_SAVE_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const customerReportDownloadReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case CUSTOMER_REPORT_DOWNLOAD_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case CUSTOMER_REPORT_DOWNLOAD_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case CUSTOMER_REPORT_DOWNLOAD_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};


export { customerReducer, customerSalesReducer, customerReportReducers, customerReportSaveReducers, customerReportDownloadReducers } ;
