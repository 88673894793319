/**
 * Return ellipsis of a given string
 * @param {string} text
 * @param {number} size
 */


import Cookies from 'js-cookie';


const ellipsis = (text, size) => {
    return `${text.split(' ').slice(0, size).join(' ')}...`;
};

export { ellipsis };


export const DEBUG      = true;

export const handleLogout = () => {
    // Remove authentication tokens or cookies
    Cookies.remove('loggedIn');
    Cookies.remove('loggedData');
    // Redirect to the login page
    window.location.href = '/';
};