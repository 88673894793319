import React, { lazy } from 'react';
import { Switch, Route,Redirect,useRouteMatch } from 'react-router-dom';
import withAdminLayout from '../../layout/withAdminLayout';

const Dashboard = lazy(() => import('../../container/sales/Dashboard'));
const SalesList = lazy(() => import('../../container/sales/SalesList'));
const SalesDetails = lazy(() => import('../../container/sales/SalesDetails'));
const Report = lazy(() => import('../../container/sales/Report'));
const DownloadReport = lazy(() => import('../../container/sales/DownloadReport'));


function NotFound() {
    return <Redirect to="/admin" />;
}

function ManagerDiscountRoutes() {
    const { path } = useRouteMatch();
    return (
        <Switch>
			<Route exact path={`${path}/dashboard`} component={Dashboard} />
            <Route exact path={`${path}/sales_list`} component={SalesList} />
            <Route exact path={`${path}/sales_details`} component={SalesDetails} />
            <Route exact path={`${path}/report`} component={Report} />
            <Route exact path={`${path}/available_report`} component={DownloadReport} />
            <Route path="*" exact={true} component={NotFound} />
        </Switch>
    );
}

export default withAdminLayout(ManagerDiscountRoutes);
