import React, { lazy, Suspense } from 'react';
import { Spin } from 'antd';
import { Switch, Route, Redirect } from 'react-router-dom';
import AuthLayout from '../container/authentication/Index';
// import { useSelector } from 'react-redux';

const Login = lazy(() => import('../container/authentication/overview/SignIn'));
const ForgotPass = lazy(() => import('../container/authentication/overview/ForgotPassword'));

function NotFound() {
  return <Redirect to="/" />;
}

function FrontendRoutes() {
    
    return (
        <Switch>
            <Suspense
                fallback={
                <div className="spin">
                    <Spin />
                </div>
                }
            >
                <Route exact path="/" component={Login} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/forgotPassword" component={ForgotPass} />
                <Route exact path="*" component={NotFound} />
            </Suspense>
        </Switch>
    );
}

export default AuthLayout(FrontendRoutes);
