import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom';
import { hot } from 'react-hot-loader/root';
import { Provider, useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { ConfigProvider } from 'antd';
import 'antd/dist/antd.less';
import store from './redux/store';
import Admin from './routes/admin';
import Auth from './routes/auth';
import './static/css/style.css';
import config from './config/config';
import ProtectedRoute from './components/utilities/protectedRoute';

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons';

const { theme } = config;
library.add(far,fas)

const ProviderConfig = () => {


    const isLoggedIn = useSelector((state) => state.auth.login);

    const rtl = false;
    const topMenu = true;
    const darkMode = false;
    

    const [path, setPath] = useState(window.location.pathname);

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            setPath(window.location.pathname);
        }
        // eslint-disable-next-line no-return-assign
        return () => (unmounted = true);
    }, [setPath]);

    
    // const [showSplash, setShowSplash] = useState(getItem('splashShown') !== 'true');
    // useEffect(() => {
    //     if (showSplash) {
    //         const timer = setTimeout(() => {
    //             setShowSplash(false);
    //             setItem('splashShown', 'true');
    //         }, 200);
    //     return () => clearTimeout(timer);
    //     }
    // }, [showSplash]);

    return (
        <ConfigProvider direction={rtl ? 'rtl' : 'ltr'}>
            <ThemeProvider theme={{ ...theme, rtl, topMenu, darkMode }}>
               
                <Router basename={process.env.PUBLIC_URL}>
                    {!isLoggedIn ? <Route path="/" component={Auth} /> : <ProtectedRoute path="/admin" component={Admin}   /> }
                    {isLoggedIn && (path === process.env.PUBLIC_URL || path === `${process.env.PUBLIC_URL}/`) && (
                        <Redirect to="/admin" />
                    )}
                </Router>
            
            </ThemeProvider>
        </ConfigProvider>
    );
}

const App = () => {

    return (
        <Provider store={store}>
            <ProviderConfig />
        </Provider>
    );
}

export default hot(App);
