import React, {  useState} from 'react';
import { Row, Col, Table, notification, } from 'antd'; 
import FeatherIcon from 'feather-icons-react';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Cards } from '../../components/cards/frame/cards-frame';
import { Button } from '../../components/buttons/buttons';
import { Main } from '../styled';
import {  useHistory, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CustomerDetailsWrapper} from '../agent/style';
import moment from 'moment';

const EmployeeViewDetails = () => {

    
    let history = useHistory();

    const goBack = () => {
        history.push('/admin/sales/sales_list');
    }

    const location = useLocation();

    console.log(location.state);
    const salesDetails = location.state;

    if(salesDetails == null){
        history.push('/admin/sales/sales_list');
        notification.error('Something Error on Data Fetch')
    }
        
    
	const columns = [
		{
			title: 'Invoice No.',
			dataIndex: 'invoiceNo',
			width: '8%',
		},
		{
			title: 'Invoice Date',
			dataIndex: 'invoiceDate',
			defaultSortOrder: 'descend',
			sorter: (a, b) => a.invoiceDate - b.invoiceDate,
			width: '8%',
		},
        {
			title: 'Brand',
			dataIndex: 'brand',
			onFilter: (value, record) => record.brand.indexOf(value) === 0,
			sorter: (a, b) => a.brand.length - b.brand.length,
			sortDirections: ['descend', 'ascend'],
			width: '10%',
		},
        // {
		// 	title: 'Main Category',
		// 	dataIndex: 'mainCategory',
		// 	onFilter: (value, record) => record.mainCategory.indexOf(value) === 0,
		// 	sorter: (a, b) => a.mainCategory.length - b.mainCategory.length,
		// 	sortDirections: ['descend', 'ascend'],
		// 	width: '15%',
		// },

		{
			title: 'Product Name',
			dataIndex: 'product',
			onFilter: (value, record) => record.product.indexOf(value) === 0,
			sorter: (a, b) => a.product.length - b.product.length,
			sortDirections: ['descend', 'ascend'],
			width: '25%',
		},
		{
			title: 'Qty',
			dataIndex: 'qty',
			width: '5%',
		},
		{
			title: 'Price',
			dataIndex: 'price',
			width: '5%',
		},
	];

	const data = [];
	const purchaseDataDateils = salesDetails;
    if (purchaseDataDateils){
		// eslint-disable-next-line
        salesDetails.order_lines.map((orderList,key) => {
            const { product_code,product_title,quantity,model_number,location,net_price,brand,main_category } = orderList;
            return data.push({
                key : key,
                productCode : product_code,
                modelCode: model_number,
                location:location,
                invoiceNo : salesDetails.voucher_num,
                invoiceDate  :salesDetails.order_date,
                brand:brand.title,
                mainCategory:main_category.title,
                product: product_title,
                qty: quantity,
                price: net_price,
                
            })
        })
	}
    // eslint-disable-next-line
	const [dataSource, setDataSource] = useState(data);
	
    return (
        <>
            <PageHeader
                ghost
                title="Sales Details"
                buttons={[
                <div key="1" className="page-header-actions">
                    <Button size="small" type="primary" onClick={goBack} >
                        <FeatherIcon icon="arrow-left" size={14} />
                        Back
                    </Button>
                </div>,
                ]}
            />
            
            <CustomerDetailsWrapper>
                <Main>
                    <Row gutter={25}>
                        <Col xxl={10} md={12} sm={12} xs={24}>
                            <div className="about-address-wrapper">
                                <Cards title='Order Details'>
                                    <div className='flex_card'>
                                        <div className='flex_icon'>
                                            <span>
                                                <FontAwesomeIcon icon="fa-solid fa-file-invoice" />
                                            </span> Order No
                                        </div>
                                        <span>{salesDetails.voucher_num}</span>
                                    </div>
                                    <div className='flex_card'>
                                        <div className='flex_icon'>
                                            <span>
                                                <FontAwesomeIcon icon="fa-regular fa-calendar-days" />
                                            </span> Order Date
                                        </div>
                                        <span>{moment(salesDetails.order_date).format('LL')}</span>
                                    </div>
                                    <div className='flex_card'>
                                        <div className='flex_icon'>
                                            <span>
                                                <FontAwesomeIcon icon="fa-solid fa-credit-card" />
                                            </span> Payment Method
                                        </div>
                                        <span> Store </span>
                                    </div>
                                </Cards>
                            </div>
                        </Col>
                        <Col xxl={7} md={12} sm={12} xs={24}>
                            <div className="about-address-wrapper">
                                <Cards title='Customer Details'>
                                    <div className='flex_card'>
                                        <div className='flex_icon'>
                                            <span>
                                                <FontAwesomeIcon icon="fa-regular fa-circle-user" />
                                            </span> Customer
                                        </div>
                                        <span>{salesDetails.customer.name}</span>
                                    </div>
                                    <div className='flex_card'>
                                        <div className='flex_icon'>
                                            <span>
                                                <FontAwesomeIcon icon="fa-solid fa-envelope-circle-check" />
                                            </span> Email
                                        </div>
                                        <span>{salesDetails?.customer?.email}</span>
                                    </div>
                                    <div className='flex_card'>
                                        <div className='flex_icon'>
                                            <span>
                                                <FontAwesomeIcon icon="fa-solid fa-mobile-screen" />
                                            </span> Phone
                                        </div>
                                        <span>+91 {salesDetails?.customer?.mobile}</span>
                                    </div>
                                </Cards>
                            </div>
                        </Col>
                        <Col xxl={7} md={12} sm={12} xs={24}>
                            <div className="about-address-wrapper">
                                <Cards title='Additional Details'>
                                    <div className='flex_card'>
                                        <div className='flex_icon'>
                                            <span>
                                                <FontAwesomeIcon icon="fa-solid fa-user" />
                                            </span> Sales Person Code
                                        </div>
                                        <span>{salesDetails.salesman_code}</span>
                                    </div>
                                    <div className='flex_card'>
                                        <div className='flex_icon'>
                                            <span>
                                                <FontAwesomeIcon icon="fa-solid fa-user" />
                                            </span> Sales Person Name
                                        </div>
                                        <span> {salesDetails.salesman_name}</span>
                                    </div>
                                    <div className='flex_card'>
                                        <div className='flex_icon'>
                                            <span>
                                                <FontAwesomeIcon icon="fa-solid fa-store" />
                                            </span> Branch Name
                                        </div>
                                        <span></span>
                                    </div>
                                </Cards>
                            </div>
                        </Col>
                    </Row>
                    {/* <Row gutter={25}>

                        <Col xxl={24} xl={24} xs={24}>
                            <div className="about-address-wrapper">
                                <Cards headless>
                                    <div className='background-address'>
                                        <h4>Billing Address</h4>
                                        <div className='address-flex'>
                                            <span>Unit 1/23 Hasting Road,</span>
                                            <span>Melbourne 3000,</span>
                                            <span>Victoria</span>
                                            <span>Australia.</span>
                                        </div>
                                        <div className='address_ico'>
                                            <FeatherIcon icon="file-text" size={50} />
                                        </div>
                                    </div>
                                </Cards>
                                <Cards headless>
                                    <div className='background-address'>
                                        <h4>Shipping Address</h4>
                                        <div className='address-flex'>
                                            <span>Unit 1/23 Hasting Road,</span>
                                            <span>Melbourne 3000,</span>
                                            <span>Victoria</span>
                                            <span>Australia.</span>
                                        </div>
                                        <div className='address_ico'>
                                            <FeatherIcon icon="truck" size={50} />
                                        </div>
                                    </div>
                                </Cards>
                            </div>
                        </Col>
                    </Row> */}
                    <Row gutter={25}>
                        <Col sm={24} md={24}>
                            <Cards title='Purchase Details'>
                                <Table className="table-responsive"
                                    columns={columns}
                                    pagination={true}
                                    dataSource={dataSource}
                                />
                            </Cards>
                        </Col>
                    </Row>
                </Main>
            </CustomerDetailsWrapper>
        </>
    )
}

export default EmployeeViewDetails