const actions = {
    CUSTOMER_DATA_BEGIN: 'CUSTOMER_DATA_BEGIN',
    CUSTOMER_DATA_SUCCESS: 'CUSTOMER_DATA_SUCCESS',
    CUSTOMER_DATA_ERR: 'CUSTOMER_DATA_ERR',

    CUSTOMER_SALES_DATA_BEGIN: 'CUSTOMER_SALES_DATA_BEGIN',
    CUSTOMER_SALES_DATA_SUCCESS: 'CUSTOMER_SALES_DATA_SUCCESS',
    CUSTOMER_SALES_DATA_ERR: 'CUSTOMER_SALES_DATA_ERR',

    CUSTOMER_REPORT_DATA_BEGIN : 'CUSTOMER_REPORT_DATA_BEGIN',
    CUSTOMER_REPORT_DATA_SUCCESS: 'CUSTOMER_REPORT_DATA_SUCCESS',
    CUSTOMER_REPORT_DATA_ERR : 'CUSTOMER_REPORT_DATA_ERR',

    CUSTOMER_REPORT_SAVE_DATA_BEGIN : 'CUSTOMER_REPORT_SAVE_DATA_BEGIN',
    CUSTOMER_REPORT_SAVE_DATA_SUCCESS: 'CUSTOMER_REPORT_SAVE_DATA_SUCCESS',
    CUSTOMER_REPORT_SAVE_DATA_ERR : 'CUSTOMER_REPORT_SAVE_DATA_ERR',

    CUSTOMER_REPORT_DOWNLOAD_DATA_BEGIN : 'CUSTOMER_REPORT_DOWNLOAD_DATA_BEGIN',
    CUSTOMER_REPORT_DOWNLOAD_DATA_SUCCESS: 'CUSTOMER_REPORT_DOWNLOAD_DATA_SUCCESS',
    CUSTOMER_REPORT_DOWNLOAD_DATA_ERR : 'CUSTOMER_REPORT_DOWNLOAD_DATA_ERR',

    customerDataBegin: () => {
        return {
            type: actions.CUSTOMER_DATA_BEGIN,
        };
    },

    customerDataSuccess: (data) => {
        return {
            type: actions.CUSTOMER_DATA_SUCCESS,
            data,
        };
    },

    customerDataErr: (err) => {
        return {
            type: actions.CUSTOMER_DATA_ERR,
            err,
        };
    },

    customerSalesDataBegin: () => {
        return {
            type: actions.CUSTOMER_SALES_DATA_BEGIN,
        };
    },

    customerSalesDataSuccess: (data) => {
        return {
            type: actions.CUSTOMER_SALES_DATA_SUCCESS,
            data,
        };
    },

    customerSalesDataErr: (err) => {
        return {
            type: actions.CUSTOMER_SALES_DATA_ERR,
            err,
        };
    },

    customerReportDataBegin: () => {
        return {
            type: actions.CUSTOMER_REPORT_DATA_BEGIN,
        };
    },

    customerReportDataSuccess: (data) => {
        return {
            type: actions.CUSTOMER_REPORT_DATA_SUCCESS,
            data,
        };
    },

    customerReportDataErr: (err) => {
        return {
            type: actions.CUSTOMER_REPORT_DATA_ERR,
            err,
        };
    },

    customerReportSaveDataBegin: () => {
        return {
            type: actions.CUSTOMER_REPORT_SAVE_DATA_BEGIN,
        };
    },

    customerReportSaveDataSuccess: (data) => {
        return {
            type: actions.CUSTOMER_REPORT_SAVE_DATA_SUCCESS,
            data,
        };
    },

    customerReportSaveDataErr: (err) => {
        return {
            type: actions.CUSTOMER_REPORT_SAVE_DATA_ERR,
            err,
        };
    },

    customerReportDownloadDataBegin: () => {
        return {
            type: actions.CUSTOMER_REPORT_DOWNLOAD_DATA_BEGIN,
        };
    },

    customerReportDownloadDataSuccess: (data) => {
        return {
            type: actions.CUSTOMER_REPORT_DOWNLOAD_DATA_SUCCESS,
            data,
        };
    },

    customerReportDownloadDataErr: (err) => {
        return {
            type: actions.CUSTOMER_REPORT_DOWNLOAD_DATA_ERR,
            err,
        };
    },
};

export default actions;
