import React, { Component } from 'react';
import { Layout, Row, Col } from 'antd';
import {  Link } from 'react-router-dom';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
// import FontAwesome from 'react-fontawesome';
import { Div } from './style';
import AuthInfo from '../components/utilities/auth-info/info';
import { changeRtlMode, changeLayoutMode, changeMenuMode } from '../redux/themeLayout/actionCreator';
import InstallPopup from './InstallPopup';
import moment from 'moment';

const { Header, Footer, Content } = Layout;
// const { darkMode } = config;

const DashboardLayout = (WrappedComponent) => {

    class LayoutComponent extends Component {
        render() {
            const { ChangeLayoutMode, topMenu, changeMenuMode } = this.props;
            
            const darkMode = ChangeLayoutMode;
            const footerStyle = {
                padding: '20px 30px 18px',
                color: 'rgba(0, 0, 0, 0.65)',
                fontSize: '14px',
                background: 'rgba(255, 255, 255, .90)',
                width: '100%',
                boxShadow: '0 -5px 10px rgba(146,153,184, 0.05)',
            };

            const modeChangeTopNav = () => {
                changeMenuMode(true);
            };

            const modeChangeSideNav = () => {
                changeMenuMode(false);
            };

            const onEventChange = {
                modeChangeTopNav,
                modeChangeSideNav,
            };

            return (
                <Div darkMode={darkMode}>
                    <Layout className="layout">
                        {/* style={{ position: 'fixed', width: '100%', top: 0, [!rtl ? 'left' : 'right']: 0, }} */}
                        <Header style={{ width: '100%'}} >
                            <Row>
                                <Col lg={4} xs={12}>
                                    <Link className={topMenu && window.innerWidth > 991 ? 'logo top-menu' : 'logo'} to="/admin" >
                                        <img src={ require(`../static/img/logo.png`) } alt="PAI" />
                                    </Link>
                                </Col>
                                <Col lg={20} xs={12}>
                                    <AuthInfo />
                                </Col>
                            </Row>
                        </Header>
                        <Layout>
                            <Layout className="atbd-dashboard-layout">
                                <Content>
                                    <WrappedComponent {...this.props} events={onEventChange} />
                                    <Footer className="admin-footer" style={footerStyle}>
                                        <Row>
                                            <Col md={24} xs={24}>
                                                <span className="admin-footer__copyright">{moment().format('YYYY')} © SkilledAnswers Info Solutions Pvt Ltd</span>
                                            </Col>
                                        </Row>
                                    </Footer>
                                </Content>
                            </Layout>
                        </Layout>
                    </Layout>
                    <InstallPopup />
                </Div>
            );
        }
    }

    const mapStateToProps = (state) => {
        return {
            ChangeLayoutMode: state.ChangeLayoutMode.data,
            rtl: state.ChangeLayoutMode.rtlData,
            topMenu: state.ChangeLayoutMode.topMenu,
        };
    };

    const mapStateToDispatch = (dispatch) => {
        return {
            changeRtl: (rtl) => dispatch(changeRtlMode(rtl)),
            changeLayout: (show) => dispatch(changeLayoutMode(show)),
            changeMenuMode: (show) => dispatch(changeMenuMode(show)),
        };
    };

    LayoutComponent.propTypes = {
        ChangeLayoutMode: propTypes.bool,
        rtl: propTypes.bool,
        topMenu: propTypes.bool,
        changeRtl: propTypes.func,
        changeLayout: propTypes.func,
        changeMenuMode: propTypes.func,
    };

    return connect(mapStateToProps, mapStateToDispatch)(LayoutComponent);
};
export default DashboardLayout;
