import axios from 'axios';
import { handleLogout } from '../../utility/utility';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const client = axios.create({
    baseURL: API_ENDPOINT,
    headers: {
        'Content-Type': 'application/json',
    },
});

client.interceptors.request.use(async(config) => {
    // do something before executing the request
    // For example tag along the bearer access token to request header or set a cookie
    const requestConfig = config;
    const { headers } = config;
    try {
        const response = await axios.get(API_ENDPOINT+'checkCustomerValid')
        requestConfig.headers = { ...headers, Authorization: `Bearer ${response.data.accessToken}` };
        return requestConfig;
    } catch (error) {
        // this failed, so let's redirect to the login page
        console.log(error);
        handleLogout()
    }
    // requestConfig.headers = { ...headers, Authorization: `Bearer ${getItem('access_token')}` };
    
});

client.interceptors.response.use(
    response => response,
    error => {
        const { response } = error;
        const originalRequest = error.config;
        if (response) {
            console.log('interceptors', response);
            if (response.status === 500 || response.status === 401 || response.status === 403) {
                // do something here
                // window.location.href = '/admin';
                // window.location.href = '/'
                // handleLogout()
                console.log()
            } else {
                return originalRequest;
            }
        }
        return Promise.reject(error);
    },
);

class DataService {
    static get(path = '') {
        return client({
            method: 'GET',
            url: path,
            headers: {}, // headers: { ...authHeader() },
        });
    }

    static post(path = '', data = {}, optionalHeader = {}) {
        return client({
            method: 'POST',
            url: path,
            data,
            headers: {...optionalHeader },
        });
    }

    static patch(path = '', data = {}) {
        return client({
            method: 'PATCH',
            url: path,
            data: JSON.stringify(data),
            headers: {},
        });
    }

    static delete(path = '', data = {}) {
        return client({
            method: 'DELETE',
            url: path,
            data: JSON.stringify(data),
            headers: {},
        });
    }

    static put(path = '', data = {}) {
        return client({
            method: 'PUT',
            url: path,
            data: JSON.stringify(data),
            headers: {},
        });
    }
}

export { DataService };