import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ProtectedRoute =({ component:Component, path }) => {
    const isLoggedIn = useSelector(state => state.auth.login);
    return isLoggedIn ? <Route component={Component} path={path} /> : <Redirect to="/" />;
}

export default ProtectedRoute;
