import Styled from 'styled-components';
import { Table } from 'antd';

const Action = Styled.div`
  .active{
    color: #FA8B0C;
  }
`;


const EChartCard = Styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    .card-chunk{
        width: 50%;
        flex: 0 0 50%;
    }
    @media only screen and (max-width: 379px){
        .card-chunk{
            width: 100%;
            flex: 0 0 100%;
            h1{
                margin-bottom: 0;
            }
            p{
                margin: 5px 0 20px 0;
            }
        }
    }
    .chartjs-tooltip {
        min-width: 132px !important;
    }
`;

const RevenueWrapper = Styled.div`

    .chart-label{
        justify-content: flex-start;
        margin-bottom: 26px;
        display: none
    }

    >.ant-card{
        min-height: 455px;
        @media only screen and (max-width: 1599px){
            min-height: 100%;
        }
    }
    .performance-lineChart{
        ul{
            margin: -25px -25px 20px;
        }
        
        &.theme-2{
            .custom-label{
                .current-amount{
                    color: ${({ theme }) => theme.pink};
                }
            }
        }
    }
    .custom-label{
        flex-direction: column;
        align-items: flex-start;
        margin: 25px;
        .current-amount{
            display: block;
            font-size: 24px;
            font-weight: 600;
            color: ${({ theme }) => theme['primary-color']};
        }
        .prev-amount{
            display: block;
            font-size: 24px;
            font-weight: 600;
            color: ${({ theme }) => theme['dark-color']};
        }
        div{
            span{
                display: inline-block;
            }
        }
    }

    .chartjs-tooltip{
        min-width: 166px !important;
        @media only screen and (max-width: 1199px){
            min-width: 150px !important;
        }
    }
`;

const CardBarChart = Styled.div`
    >div{
        @media only screen and (max-width: 575px) {
            flex-flow: column;
            align-items: flex-start !important;
            ul{
                margin: 0 0 15px;
            }
        }
    }
    .card-bar-top{
        &.flex-grid{
            ${({ theme }) => (theme.rtl ? 'margin-right' : 'margin-left')}: -20px;
            @media only screen and (max-width: 575px) {
                flex-flow: column;
                align-items: center;
            }
            h1{
                font-size: 24px;
                margin-bottom: 22px;
                @media only screen and (max-width: 1199px) {
                    font-size: 20px;
                }
            }
        }
        .flex-grid-child{
            padding: 0 20px;
        }
        p{
            font-size: 14px;
            margin-bottom: 8px;
            color: ${({ theme }) => theme['light-color']};
        }
        h1{
            margin-bottom: 18px;
            sub{
                bottom: 0;
                font-size: 14px;
                ${({ theme }) => (theme.rtl ? 'margin-right' : 'margin-left')}: 8px;
                color: ${({ theme }) => theme['success-color']};
                svg{
                    ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 4px;
                }
            }
        }
    }
    ul{
        margin-top: 15px;
    }
    .chart-dataIndicator{
        li{
            font-size: 13px;
            color: ${({ theme }) => theme['gray-color']};
            &:not(:last-child){
                ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 16px;
            }
        }
    }
    .chartjs-tooltip{
        min-width: 140px !important;
        @media only screen and (max-width: 1199px){
            min-width: 115px !important;
        }
    }
    .deals-barChart{
        display: flex;
        .card-bar-top{
            &:not(:last-child){
                margin-right: 30px;
                ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 30px;
            }
        }
        h4{
            font-weight: 400;
            color: ${({ theme }) => theme['light-gray-color']};
            p{
                &.growth-down{
                    .deal-percentage{
                        color: ${({ theme }) => theme['danger-color']};
                    }
                }
                &.growth-up{
                    .deal-percentage{
                        color: ${({ theme }) => theme['success-color']};
                    }
                }
                .deal-percentage{
                    svg,
                    img,
                    i{
                        margin-right: 3px;
                    }
                }
                .deal-value{
                    font-size: 22px;
                    font-weight: 600;
                    margin-right: 8px;
                    color: ${({ theme }) => theme['dark-color']};
                }
            }
        }
    }
    .deals-list{
        .custom-label{
            font-size: 14px;
            &:not(:last-child){
                ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 30px;
            }
        }
    }
`;

const CardBarChart2 = Styled.div`
    @media only screen and (max-width: 379px){
        text-align: center
    }
    h1{
        margin-bottom: 5px;
    }
    @media only screen and (max-width: 1500px){
        h1{
            font-size: 22px;
        }
    }
    & > span{
        font-size: 14px;
        color: ${({ theme }) => theme['light-gray-color']};
    }
    p{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 21px 0 0 0;
        @media only screen and (max-width: 379px){
            justify-content: center;
        }
        .growth-upward, .growth-downward{
            display: inline-flex;
            align-items: center;
            ${({ theme }) => (!theme.rtl ? 'padding-right' : 'padding-left')}: 10px;
            font-weight: 600;

            svg{
                width: 15px;
            }
        }
        .growth-upward{
            color: ${({ theme }) => theme['success-color']};
            svg{
                color: ${({ theme }) => theme['success-color']};
            }
        }
        .growth-downward{
            color: ${({ theme }) => theme['danger-color']};
            svg{
                color: ${({ theme }) => theme['danger-color']};
            }
        }
        span{
            color: ${({ theme }) => theme['light-gray-color']};
            font-size: 13px;
            display: inline-block;
        }
    }
`;

const FormValidationWrap = Styled.div`
    .ant-card-body{
        padding: 30px 25px 50px 25px !important;
    }
    .ant-form-item-has-error{
        .ant-form-item-explain{
            font-size: 13px;
        }
    }
    .sDash_agree-check{
        .ant-form-item-control-input{
            min-height: auto;
        }
        .ant-form-item-has-error{
            .ant-checkbox-wrapper{
                span{
                    font-size: 13px;
                    color: ${({ theme }) => theme['danger-color']};
                }
                .ant-checkbox{
                    border-color: ${({ theme }) => theme['danger-color']}30;
                    &.ant-checkbox-checked{
                        border-color: ${({ theme }) => theme['danger-color']}30;
                        &:after{
                            border-color: ${({ theme }) => theme['danger-color']}30;
                        }
                        .ant-checkbox-inner{
                            &:after{
                                border-color: ${({ theme }) => theme['danger-color']};
                            }
                        }
                    }
                    .ant-checkbox-inner{
                        border-color: ${({ theme }) => theme['danger-color']}30;
                        background-color: ${({ theme }) => theme['danger-color']}30;
                    }
                }
            }
        }
        .ant-form-item-explain{
            margin-left: 25px;
            font-size: 13px;
        }
    }
    .sDash_form-action{
        button{
            border-radius: 4px;
            padding: 6.4px 29.2px;
            height: 44px;
        }
    }
`;

const UsercardWrapper = Styled.nav`
    .user-card-pagination{
        margin: 15px 0 40px 0;
        text-align: ${({ theme }) => (!theme.rtl ? 'right' : 'left')};
        @media only screen and (max-width: 991px){
            text-align: center;
        }
    }
`;

const RecentDealsWrapper = Styled.div`
    margin-top: 18px;
    min-height: 314px;
    .recent-deals-table{
        .dealing-author{
            display: flex;
            align-items: center;
            img{
                max-width: 36px;
                border-radius: 50%;
            }
            .dealing-author-info{
                ${({ theme }) => (theme.rtl ? 'margin-right' : 'margin-left')}: 12px;
                h4{
                    font-size: 14px;
                    font-weight: 600;
                    margin-bottom: 2px;
                }
                p{
                    font-size: 13px;
                    color: #9297AF;
                    margin-bottom: 0;
                }
            }
        }
        .deal-amount{
            font-size: 14px;
            font-weight: 600;
            color: ${({ theme }) => theme['dark-color']};
        }
        .ant-table-tbody > tr > td{
            padding: 7px 25px;
            border-bottom: 0 none;
        }
    }
`;

const UserCard = Styled.div`
    text-align: center;
    .user-card{
        &.theme-list{
            .ant-card-body{
                padding: 30px 25px 30px 30px !important;
                @media only screen and (max-width: 479px){
                    padding: 25px 20px 25px 20px !important;
                }
            }
            figure{
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                @media only screen and (max-width: 479px){
                    flex-flow: column;
                }
                img{
                    max-width: 80px;
                    ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}:20px;
                    @media only screen and (max-width: 479px){
                        ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}:0px;
                    }
                }
            }
            figcaption{
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                text-align: left;
                @media only screen and (max-width: 379px){
                    flex-flow: column;
                }
            }
            .card__content{
                p{
                    max-width: 400px;
                    font-size: 15px;
                    color: ${({ theme }) => theme['gray-color']};
                }
                .card__designation{
                    font-size: 13px;
                    margin-bottom: 15px;
                    color: ${({ theme }) => theme['light-color']};
                }
                .card-text{
                    margin-bottom: 12px;
                }
                .card-info{
                    margin-bottom: 0;
                    .user-meta{
                        font-size: 14px;
                        strong{
                            font-weight: 600;
                            color: ${({ theme }) => theme['dark-color']};
                        }
                    }
                    .user-meta + .user-meta{
                        ${({ theme }) => (theme.rtl ? 'margin-right' : 'margin-left')}: 20px;
                    }
                }
            }
            .card__actions{
                text-align: ${({ theme }) => (theme.rtl ? 'left' : 'right')}
                @media only screen and (max-width: 379px){
                    margin-top: 15px;
                }
                button{
                    padding: 0px 19.05px;
                    min-width: 114px;
                    
                }

                button.active {
                    border: 1px solid rgb(57, 49, 134);
                    color: rgb(255, 255, 255) !important;
                    background-color: rgb(57, 49, 134) !important;
                }
            }
        }
        &.theme-grid-2{
            .ant-card-body{
                padding: 0 !important;
            }
            figure{
                position: relative;
            }
            .user-card__img{
                margin-bottom: 0;
                position: absolute;
                top: 95px;
                left: 50%;
                transform: translateX(-50%);
                width: 60px;
                height: 60px;
                border-radius: 50%;
                background-color: rgb(245 242 242);
		        background: rgb( 132,90,223 );
                box-shadow: 0 0 .5rem  rgba( 132,90,223 , 0.5);
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 22;
                svg{
                    position: relative;
                    font-size: 30px;
                    color: #fff;
                }
            }
            .user-card__bg{
                background-size: cover !important;
                background-position: center !important;
                border-radius: 10px 10px 0 0;
                .card_info_txt {
                    padding: 1.5rem 1rem 1rem;
                    color: #555;
                    letter-spacing: 0.4px;
                    h5 {
                        font-size: 16px;
                        font-weight: 600;
                        text-transform: uppercase;
                        margin-bottom: 0.35rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #3B5998;
                        span {
                            display: flex;
                            margin-right: 0.45rem;
                            font-size: 14px;
                        }
                    }
                    p{
                        font-size: 14px;
                        margin-bottom: 0;
                        font-weight: 400;
                    }
                }
            }
            .card__bottom{
                position: relative;
                background-color: #fff;
                top: -26px;
                padding-top: 65px;
                border-radius: 30px 30px 10px 10px;
                .card__actions{
                    
                }
            }
        }
        &.theme-grid-3{
            .ant-card{
                text-align: left;
            }
            .ant-card-body{
                padding: 0 !important;
            }
            .card__top,
            .card__content,
            .card__info{
                padding: 0 30px;
            }
            .card__top{
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-top: 30px;
                margin-bottom: 10px;
                .user-card__img{
                margin-right: 12px;
                ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 12px;
                img{
                    max-width: 70px;
                }
            }
            .user-card__info{
                width: 100%;
                position: relative;
                .action-more{
                    position: absolute;
                    right: 0;
                    ${({ theme }) => (theme.rtl ? 'left' : 'right')}: 0;
                    top: 0;
                    color: #ADB4D2;
                }
                .card__designation{
                    margin-bottom: 0;
                }
            }
        }
        .card__content{
            p{
                font-size: 15px;
                margin-bottom: 26px;
            }
            .image-group{
                margin: -3px;
            }
            img{
                max-width: 34px;
                margin: 3px;
            }
        }
        .card__info{
            padding-bottom: 30px;
            padding-top: 18px;
            .ant-progress-inner{
                position: relative !important;
            }
            p{
                font-size: 12px;
                color: ${({ theme }) => theme['light-color']};
            }
            h2{
                font-size: 14px;
                font-weight: 500;
                margin-top: 4px;
                margin-bottom: 16px;
            }
            .info-line{
                display: flex;
                justify-content: space-between;
                .success{
                    color: ${({ theme }) => theme['success-color']};
                    background-color: transparent;
                }
            }
            .completed-count{
                margin-top: 4px;
            }
            .project-progress{
                display: flex;
                justify-content: space-between;
                .progress-percentage{
                    ${({ theme }) => (theme.rtl ? 'margin-right' : 'margin-left')}: 20px;
                    span{
                        font-size: 12px;
                        color: ${({ theme }) => theme['gray-color']};
                    }
                }
            }
        }
    }
}
    .card{
        position: relative;
        box-shadow: 0 5px 20px ${({ theme }) => theme['light-color']}03;
        .ant-card-body{
            padding: 30px !important;
            div{
                position: static;
            }
        }
        figure{
            margin-bottom: 0;
            img{
                margin-bottom: 20px;
                width: 100%;
                border-radius: 50%;
                max-width: 150px;
            }      
        }
        .card__more_actions{
            position: absolute;
            ${({ theme }) => (theme.rtl ? 'left' : 'right')}: 24px;
            top: 20px;
            line-height: .5;
            padding: 5px 3px;
            color: ${({ theme }) => theme['extra-light-color']};
            box-shadow: 0 10px 20px #9299B815;
            svg,
            img{
                width: 20px;
            }
        }
        .card__name{
            font-size: 13px;
	        padding-inline: 0.25rem;
            margin-bottom: 6px;
            font-weight: 500;
	        margin-bottom: 2rem;
	        letter-spacing: 0.2px;
            a{
                color: ${({ theme }) => theme['gray-color']};
                &:hover{
                    color: ${({ theme }) => theme['primary-color']};
                }
            }
        }
        .card__designation{
            font-size: 13px;
            margin-bottom: 25px;
            color: ${({ theme }) => theme['light-color']};
        }
        .card__social{
            margin-top: 16px;
            a{
                width: 38px;
                height: 38px;
                border-radius: 50%;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                box-shadow: 0 10px 20px ${({ theme }) => theme['light-color']}15;
                background: #fff;
                &:not(:last-child){
                    ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 10px;
                }
                &.facebook span.fa{
                    color: #3B5998;
                }
                &.twitter span.fa{
                    color: #1DA1F2;
                }
                &.dribble span.fa{
                    color: #C2185B;
                }
                &.instagram span.fa{
                    color: #FF0300;
                }
            }
        }
    }

    .user-card{
        .ant-card-body{
            padding: 30px 25px 18px 25px !important;
            @media only screen and (max-width: 1599px){
                padding: 20px  20px 20px !important;
            }
            @media only screen and (max-width: 767px){
                padding: 15px  15px 15px !important;
            }
        }
        figure{
            img{
                margin-bottom: 18px;
                max-width: 120px;
            }      
        }
        .card__actions{
            margin: -5px;
            .ant-btn-white{
                color: ${({ theme }) => theme['gray-color']};
                border: 1px solid ${({ theme }) => theme['border-color-light']};
                &:hover{
                    border: 1px solid ${({ theme }) => theme['primary-color']};
                }
            }
            button{
                font-size: 13px;
                padding: 0px 22.7px;
                height: 38px;
                border-radius: 6px;
                box-shadow: 0px 3px 5px ${({ theme }) => theme['light-color']}05;
                margin: 5px;
                &:hover{
                    color: #fff !important;
                    background-color: ${({ theme }) => theme['primary-color']} !important;
                    svg, i{
                        color: #fff;
                    }
                }
                &.active{
                    color: #fff !important;
                    background-color: ${({ theme }) => theme['primary-color']} !important;
                    svg, i{
                        color: #fff;
                    }
                }
                svg,i{
                    color: ${({ theme }) => theme['light-color']};
                }
            }
            
        }
    }
    .card__info{
        padding-top: 20px;
        margin-top: 18px;
        border-top: 1px solid ${({ theme }) => theme['border-color-light']};
        .info-single{
            text-align: center;
        }
        .info-single__title{
            font-size: 16px;
            font-weight: 600;
            line-height: 1.5;
            margin-bottom: 4px;
        }
        p{
            margin-bottom: 0;
            color: ${({ theme }) => theme['light-color']};
        }
    }
 }`;








const PaginationStyle = Styled.div`
	display: flex;
	align-items: center;
	justify-content: end;
	position: relative;
	width: 100%;
	.ant-pagination {
		margin-inline: 0 !important;
	}
`;	

// view Tickets
const PageHeaderCustom = Styled.div`
	color: #272B41;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
	position: relative;
	padding: 25px;
	border-bottom: 1px solid #0000001f;
	.page-heading-left {
		display: flex;
		align-items: center;
		margin-bottom: 16px;
		overflow: hidden;
		align-items: flex-start;
		a {
			margin-right: 12px;
			margin-bottom: 0;
			// color: rgba(0, 0, 0, 0.85);
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			text-transform: capitalize;
			display: block;
			width: 100%;
			display: flex;
			align-items: center;
			.page-link span {
				margin-right: 6px;
				font-size: 26px;
				display: inline-block;
				color: #5da9e4;
			}
			.page-header-text {
				h4 {
					color: #2d80c0;
					font-weight: 600;
					font-size: 20px;
					line-height: 32px;
					margin-bottom: 0;
				}
				
			}
		}
	}
	.page-header-bottom{
		margin-left: 16px;
		span {
			color: #5a5f7d;
			font-size: 12px;
			line-height: 1.5715;			
			margin-right: 4px;
			&:after {
				content: "";
				display: inline-block;
				position: relative;
				height: 15px;
				width: 1px;
				background: #d7d7d7;
				margin-inline: 10px 6px;
				top: 3px;
			}
			&:nth-last-of-type(1):after {
				content: none
			}
		}
	}
`;

const CardContainer = Styled.div`
	.ant-card-body {
		padding: 0px !important;
		.pr-0 {
			padding-right: 0 !important;
		}
		.pl-0 {
			padding-left: 0 !important;
		}
		.px-0 {
			padding-inline: 0 !important;
		}
		.btn-block-primary {
			position: sticky;
			bottom: 1rem;
		}
	}
`;

const TicketViewCard = Styled.div`
	border-right: 1px solid #0000001f;
	.ticket-view-wrap {
		position: relative;
		padding-bottom: 25px;
		border-bottom: 1px solid #0000001f;
		&:last-child {
			border: 0;
		}
		.ticket-head {
			display: flex;
			align-items: center;
			padding: 25px;
			border-bottom: 1px solid #0000001f;
			.ticket-user-name {
				width: 50px;
				height: 50px;
				background: #00000042;
				color: #00000094;
				padding: 4px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 22px;
				font-weight: 500;
				border-radius: 100%;
				margin-right: 16px;
			}
			p {
				margin-bottom: 0;
				font-size: 16px;
				line-height: 20px
			}
		}
		.ticket-body {
			.ticket-content {
				padding: 25px;
				font-size: 14px;
				line-height: 24px;
				color: #303030;
				margin-left: 66px;
			}
		}
		.ticket-bottom {
			padding: 25px 25px 25px 91px;
			font-size: 16px;
			line-height: 20px;
			background: #f5f5f5;
			a {
				color: rgb(48, 48, 48);
			}
		}
	}
`;

const TicketStatus = Styled.div`
	position: relative;
	.ticket-status-wrap {
		position: relative;
		padding: 24px 16px;
		border-bottom: 1px solid #0000001f;
		.status-head {
			font-weight: 600;
			font-size: 20px;
			line-height: 32px;			
			margin-bottom: 16px;
		}
		.contact-status-head {
			font-weight: 600;
			font-size: 17px;
			line-height: 28px;			
			margin-bottom: 16px;
		}
		span {
			color: #5a5f7d;
			font-size: 14px;
			line-height: 1.5;
			margin-right: 4px;
			a {
				color: #2d80c0;
				span {
					color: #2d80c0;
				}
			}
			&:after {
				content: "";
				display: inline-block;
				position: relative;
				height: 15px;
				width: 1px;
				background: #d7d7d7;
				margin-inline: 10px 6px;
				top: 3px;
			}
			&:nth-last-of-type(1):after {
				content: none
			}
		}
	}
	.form-edit-ticket {
		padding: 16px;
	}
	.form-edit-ticket.p-0 {
		padding: 0;
	}
	.ticket-contact-details {
		padding: 16px;
		.contact-details-block {
			margin-bottom: 1rem;
			padding-bottom: 1rem;
			border-bottom: 1px solid #0000001f;
			p{
				margin-bottom: 6px;
				color: #5a5f7d;
				font-size: 12px;
			}
			.contact-details_block {
				margin-bottom: 12px;
				.contact-details_status {
					color: #828388;
				}
			}
			.view-all-link {
				color: #0064b3;
				text-decoration: underline;
			}
            .gap-2 {
				gap: 0.35rem;
                buttom {
                    margin-right: 4px;
                }
			}
			.highlight_icon svg {
				color: #0064b3;
			}
           
		}
        
	}
    
`;

const CustomerDetailsWrapper = Styled.div`
	.project-action{
		.project-edit, 
		.project-remove,
		.project-add {
			border-radius: 6px;
			background: #fff;
			height: 35px;
			padding: 0 15px;
			font-size: 12px;
			font-weight: 500;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			margin: 0 5px;
			box-shadow: 0 3px 5px ${({ theme }) => theme['gray-solid']}05;
			svg,
			img{
				${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 6px;
			}
		}
		.project-edit{
			color: ${({ theme }) => theme['success-color']};
		}
		.project-remove{
			color: ${({ theme }) => theme['danger-color']};
		}
		.project-add{
			color: ${({ theme }) => theme['primary-color']};
		}
	}
	.about-project-wrapper{
        background: #fff;
        border-radius: 10px;
        margin-bottom: 25px;
    }
	.about-project{
        // display: flex;
        // align-items: center;
        // @media only screen and (max-width: 991px) {
        //     flex-flow: column;
        //     align-items: flex-start;
        // }
        
        div{
            span{
                color: ${({ theme }) => theme['gray-solid']};
                font-size: 14px;
                display: block;
                margin-bottom: 3px;
            }
            p{
                font-weight: 500;
				font-size: 14px;
            }
        }
    }
	.about-order-wrapper {
		// display: flex;
		// gap: 16px;
		.ant-card:nth-of-type(2) {
			.about-card-icon {
				background: #faefdd;
				color: #a9742b;
			}
		}
		.ant-card:nth-of-type(3) {
			.about-card-icon {
				background: #b8d3fd;
				color: #4486ff;
			}
		}
		.ant-card-body {
			height: 100%;
		}
		.about-card-flex {
			display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center;
			padding: 0.75rem 1.5rem;
			.about-card-icon {
				width: 40px;
				height: 40px;
				background: #f7d7d8;
				color: #f9282f;
				border-radius: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
			}
			.about-card-title {
				font-size: 16px;
				font-weight: 500;
				margin-top: 0.75rem;
			}
			.about-card-number {
				color: rgb(146, 153, 184);
				// margin-top: 0.75rem;
			}
		}
		.membership-card {
			justify-content: center;
			height: 100%;
			background: #e7f0fe;
			color: #4486ff;
			border-radius: 10px;
            // padding: 0.5rem;
			.about-card-title {
				font-size: 20px;
				font-weight: 600;
				text-transform: uppercase;
			}
            .about-card-icon {
                margin-top: 0.75rem;
				background: #b8d3fd;
				color: #4486ff;
			}
		}
	}
    .search-box{
        position: relative;
        background:#efefef;
        box-shadow: 0 5px 5px rgba(#9299B8,1);
        .search-icon{
            position: absolute;
            ${({ theme }) => (theme.rtl ? 'right' : 'left')}: 18px;
            top: 50%;
            transform: translateY(-50%);
            svg,
            img{
                margin-top: 6px;
                min-width: 16px;
                color: #9299B8;
            }
        }
        input{
            border: 0 none;
            height: 40px;
            min-width: 280px;
            padding: ${({ theme }) => (theme.rtl ? '0 45px 0 20px' : '0 20px 0 45px')};
            border-radius: 6px;
            &::placeholder{
                color: #ADB4D2;
            }
            &:focus{
                outline: none;
            }
        }
    }
	.about-address-wrapper {
		// display: flex;
		// gap: 16px;
        .ant-col{
            display: flex;
        }
        .ant-card{
            align-self: stretch;
        }
		.background-address {
			
			h4 {
				font-size: 18px;
				font-weight: 600;
				line-height: 24px;
				margin-bottom: 1.5rem;
                
			}
			.address-flex {
				display: flex;
				flex-direction: column;
				width: 100%;
				font-weight: 500;
                position:relative;
                z-index:99
			}
			.address_ico {
				position: absolute;
				right: 0;
				top: 0;
				svg {
                    @media only screen and (max-width: 991px){
                        width: 90px;
                        height: 90px;
                    }
                    width: 140px;
                    height: 140px;
					stroke: #f3f3f3;				
				}
			}
		}
		.flex_card {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 1rem;
			width: 100%;
			font-size: 14px;
			font-weight: 600;
			line-height: 20px;
			border-bottom: 1px solid #f3f3f3;
			padding-bottom: 1rem;
			&:nth-last-of-type(1) {
				border-bottom: 0;
				padding-bottom: 0;
			}
			.flex_icon {
				display: flex;
				align-items: center;
				font-size: 14px;
				font-weight: 600;
				line-height: 20px;
				color: #787878;
				span {
					display: flex;
					align-items: center;
					margin-right: 0.25rem;
				}
			}
			.img_payment {
				width: 35px;
				margin-left: 0.25rem;
			}
		}
	}
`;

const DatePickerStyle = Styled.div`
    .ant-form-item-control-input {
        min-height: 38px;
    }
	.ant-picker-input > input {
		padding: 8px 12px;
	}
`;

const ComingsoonStyleWrapper = Styled.div`
    text-align: center;
    background: #fff;
    padding: 75px 0 30px;
    margin-bottom: 30px;
    @media only screen and (max-width: 1150px){
        padding: 50px 0 6px;
    }
    @media only screen and (max-width: 991px){
        padding: 20px 0 0px;
    }
    .coming-soon-content{
        h2{
            font-size: 40px;
            font-weight: 600;
            line-height: 1.5;
            margin-bottom: 25px;
            
            color: ${({ theme }) => theme['primary-color']};
            @media only screen and (max-width: 991px){
                font-size: 40px;
                margin-bottom: 15px;
            }
            @media only screen and (max-width: 767px){
                font-size: 40px;
                line-height: 1.45;
            }
            @media only screen and (max-width: 479px){
                font-size: 30px;
            }
            @media only screen and (max-width: 375px){
                font-size: 20px;
            }
        }
        h3.timer-red{
            color: red;
        }
        p{
            font-size: 17px;
            max-width: 580px;
            margin: 0 auto 25px;
            color: ${({ theme }) => theme['gray-color']};
            @media only screen and (max-width: 991px){
                margin-bottom: 15px;
            }
            @media only screen and (max-width: 767px){
                font-size: 16px;
            }
            @media only screen and (max-width: 375px){
                font-size: 15px;
            }
        }
    }
`;

const DialPadCSS = Styled.div`
 max-width: 300px;
  margin: 50px auto;
  padding: 20px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  background-color: #f0f2f5;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  text-align: center;
`;

const TableStyle = Styled(Table)`
    margin-bottom: 30px;  
    .ant-table{
        // padding-bottom: 30px;
        // border-radius: 10px;
        tr{
            th,td{
                &:first-child{
                ${({ theme }) => (theme.rtl ? 'padding-right' : 'padding-left')}: 20px;
                }
                &:last-child{
                ${({ theme }) => (!theme.rtl ? 'padding-right' : 'padding-left')}: 30px;
                }
            }
            td {
                button {
                    img {
                        width:24px;
                        height:24px;
                    }
                }
            }
        }

    }
    .ant-table-thead{
        >tr{
            >th{
                background: #fff;
                &:first-child{
                    ${({ theme }) => (!theme.rtl ? 'padding-right' : 'padding-left')}: 20px;
                    border-top-left-radius: 10px !important;
                }
                &:last-child{
                    border-top-right-radius: 10px !important;
                }
            }
        }

  
        .ant-table-tbody{
            .ant-table-cell{
                white-space: normal !important;
                text-align: ${({ theme }) => (theme.rtl ? 'right' : 'left')};
            }
            >tr{
                &:hover{
                    box-shadow: 0 15px 40px ${({ theme }) => theme['gray-solid']}20;
                    h1{
                        font-weight: 600;
                        color: ${({ theme }) => theme['dark-color']};
                    a{
                        color: ${({ theme }) => theme['dark-color']};
                    }
                }
                
                >td{
                    background: #fff !important;
                    border-color: #ffffff;
                    
                }
            }
        &.ant-table-row-selected{
            &:hover{
                >td{
                    background: #fff;
                }
            }
            >td{
                background: #fff;
            }
        }
        >td{
            padding: 15px 16px;
            &:last-child{
                text-align: ${({ theme }) => (!theme.rtl ? 'right' : 'left')};
            }
        }
    }
}
.ant-table-thead > tr > th .ant-table-header-column {
    width: 100%;
}
.ant-table-thead > tr:first-child > th:last-child {
    border-top-right-radius: 4px;
    text-align: ${({ theme }) => (!theme.rtl ? 'right' : 'left')};
}
.ant-pagination-item {
    display: none;
}
.ant-table-pagination.ant-pagination {
    position: absolute;
    z-index: 1;
    ${({ theme }) => (!theme.rtl ? 'right' : 'left')}: 0;
}`;

export {
	RevenueWrapper,
	CardBarChart,
	FormValidationWrap,
	CardBarChart2,
	EChartCard,
	PaginationStyle,
	PageHeaderCustom,
	TicketViewCard,
	CardContainer,
	TicketStatus,
    RecentDealsWrapper,
    Action,
    CustomerDetailsWrapper,
    UsercardWrapper,
    UserCard,
    DatePickerStyle,
    ComingsoonStyleWrapper,
    DialPadCSS,
    TableStyle,
};