import React, { lazy } from 'react';
import { Switch, Route,useRouteMatch,Redirect } from 'react-router-dom';
import withAdminLayout from '../../layout/withAdminLayout';

const Dashboard = lazy(() => import('../../container/feedback/Dashboard'));
const TicketList = lazy(() => import('../../container/feedback/TicketList'));
const Report = lazy(() => import('../../container/feedback/Report'));

function NotFound() {
    return <Redirect to="/admin" />;
}

function FeedbackRoutes() {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={`${path}`} component={Dashboard} />
			<Route exact path={`${path}/dashboard`} component={Dashboard} />
            <Route exact path={`${path}/ticket_list`} component={TicketList} />
            <Route exact path={`${path}/report`} component={Report} />
            <Route path="*" exact={true} component={NotFound} />
        </Switch>
    );
}

export default withAdminLayout(FeedbackRoutes);
