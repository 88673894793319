import actions from './actions';

const { 
    INVENTORY_DATA_BEGIN, INVENTORY_DATA_SUCCESS, INVENTORY_DATA_ERR, 
    INVENTORY_REQUEST_DATA_BEGIN , INVENTORY_REQUEST_DATA_SUCCESS, INVENTORY_REQUEST_DATA_ERR,
    INVENTORY_REPORT_DATA_BEGIN, INVENTORY_REPORT_DATA_SUCCESS, INVENTORY_REPORT_DATA_ERR,
    INVENTORY_REPORT_SAVE_DATA_BEGIN, INVENTORY_REPORT_SAVE_DATA_SUCCESS, INVENTORY_REPORT_SAVE_DATA_ERR, 
    INVENTORY_REPORT_DOWNLOAD_DATA_BEGIN, INVENTORY_REPORT_DOWNLOAD_DATA_SUCCESS, INVENTORY_REPORT_DOWNLOAD_DATA_ERR, 
} = actions;

const initialStateFilter = { data: [], loading: false, error: null };
const initialState = { data: null, loading: false, error: null, };

const inventoryReducer = (state = initialStateFilter, action) => {
    const { type, data, err } = action;
    switch (type) {
        case INVENTORY_DATA_BEGIN:
            return {
                ...initialStateFilter,
                loading: true,
            };
        case INVENTORY_DATA_SUCCESS:
            return {
                ...initialStateFilter,
                data,
                loading: false,
            };
        case INVENTORY_DATA_ERR:
            return {
                ...initialStateFilter,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const inventoryRequestReducer = (state = initialStateFilter, action) => {
    const { type, data, err } = action;
    switch (type) {
        case INVENTORY_REQUEST_DATA_BEGIN:
            return {
                ...initialStateFilter,
                loading: true,
            };
        case INVENTORY_REQUEST_DATA_SUCCESS:
            return {
                ...initialStateFilter,
                data,
                loading: false,
            };
        case INVENTORY_REQUEST_DATA_ERR:
            return {
                ...initialStateFilter,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const inventoryReportReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case INVENTORY_REPORT_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case INVENTORY_REPORT_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case INVENTORY_REPORT_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const inventoryReportSaveReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case INVENTORY_REPORT_SAVE_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case INVENTORY_REPORT_SAVE_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case INVENTORY_REPORT_SAVE_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const inventoryReportDownloadReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case INVENTORY_REPORT_DOWNLOAD_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case INVENTORY_REPORT_DOWNLOAD_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case INVENTORY_REPORT_DOWNLOAD_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

export { inventoryReducer,inventoryRequestReducer, inventoryReportReducers, inventoryReportSaveReducers, inventoryReportDownloadReducers };
