import actions from './actions';

const { 
    WALKOUT_DASHBOARD_LEAD_SOURCE_DATA_BEGIN, WALKOUT_DASHBOARD_LEAD_SOURCE_DATA_SUCCESS, WALKOUT_DASHBOARD_LEAD_SOURCE_DATA_ERR,
    WALKOUT_DASHBOARD_CATEGORY_DATA_BEGIN, WALKOUT_DASHBOARD_CATEGORY_DATA_SUCCESS, WALKOUT_DASHBOARD_CATEGORY_DATA_ERR,
    WALKOUT_DASHBOARD_TICKET_DATA_BEGIN, WALKOUT_DASHBOARD_TICKET_DATA_SUCCESS, WALKOUT_DASHBOARD_TICKET_DATA_ERR,
    WALKOUT_DASHBOARD_SUMMARY_DATA_BEGIN, WALKOUT_DASHBOARD_SUMMARY_DATA_SUCCESS, WALKOUT_DASHBOARD_SUMMARY_DATA_ERR,
    WALKOUT_DASHBOARD_REGION_DATA_BEGIN, WALKOUT_DASHBOARD_REGION_DATA_SUCCESS, WALKOUT_DASHBOARD_REGION_DATA_ERR,
    WALKOUT_QUERIES_DATA_BEGIN, WALKOUT_QUERIES_DATA_SUCCESS, WALKOUT_QUERIES_DATA_ERR, 
    WALKOUT_QUERIES_STATUS_DATA_BEGIN, WALKOUT_QUERIES_STATUS_DATA_SUCCESS, WALKOUT_QUERIES_STATUS_DATA_ERR,WALKOUT_QUERIES_STATUS_DATA_RESET, 
    WALKOUT_REASON_DATA_BEGIN, WALKOUT_REASON_DATA_SUCCESS, WALKOUT_REASON_DATA_ERR, 
    WALKOUT_HISTORY_DATA_BEGIN, WALKOUT_HISTORY_DATA_SUCCESS,WALKOUT_HISTORY_DATA_ERR, 
    WALKOUT_DISPOSITION_DATA_BEGIN,WALKOUT_DISPOSITION_DATA_SUCCESS, WALKOUT_DISPOSITION_DATA_ERR, 
    WALKOUT_REPORT_DATA_BEGIN, WALKOUT_REPORT_DATA_SUCCESS, WALKOUT_REPORT_DATA_ERR,
    WALKOUT_REPORT_SAVE_DATA_BEGIN, WALKOUT_REPORT_SAVE_DATA_SUCCESS, WALKOUT_REPORT_SAVE_DATA_ERR, 
    WALKOUT_REPORT_DOWNLOAD_DATA_BEGIN, WALKOUT_REPORT_DOWNLOAD_DATA_SUCCESS, WALKOUT_REPORT_DOWNLOAD_DATA_ERR, 
    WALKOUT_ADD_NOTES_DATA_BEGIN, WALKOUT_ADD_NOTES_DATA_SUCCESS, WALKOUT_ADD_NOTES_DATA_ERR, 
    WALKOUT_LEAD_ASSIGN_DATA_BEGIN, WALKOUT_LEAD_ASSIGN_DATA_SUCCESS, WALKOUT_LEAD_ASSIGN_DATA_ERR, WALKOUT_LEAD_ASSIGN_DATA_RESET, 
    WALKOUT_LEAD_ASSIGN_BULK_DATA_BEGIN, WALKOUT_LEAD_ASSIGN_BULK_DATA_SUCCESS, WALKOUT_LEAD_ASSIGN_BULK_DATA_ERR,  WALKOUT_LEAD_ASSIGN_BULK_DATA_RESET,
} = actions;

const initialState = { data: [], loading: false, error: null, };

const initialStateDashboard = { data: '', start:null, end:null, loading: false, error: null, };

const walkoutDashbaordLeadSourceReducers = (state = initialStateDashboard, action) => {
    const { type, data, start, end, err } = action;
    switch (type) {
        case WALKOUT_DASHBOARD_LEAD_SOURCE_DATA_BEGIN:
            return {
                ...initialStateDashboard,
                loading: true,
            };
        case WALKOUT_DASHBOARD_LEAD_SOURCE_DATA_SUCCESS:
            return {
                ...initialStateDashboard,
                data,
                start,
                end,
                loading: false,
            };
        case WALKOUT_DASHBOARD_LEAD_SOURCE_DATA_ERR:
            return {
                ...initialStateDashboard,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const walkoutDashboardCategoryReducers = (state = initialStateDashboard, action) => {
    const { type, data, start, end, err } = action;
    switch (type) {
        case WALKOUT_DASHBOARD_CATEGORY_DATA_BEGIN:
            return {
                ...initialStateDashboard,
                loading: true,
            };
        case WALKOUT_DASHBOARD_CATEGORY_DATA_SUCCESS:
            return {
                ...initialStateDashboard,
                data,
                start,
                end,
                loading: false,
            };
        case WALKOUT_DASHBOARD_CATEGORY_DATA_ERR:
            return {
                ...initialStateDashboard,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const walkoutDashboardTicketReducers = (state = initialStateDashboard, action) => {
    const { type, data, start, end, err } = action;
    switch (type) {
        case WALKOUT_DASHBOARD_TICKET_DATA_BEGIN:
            return {
                ...initialStateDashboard,
                loading: true,
            };
        case WALKOUT_DASHBOARD_TICKET_DATA_SUCCESS:
            return {
                ...initialStateDashboard,
                data,
                start,
                end,
                loading: false,
            };
        case WALKOUT_DASHBOARD_TICKET_DATA_ERR:
            return {
                ...initialStateDashboard,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const walkoutDashbaordSummaryReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case WALKOUT_DASHBOARD_SUMMARY_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case WALKOUT_DASHBOARD_SUMMARY_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case WALKOUT_DASHBOARD_SUMMARY_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const walkoutDashbaordRegionReducers = (state = initialStateDashboard, action) => {
    const { type, data, start, end, err } = action;
    switch (type) {
        case WALKOUT_DASHBOARD_REGION_DATA_BEGIN:
            return {
                ...initialStateDashboard,
                loading: true,
            };
        case WALKOUT_DASHBOARD_REGION_DATA_SUCCESS:
            return {
                ...initialStateDashboard,
                data,
                start,
                end,
                loading: false,
            };
        case WALKOUT_DASHBOARD_REGION_DATA_ERR:
            return {
                ...initialStateDashboard,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const walkoutEnquiresReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case WALKOUT_QUERIES_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case WALKOUT_QUERIES_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case WALKOUT_QUERIES_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const walkoutEnquiresStatusReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case WALKOUT_QUERIES_STATUS_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case WALKOUT_QUERIES_STATUS_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case WALKOUT_QUERIES_STATUS_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };
        
        case WALKOUT_QUERIES_STATUS_DATA_RESET:
            return initialState;
        default:
            return state;
    }
};

const walkoutReasonReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case WALKOUT_REASON_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case WALKOUT_REASON_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case WALKOUT_REASON_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const walkoutHistoryReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case WALKOUT_HISTORY_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case WALKOUT_HISTORY_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case WALKOUT_HISTORY_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const walkoutDispositionReducer = (state = initialState, action ) =>{
    const { type, data, err } = action;
    switch (type) {
        case WALKOUT_DISPOSITION_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case WALKOUT_DISPOSITION_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                error:null,
                loading: false,
            };
        case WALKOUT_DISPOSITION_DATA_ERR:
            return {
                ...initialState,
                error: err,
                data:null,
                loading: false,
            };

        default:
            return state;
    }
}

const walkoutAddNotesReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case WALKOUT_ADD_NOTES_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case WALKOUT_ADD_NOTES_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case WALKOUT_ADD_NOTES_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const walkoutLeadAssignReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case WALKOUT_LEAD_ASSIGN_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case WALKOUT_LEAD_ASSIGN_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case WALKOUT_LEAD_ASSIGN_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };
        case WALKOUT_LEAD_ASSIGN_DATA_RESET:
            return initialState;
        default:
            return state;
    }
};

const walkoutLeadAssignBulkReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case WALKOUT_LEAD_ASSIGN_BULK_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case WALKOUT_LEAD_ASSIGN_BULK_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case WALKOUT_LEAD_ASSIGN_BULK_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        case WALKOUT_LEAD_ASSIGN_BULK_DATA_RESET:
            return initialState
        default:
            return state;
    }
};

const walkoutReportReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case WALKOUT_REPORT_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case WALKOUT_REPORT_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case WALKOUT_REPORT_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const walkoutReportSaveReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case WALKOUT_REPORT_SAVE_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case WALKOUT_REPORT_SAVE_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case WALKOUT_REPORT_SAVE_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const walkoutReportDownloadReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case WALKOUT_REPORT_DOWNLOAD_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case WALKOUT_REPORT_DOWNLOAD_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case WALKOUT_REPORT_DOWNLOAD_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};



export { walkoutDashbaordLeadSourceReducers, walkoutDashboardCategoryReducers, walkoutDashboardTicketReducers, walkoutDashbaordSummaryReducers, walkoutDashbaordRegionReducers, walkoutEnquiresReducers, walkoutEnquiresStatusReducers, walkoutReasonReducers, walkoutHistoryReducers, walkoutDispositionReducer, walkoutAddNotesReducers, walkoutLeadAssignReducers,walkoutLeadAssignBulkReducers,  walkoutReportReducers, walkoutReportSaveReducers, walkoutReportDownloadReducers  };
