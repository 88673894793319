const actions = {

    MAINTENANCE_DASHBOARD_LEAD_SOURCE_DATA_BEGIN : 'MAINTENANCE_DASHBOARD_LEAD_SOURCE_DATA_BEGIN',
    MAINTENANCE_DASHBOARD_LEAD_SOURCE_DATA_SUCCESS: 'MAINTENANCE_DASHBOARD_LEAD_SOURCE_DATA_SUCCESS',
    MAINTENANCE_DASHBOARD_LEAD_SOURCE_DATA_ERR : 'MAINTENANCE_DASHBOARD_LEAD_SOURCE_DATA_ERR',

    MAINTENANCE_DASHBOARD_CATEGORY_DATA_BEGIN : 'MAINTENANCE_DASHBOARD_CATEGORY_DATA_BEGIN',
    MAINTENANCE_DASHBOARD_CATEGORY_DATA_SUCCESS: 'MAINTENANCE_DASHBOARD_CATEGORY_DATA_SUCCESS',
    MAINTENANCE_DASHBOARD_CATEGORY_DATA_ERR : 'MAINTENANCE_DASHBOARD_CATEGORY_DATA_ERR',

    MAINTENANCE_DASHBOARD_TICKET_DATA_BEGIN : 'MAINTENANCE_DASHBOARD_TICKET_DATA_BEGIN',
    MAINTENANCE_DASHBOARD_TICKET_DATA_SUCCESS: 'MAINTENANCE_DASHBOARD_TICKET_DATA_SUCCESS',
    MAINTENANCE_DASHBOARD_TICKET_DATA_ERR : 'MAINTENANCE_DASHBOARD_TICKET_DATA_ERR',

    MAINTENANCE_DASHBOARD_SUMMARY_DATA_BEGIN : 'MAINTENANCE_DASHBOARD_SUMMARY_DATA_BEGIN',
    MAINTENANCE_DASHBOARD_SUMMARY_DATA_SUCCESS: 'MAINTENANCE_DASHBOARD_SUMMARY_DATA_SUCCESS',
    MAINTENANCE_DASHBOARD_SUMMARY_DATA_ERR : 'MAINTENANCE_DASHBOARD_SUMMARY_DATA_ERR',

    MAINTENANCE_DASHBOARD_REGION_DATA_BEGIN : 'MAINTENANCE_DASHBOARD_REGION_DATA_BEGIN',
    MAINTENANCE_DASHBOARD_REGION_DATA_SUCCESS: 'MAINTENANCE_DASHBOARD_REGION_DATA_SUCCESS',
    MAINTENANCE_DASHBOARD_REGION_DATA_ERR : 'MAINTENANCE_DASHBOARD_REGION_DATA_ERR',

    MAINTENANCE_DEPARTMENT_DATA_BEGIN : 'MAINTENANCE_DEPARTMENT_DATA_BEGIN',
    MAINTENANCE_DEPARTMENT_DATA_SUCCESS: 'MAINTENANCE_DEPARTMENT_DATA_SUCCESS',
    MAINTENANCE_DEPARTMENT_DATA_ERR : 'MAINTENANCE_DEPARTMENT_DATA_ERR',

    MAINTENANCE_HISTORY_DATA_BEGIN : 'MAINTENANCE_HISTORY_DATA_BEGIN',
    MAINTENANCE_HISTORY_DATA_SUCCESS: 'MAINTENANCE_HISTORY_DATA_SUCCESS',
    MAINTENANCE_HISTORY_DATA_ERR : 'MAINTENANCE_HISTORY_DATA_ERR',

    MAINTENANCE_REPORT_DATA_BEGIN : 'MAINTENANCE_REPORT_DATA_BEGIN',
    MAINTENANCE_REPORT_DATA_SUCCESS: 'MAINTENANCE_REPORT_DATA_SUCCESS',
    MAINTENANCE_REPORT_DATA_ERR : 'MAINTENANCE_REPORT_DATA_ERR',

    MAINTENANCE_REPORT_SAVE_DATA_BEGIN : 'MAINTENANCE_REPORT_SAVE_DATA_BEGIN',
    MAINTENANCE_REPORT_SAVE_DATA_SUCCESS: 'MAINTENANCE_REPORT_SAVE_DATA_SUCCESS',
    MAINTENANCE_REPORT_SAVE_DATA_ERR : 'MAINTENANCE_REPORT_SAVE_DATA_ERR',

    MAINTENANCE_REPORT_DOWNLOAD_DATA_BEGIN : 'MAINTENANCE_REPORT_DOWNLOAD_DATA_BEGIN',
    MAINTENANCE_REPORT_DOWNLOAD_DATA_SUCCESS: 'MAINTENANCE_REPORT_DOWNLOAD_DATA_SUCCESS',
    MAINTENANCE_REPORT_DOWNLOAD_DATA_ERR : 'MAINTENANCE_REPORT_DOWNLOAD_DATA_ERR',


    maintenanceDashboardLeadSourceDataBegin: () => {
        return {
            type: actions.MAINTENANCE_DASHBOARD_LEAD_SOURCE_DATA_BEGIN,
        };
    },

    maintenanceDashboardLeadSourceDataSuccess: (data,start,end) => {
        return {
            type: actions.MAINTENANCE_DASHBOARD_LEAD_SOURCE_DATA_SUCCESS,
            data,
            start,
            end,
        };
    },

    maintenanceDashboardLeadSourceDataErr: (err) => {
        return {
            type: actions.MAINTENANCE_DASHBOARD_LEAD_SOURCE_DATA_ERR,
            err,
        };
    },

    maintenanceDashboardRegionDataBegin: () => {
        return {
            type: actions.MAINTENANCE_DASHBOARD_REGION_DATA_BEGIN,
        };
    },

    maintenanceDashboardRegionDataSuccess: (data,start,end) => {
        return {
            type: actions.MAINTENANCE_DASHBOARD_REGION_DATA_SUCCESS,
            data,
            start,
            end,
        };
    },

    maintenanceDashboardRegionDataErr: (err) => {
        return {
            type: actions.MAINTENANCE_DASHBOARD_REGION_DATA_ERR,
            err,
        };
    },

    maintenanceDashboardCategoryDataBegin: () => {
        return {
            type: actions.MAINTENANCE_DASHBOARD_CATEGORY_DATA_BEGIN,
        };
    },

    maintenanceDashboardCategoryDataSuccess: (data,start,end) => {
        return {
            type: actions.MAINTENANCE_DASHBOARD_CATEGORY_DATA_SUCCESS,
            data,
            start,
            end,
        };
    },

    maintenanceDashboardCategoryDataErr: (err) => {
        return {
            type: actions.MAINTENANCE_DASHBOARD_CATEGORY_DATA_ERR,
            err,
        };
    },


    maintenanceDashboardTicketDataBegin: () => {
        return {
            type: actions.MAINTENANCE_DASHBOARD_TICKET_DATA_BEGIN,
        };
    },

    maintenanceDashboardTicketDataSuccess: (data,start,end) => {
        return {
            type: actions.MAINTENANCE_DASHBOARD_TICKET_DATA_SUCCESS,
            data,
            start,
            end,
        };
    },

    maintenanceDashboardTicketDataErr: (err) => {
        return {
            type: actions.MAINTENANCE_DASHBOARD_TICKET_DATA_ERR,
            err,
        };
    },

    maintenanceDashboardSummaryDataBegin: () => {
        return {
            type: actions.MAINTENANCE_DASHBOARD_SUMMARY_DATA_BEGIN,
        };
    },

    maintenanceDashboardSummaryDataSuccess: (data) => {
        return {
            type: actions.MAINTENANCE_DASHBOARD_SUMMARY_DATA_SUCCESS,
            data,
        };
    },

    maintenanceDashboardSummaryDataErr: (err) => {
        return {
            type: actions.MAINTENANCE_DASHBOARD_SUMMARY_DATA_ERR,
            err,
        };
    },

    maintenanceDepartmentDataBegin: () => {
        return {
            type: actions.MAINTENANCE_DEPARTMENT_DATA_BEGIN,
        };
    },

    maintenanceDepartmentDataSuccess: (data) => {
        return {
            type: actions.MAINTENANCE_DEPARTMENT_DATA_SUCCESS,
            data,
        };
    },

    maintenanceDepartmentDataErr: (err) => {
        return {
            type: actions.MAINTENANCE_DEPARTMENT_DATA_ERR,
            err,
        };
    },

    maintenanceHistoryDataBegin: () => {
        return {
            type: actions.MAINTENANCE_HISTORY_DATA_BEGIN,
        };
    },

    maintenanceHistoryDataSuccess: (data) => {
        return {
            type: actions.MAINTENANCE_HISTORY_DATA_SUCCESS,
            data,
        };
    },

    maintenanceHistoryDataErr: (err) => {
        return {
            type: actions.MAINTENANCE_HISTORY_DATA_ERR,
            err,
        };
    },

    maintenanceReportDataBegin: () => {
        return {
            type: actions.MAINTENANCE_REPORT_DATA_BEGIN,
        };
    },

    maintenanceReportDataSuccess: (data) => {
        return {
            type: actions.MAINTENANCE_REPORT_DATA_SUCCESS,
            data,
        };
    },

    maintenanceReportDataErr: (err) => {
        return {
            type: actions.MAINTENANCE_REPORT_DATA_ERR,
            err,
        };
    },

    maintenanceReportSaveDataBegin: () => {
        return {
            type: actions.MAINTENANCE_REPORT_SAVE_DATA_BEGIN,
        };
    },

    maintenanceReportSaveDataSuccess: (data) => {
        return {
            type: actions.MAINTENANCE_REPORT_SAVE_DATA_SUCCESS,
            data,
        };
    },

    maintenanceReportSaveDataErr: (err) => {
        return {
            type: actions.MAINTENANCE_REPORT_SAVE_DATA_ERR,
            err,
        };
    },

    maintenanceReportDownloadDataBegin: () => {
        return {
            type: actions.MAINTENANCE_REPORT_DOWNLOAD_DATA_BEGIN,
        };
    },

    maintenanceReportDownloadDataSuccess: (data) => {
        return {
            type: actions.MAINTENANCE_REPORT_DOWNLOAD_DATA_SUCCESS,
            data,
        };
    },

    maintenanceReportDownloadDataErr: (err) => {
        return {
            type: actions.MAINTENANCE_REPORT_DOWNLOAD_DATA_ERR,
            err,
        };
    },

};

export default actions;
