import React, { lazy } from 'react';
import { Switch, Route,Redirect ,useRouteMatch} from 'react-router-dom';
import withAdminLayout from '../../layout/withAdminLayout';

const Dashboard                 = lazy(() => import('../../container/warranty/Dashboard'));
const RegisteredList            = lazy(() => import('../../container/warranty/RegisteredList'));
const Report                    = lazy(() => import('../../container/warranty/Report'));
const DownloadReport            = lazy(() => import('../../container/warranty/DownloadReport'));

function NotFound() {
    return <Redirect to="/admin" />;
}

function DataRoutes() {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={`${path}`} component={Dashboard} />
			<Route exact path={`${path}/dashboard`} component={Dashboard} />
            <Route exact path={`${path}/list`} component={RegisteredList} />
            {/* <Route exact path={`${path}/demo_register/:id/view`} component={ViewDetails} /> */}
            {/* <Route exact path={`${path}/demo_register/:id/edit`} component={EditDetails} /> */}
            
            <Route exact path={`${path}/report`} component={Report} />
            <Route exact path={`${path}/available_report`} component={DownloadReport} />
            <Route path="*" exact={true} component={NotFound} />
        </Switch>
    );
}

export default withAdminLayout(DataRoutes);