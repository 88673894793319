import Cookies from 'js-cookie';
import actions from './actions';

const { INITIAL, LOGIN_BEGIN, LOGIN_SUCCESS, LOGIN_ERR, LOGOUT_BEGIN, LOGOUT_SUCCESS, LOGOUT_ERR, FORGOT_BEGIN,FORGOT_SUCCESS,FORGOT_ERR,OTP_BEGIN,OTP_SUCCESS,OTP_ERR,CHANGE_BEGIN,CHANGE_SUCCESS,CHANGE_ERR } = actions;

const initState = {
    login: Cookies.get('loggedIn'),
    loading: false,
    error: null,
    loginData:Cookies.get('loggedData')?JSON.parse(Cookies.get('loggedData')):'',
};

const authReducer = (state = initState, action) => {
    const { type, data,loginData, err } = action;
    switch (type) {
        case LOGIN_BEGIN:
            return {
                ...state,
                error:'',
                login:'',
                loginData: '',
                loading: true,
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                login: data,
                loginData: loginData,
                loading: false,
            };
        case LOGIN_ERR:
            return {
                ...state,
                error: err,
                login: '',
                loginData: '',
                loading: false,
            };
        
        case LOGOUT_BEGIN:
            return {
                ...state,
                error:'',
                login: '',
                loginData: '',
                loading: true,
            };
        case LOGOUT_SUCCESS:
            return {
                ...state,
                error:'',
                login: '',
                loginData: '',
                loading: false,
            };
        case LOGOUT_ERR:
            return {
                ...state,
                error: err,
                login: '',
                loginData: '',
                loading: false,
            };
        default:
            return state;
    }
};


const initStateForgot = {
    type:'forgot', 
    loading: false,
    error: null,
    forgot:false,
    otp:false,
    change:false,
};

const forgotReducer = (state = initStateForgot, action) => {
    const { type, data, err, username } = action;
    switch (type) {
        case FORGOT_BEGIN:
            return {
                ...state,
                error:'',
                loading: true,
            };
        case FORGOT_SUCCESS:
            return {
                ...state,
                type:'otp',
                forgot:data,
                username:username,
                loading: false,
            };
        case FORGOT_ERR:
            return {
                ...state,
                error: err,
                loading: false,
            };
        
        case OTP_BEGIN:
            return {
                ...state,
                error:'',
                loading: true,
            };
        case OTP_SUCCESS:
            return {
                ...state,
                type:'change',
                otp:data,
                loading: false,
            };
        case OTP_ERR:
            return {
                ...state,
                error: err,
                loading: false,
            };

        case CHANGE_BEGIN:
            return {
                ...state,
                error:'',
                loading: true,
            };
        case CHANGE_SUCCESS:
            return {
                ...state,
                type:'forgot',
                change:true,
                loading: false,
            };
        case CHANGE_ERR:
            return {
                ...state,
                error: err,
                loading: false,
            };
            
        case INITIAL:
            return initStateForgot;
            
        default:
            return state;
    }
};
export { authReducer, forgotReducer} ;
