import React, { lazy } from 'react';
import { Switch, Route,useRouteMatch,Redirect } from 'react-router-dom';
import withAdminLayout from '../../layout/withAdminLayout';

const Dashboard                 = lazy(() => import('../../container/inventory/Dashboard'));
const InventoryList             = lazy(() => import('../../container/inventory/InventoryList'));
const InventoryRequestList      = lazy(() => import('../../container/inventory/InventoryRequest'));
const SearchProduct             = lazy(() => import('../../container/inventory/SearchProduct'));
const Report                    = lazy(() => import('../../container/inventory/Report'));
const DownloadReport            = lazy(() => import('../../container/inventory/DownloadReport'));
function NotFound() {
    return <Redirect to="/admin" />;
}

function InventoryRoutes() {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={`${path}`} component={Dashboard} />
			<Route exact path={`${path}/dashboard`} component={Dashboard} />
            <Route exact path={`${path}/inventory_list`} component={InventoryList} />
            <Route exact path={`${path}/search_product`} component={SearchProduct} />
            <Route exact path={`${path}/inventory_request`} component={InventoryRequestList} />
            <Route exact path={`${path}/report`} component={Report} />
            <Route exact path={`${path}/available_report`} component={DownloadReport} />
            <Route path="*" exact={true} component={NotFound} />
        </Switch>
    );
}

export default withAdminLayout(InventoryRoutes);
