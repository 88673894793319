import axios from 'axios';
import Cookies from 'js-cookie';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const client = axios.create({
    baseURL: API_ENDPOINT,
    headers: {
        'Content-Type': 'application/json',
    },
});

const clientWithoutToken = axios.create({
    baseURL: API_ENDPOINT,
    headers: {
        'Content-Type': 'application/json',
    },
});

client.interceptors.request.use(async (config) => {
    const requestConfig = config;
    const { headers } = config;
    const response = await axios.get(API_ENDPOINT+'guestToken').catch((error) => {
        console.log(error)
    });

    if(response?.data.redirect === '/dashboard'){

        Cookies.set('loggedIn', true);
        Cookies.set('loggedData', JSON.stringify(response?.data.data));
        window.location.href = '/admin';
    }

    requestConfig.headers = { ...headers, Authorization: `Bearer ${response.data.accessToken}` };
    return requestConfig;
}, (error) => {
    return Promise.reject(error);
});

client.interceptors.response.use(
    response => response,
    error => {
        const { response } = error;
        const originalRequest = error.config;
        if (response) {
            console.log(response);
            if (response.status === 500) {
                // do something here
            } else {
                return originalRequest;
            }
        }
        return Promise.reject(error);
    },
);
   
class GuestService {

    static get(path = '') {
        return client({
            method: 'GET',
            url: path,
            headers: {},
        });
    }

    static post(path = '', data = {}, optionalHeader = {}) {
        return client({
            method: 'POST',
            url: path,
            data,
            headers: {...optionalHeader },
        });
    }

    static patch(path = '', data = {}) {
        return client({
            method: 'PATCH',
            url: path,
            data: JSON.stringify(data),
            headers: {},
        });
    }

    static delete(path = '', data = {}) {
        return clientWithoutToken({
            method: 'DELETE',
            url: path,
            data: JSON.stringify(data),
            headers: {},
        });
    }

    static put(path = '', data = {}) {
        return client({
            method: 'PUT',
            url: path,
            data: JSON.stringify(data),
            headers: {},
        });
    }
}

export { GuestService };