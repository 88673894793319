import React from 'react';
import { NavLink } from 'react-router-dom';
import { Main } from '../container/styled';
import { Button } from '../components/buttons/buttons';
import { ErrorWrapper } from './style';
import Heading from '../components/heading/heading';

const PermissionDenied = () => {

    return (
        <>
            <Main>
                <ErrorWrapper>
                    <img src={require(`../static/img/pages/support.svg`).default} alt="404" />
                    <Heading className="error-text" as="h3">
                        403
                    </Heading>
                    <p>Sorry, you are not authorized to access this page.</p>
                    <NavLink to="/admin">
                    <Button size="default" type="primary" to="/admin">
                        Return Home
                    </Button>
                    </NavLink>
                </ErrorWrapper>
            </Main>
        </>
        
    );
};

export default PermissionDenied;