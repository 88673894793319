import actions from './actions';
import { DataService } from '../../config/data/dataService';
const { 
    channelBegin, channelSuccess, channelErr, 
    liveCallListBegin, liveCallListSuccess, liveCallListErr, 
    callMoniterReportBegin, callMoniterReportSuccess, callMoniterReportErr, 
    callMargeReportBegin, callMargeReportSuccess, callMargeReportErr, 
    incomingVOIPReportBegin, incomingVOIPReportSuccess, incomingVOIPReportErr, 
    signInSignOutReportBegin, signInSignOutReportSuccess, signInSignOutReportErr, 
    callActionDataBegin, callActionDataSuccess, callActionDataErr, 
    hoursReportBegin, hoursReportSuccess, hoursReportErr, 
    requestTypeBegin, requestTypeSuccess, requestTypeErr , 
    requestTypeSubmitBegin, requestTypeSubmitSuccess, requestTypeSubmitErr, 
    requestSubTypeBegin, requestSubTypeSuccess, requestSubTypeErr , 
    requestSubTypeSubmitBegin, requestSubTypeSubmitSuccess, requestSubTypeSubmitErr,
    agentForceLogoutBegin,agentForceLogoutSuccess,agentForceLogoutErr, agentForceLogoutReset,
} = actions;

const getChannelList = () => {
    return async (dispatch) => {
        try {
            dispatch(channelBegin());
            const response = await DataService.get('/getChannel');
            if (response.status === 200 && response.data.status === true) {
                dispatch(channelSuccess(response.data.data));
            } else {
                dispatch(channelErr());
            }
        } catch (err) {
            dispatch(channelErr(err));
        }
    };
}

const getLiveCallList = () => {
    return async (dispatch) => {
        try {
            dispatch(liveCallListBegin());
            const response = await DataService.get('/getLiveCall');
            if (response.status === 200 && response.data.status === true) {
                dispatch(liveCallListSuccess(response.data.data));
            } else {
                dispatch(liveCallListErr());
            }
        } catch (err) {
            dispatch(liveCallListErr(err));
        }
    };
}

const getCallMoniterReport = (data) => {
    return async (dispatch) => {
        try {
            dispatch(callMoniterReportBegin());
            const response = await DataService.post('/getCallReport',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(callMoniterReportSuccess(response.data.data));
            } else {
                dispatch(callMoniterReportErr());
            }
        } catch (err) {
            dispatch(callMoniterReportErr(err));
        }
    };
}

const getCallMargeReport = (data) => {
    return async (dispatch) => {
        try {
            dispatch(callMargeReportBegin());
            const response = await DataService.post('/getCallMargeReport',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(callMargeReportSuccess(response.data.data));
            } else {
                dispatch(callMargeReportErr());
            }
        } catch (err) {
            dispatch(callMargeReportErr(err));
        }
    };
}

const getIncomingVOIPReport = (data) => {
    return async (dispatch) => {
        try {
            dispatch(incomingVOIPReportBegin());
            const response = await DataService.post('/getVOIPIncomingReport',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(incomingVOIPReportSuccess(response.data.data));
            } else {
                dispatch(incomingVOIPReportErr());
            }
        } catch (err) {
            dispatch(incomingVOIPReportErr(err));
        }
    };
}

const getSigninSignOutReport = (data) => {
    return async (dispatch) => {
        try {
            dispatch(signInSignOutReportBegin());
            const response = await DataService.post('/getSignInSignOutReport',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(signInSignOutReportSuccess(response.data.data));
            } else {
                dispatch(signInSignOutReportErr());
            }
        } catch (err) {
            dispatch(signInSignOutReportErr(err));
        }
    };
}

const getHourBasedReport = (data) => {
    return async (dispatch) => {
        try {
            dispatch(hoursReportBegin());
            const response = await DataService.post('/getHoursBasedReport',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(hoursReportSuccess(response.data.data));
            } else {
                dispatch(hoursReportErr());
            }
        } catch (err) {
            dispatch(hoursReportErr(err));
        }
    };
}

const actionCallTrigger = (data) => {
    return async (dispatch) => {
        try {
            dispatch(callActionDataBegin());
            const response = await DataService.post('/callAction',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(callActionDataSuccess(response.data.data));
            } else {
                dispatch(callActionDataErr());
            }
        } catch (err) {
            dispatch(callActionDataErr(err));
        }
    };
}

const getRequestType = (data) => {
    return async (dispatch) => {
        try {
            dispatch(requestTypeBegin());
            const response = await DataService.get('/getRequestType');
            if (response.status === 200 && response.data.status === true) {
                dispatch(requestTypeSuccess(response.data.data));
            } else {
                dispatch(requestTypeErr());
            }
        } catch (err) {
            dispatch(requestTypeErr(err));
        }
    };
}

const submitRequestType = (data) => {
    return async (dispatch) => {
        try {
            dispatch(requestTypeSubmitBegin());
            const response = await DataService.post('/addRequestType', data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(requestTypeSubmitSuccess(response.data.data));
                dispatch(getRequestType());
                // return response.data.data;
            } else {
                dispatch(requestTypeSubmitErr());
            }
        } catch (err) {
            dispatch(requestTypeSubmitErr());
        }
    };
}

const getSubRequestType = (data) => {
    return async (dispatch) => {
        try {
            dispatch(requestSubTypeBegin());
            const response = await DataService.get('/getSubRequestType');
            if (response.status === 200 && response.data.status === true) {
                dispatch(requestSubTypeSuccess(response.data.data));
            } else {
                dispatch(requestSubTypeErr());
            }
        } catch (err) {
            dispatch(requestSubTypeErr(err));
        }
    };
}

const submitSubRequestType = (data) => {
    return async (dispatch) => {
        try {
            dispatch(requestSubTypeSubmitBegin());
            const response = await DataService.post('/addSubRequestType', data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(requestSubTypeSubmitSuccess(response.data.data));
                dispatch(getSubRequestType());
                // return response.data.data;
            } else {
                dispatch(requestSubTypeSubmitErr());
            }
        } catch (err) {
            dispatch(requestSubTypeSubmitErr());
        }
    };
}


const agentForceLogout = (data) => {
    return async (dispatch) => {
        try {
            dispatch(agentForceLogoutBegin());
            const response = await DataService.post('/agentForceLogout', data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(agentForceLogoutSuccess('updated'));
                // return response.data.data;
            } else {
                dispatch(agentForceLogoutErr());
            }
        } catch (err) {
            dispatch(agentForceLogoutErr());
        }
    };
}



const resetAgentForceLogout = (data) => {
    return async (dispatch) => {
        dispatch(agentForceLogoutReset());
    };
}




export { getChannelList, getLiveCallList, getCallMoniterReport, getCallMargeReport, getIncomingVOIPReport, getSigninSignOutReport, getHourBasedReport, actionCallTrigger,  getRequestType, submitRequestType, getSubRequestType, submitSubRequestType, agentForceLogout, resetAgentForceLogout};
