import Styled from 'styled-components';

const EChartCard = Styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    .card-chunk{
        width: 50%;
        flex: 0 0 50%;
    }
    @media only screen and (max-width: 379px){
        .card-chunk{
            width: 100%;
            flex: 0 0 100%;
            h1{
                margin-bottom: 0;
            }
            p{
                margin: 5px 0 20px 0;
            }
        }
    }
    .chartjs-tooltip {
        min-width: 132px !important;
    }
`;

const CardBarChart = Styled.div`
    >div{
        @media only screen and (max-width: 575px) {
            flex-flow: column;
            align-items: flex-start !important;
            ul{
                margin: 0 0 15px;
            }
        }
    }
    .card-bar-top{
        &.flex-grid{
            ${({ theme }) => (theme.rtl ? 'margin-right' : 'margin-left')}: -20px;
            @media only screen and (max-width: 575px) {
                flex-flow: column;
                align-items: center;
            }
            h1{
                font-size: 24px;
                margin-bottom: 22px;
                @media only screen and (max-width: 1199px) {
                    font-size: 20px;
                }
            }
        }
        .flex-grid-child{
            padding: 0 20px;
        }
        p{
            font-size: 14px;
            margin-bottom: 8px;
            color: ${({ theme }) => theme['light-color']};
        }
        h1{
            margin-bottom: 18px;
            sub{
                bottom: 0;
                font-size: 14px;
                ${({ theme }) => (theme.rtl ? 'margin-right' : 'margin-left')}: 8px;
                color: ${({ theme }) => theme['success-color']};
                svg{
                    ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 4px;
                }
            }
        }
    }
    ul{
        margin-top: 15px;
    }
    .chart-dataIndicator{
        li{
            font-size: 13px;
            color: ${({ theme }) => theme['gray-color']};
            &:not(:last-child){
                ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 16px;
            }
        }
    }
    .chartjs-tooltip{
        min-width: 140px !important;
        @media only screen and (max-width: 1199px){
            min-width: 115px !important;
        }
    }
    .deals-barChart{
        display: flex;
        .card-bar-top{
            &:not(:last-child){
                margin-right: 30px;
                ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 30px;
            }
        }
        h4{
            font-weight: 400;
            color: ${({ theme }) => theme['light-gray-color']};
            p{
                &.growth-down{
                    .deal-percentage{
                        color: ${({ theme }) => theme['danger-color']};
                    }
                }
                &.growth-up{
                    .deal-percentage{
                        color: ${({ theme }) => theme['success-color']};
                    }
                }
                .deal-percentage{
                    svg,
                    img,
                    i{
                        margin-right: 3px;
                    }
                }
                .deal-value{
                    font-size: 22px;
                    font-weight: 600;
                    margin-right: 8px;
                    color: ${({ theme }) => theme['dark-color']};
                }
            }
        }
    }
    .deals-list{
        .custom-label{
            font-size: 14px;
            &:not(:last-child){
                ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 30px;
            }
        }
    }
`;

const CardBarChart2 = Styled.div`
    @media only screen and (max-width: 379px){
        text-align: center
    }
    h1{
        margin-bottom: 5px;
    }
    @media only screen and (max-width: 1500px){
        h1{
            font-size: 22px;
        }
    }
    & > span{
        font-size: 14px;
        color: ${({ theme }) => theme['light-gray-color']};
    }
    p{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 21px 0 0 0;
        @media only screen and (max-width: 379px){
            justify-content: center;
        }
        .growth-upward, .growth-downward{
            display: inline-flex;
            align-items: center;
            ${({ theme }) => (!theme.rtl ? 'padding-right' : 'padding-left')}: 10px;
            font-weight: 600;

            svg{
                width: 15px;
            }
        }
        .growth-upward{
            color: ${({ theme }) => theme['success-color']};
            svg{
                color: ${({ theme }) => theme['success-color']};
            }
        }
        .growth-downward{
            color: ${({ theme }) => theme['danger-color']};
            svg{
                color: ${({ theme }) => theme['danger-color']};
            }
        }
        span{
            color: ${({ theme }) => theme['light-gray-color']};
            font-size: 13px;
            display: inline-block;
        }
    }
`;

const RevenueWrapper = Styled.div`

    .chart-label{
        justify-content: flex-start;
        margin-bottom: 26px;
        display: none
    }

    >.ant-card{
        min-height: 455px;
        @media only screen and (max-width: 1599px){
            min-height: 100%;
        }
    }
    .performance-lineChart{
        ul{
            margin: -25px -25px 20px;
        }
        
        &.theme-2{
            .custom-label{
                .current-amount{
                    color: ${({ theme }) => theme.pink};
                }
            }
        }
    }
    .custom-label{
        flex-direction: column;
        align-items: flex-start;
        margin: 25px;
        .current-amount{
            display: block;
            font-size: 24px;
            font-weight: 600;
            color: ${({ theme }) => theme['primary-color']};
        }
        .prev-amount{
            display: block;
            font-size: 24px;
            font-weight: 600;
            color: ${({ theme }) => theme['dark-color']};
        }
        div{
            span{
                display: inline-block;
            }
        }
    }

    .chartjs-tooltip{
        min-width: 166px !important;
        @media only screen and (max-width: 1199px){
            min-width: 150px !important;
        }
    }
`;

const Map = Styled.div`
  margin: 1rem auto;
  width: 300px;

  svg {
    stroke: #fff;

    /* // All layers are just path elements */
    path {
      fill: #a82b2b;
      cursor: pointer;
      outline: none;

      /* // When a layer is hovered */
      &:hover {
        fill: rgba(168, 43, 43, 0.83);
      }

      /* // When a layer is focused. */
      &:focus {
        fill: rgba(168, 43, 43, 0.6);
      }

      /* // When a layer is 'checked' (via checkedLayers prop). */
      &[aria-checked='true'] {
        fill: rgba(56, 43, 168, 1);
      }

      /* // When a layer is 'selected' (via currentLayers prop). */
      &[aria-current='true'] {
        fill: rgba(56, 43, 168, 0.83);
      }

      /* // You can also highlight a specific layer via it's id */
      &[id='nz-can'] {
        fill: rgba(56, 43, 168, 0.6);
      }
    }
  }
`;

const ChartContainer = Styled.div`
    display: block;
    font-family: "Raleway";
    .chart-divider {
        display: block;
        width: 100%;
        height: 100px;
    }
    .chartjs-tooltip {
        opacity: 1;
        position: absolute;
        background: #fff;
        box-shadow: 0 8px 10px #9299B815;
        padding: 10px 12px !important;
        border-radius: 3px;
        border: 1px solid #F1F2F6;
        min-width: 80px;
        -webkit-transition: all 0.5s ease;
        transition: all 0.5s ease;
        pointer-events: none;
        transform: translate(-50%, 5%);
        z-index: 222;
        top: 0;
        left: 0;
        @media only screen and (max-width: 1199px){
            padding: 6px 8px !important;
        }
        &:before {
            position: absolute;
            content: '';
            border-top: 5px solid #fff;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            bottom: -5px;
            ${({ theme }) => (!theme.rtl ? 'left' : 'right')}: 50%;
            transform: translateX(-50%);
        }
    }
    .chartjs-tooltip-key {
        display: inline-block;
        width: 10px;
        height: 10px;
        background: "pink";
        ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}
        : 10px;
    }
    .tooltip-title {
        color: ${({ theme }) => theme['gray-color']};
        font-size: 12px;
        font-weight: 500 !important;
        font-family: 'Inter', sans-serif;
        text-transform: capitalize;
        margin-bottom: 4px;
    }
    .tooltip-value {
        color: #63b963;
        font-size: 22px;
        font-weight: 600 !important;
        font-family: 'Inter', sans-serif;
    }
    .tooltip-value sup {
        font-size: 12px;
        @media only screen and (max-width: 1199px){
            font-size: 11px;
        }
    }
    table{
        tbody{
            td{
                font-size: 13px;
                font-weight: 500;
                padding-bottom: 3px;
                white-space: nowrap;
                color: ${({ theme }) => theme['dark-color']};
                @media only screen and (max-width: 1199px){
                    font-size: 12px;
                }
                .data-label{
                    ${({ theme }) => (theme.rtl ? 'margin-right' : 'margin-left')}: 3px;
                    color: ${({ theme }) => theme['light-gray-color']};
                }
            }
        }
    }
`;

const SentEmailWrapper = Styled.div`
    .ant-card-body{
        padding: 30px 25px !important;
    }
    .sent-emial-chart{
        color: #333;
        canvas{
            margin: 0 auto;
        }
    }
    .sent-emial-data{
        margin-top: 30px;
    }
    .sent-emial-box{
        justify-content: space-between;
        @media only screen and (max-width: 575px){
            align-items: flex-start;
            flex-direction: column;
        }
    }
    .sent-emial-item{
        display: flex;
        @media only screen and (max-width: 575px){
            &:not(:last-child){
                margin-bottom: 15px;
            }
        }
        .sent-emial-icon{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 60px;
            height: 60px;
            border-radius: 10px;
            ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 10px;
            background-color: ${({ theme }) => theme['primary-color']}10;
            &.icon-success{
                background-color: ${({ theme }) => theme['success-color']}10;
            }
            &.icon-primary{
                background-color: ${({ theme }) => theme['primary-color']}10;
            }
            &.icon-warning{
                background-color: ${({ theme }) => theme['warning-color']}10;
            }
        }
        .sent-emial-content{
            flex: auto;
            h4{
                font-size: 20px;
                font-weight: 500;
                margin-bottom: 2px;
                strong{
                    font-weight: 500;
                }
            }
            p{
                font-size: 14px;
                font-weight: 400;
                margin-bottom: 0;
                color: ${({ theme }) => theme['gray-color']};
            }
        }
    }
`;

const RecentDealsWrapper = Styled.div`
    margin-top: 18px;
    min-height: 314px;
    .recent-deals-table{
        .dealing-author{
            display: flex;
            align-items: center;
            img{
                max-width: 36px;
                border-radius: 50%;
            }
            .dealing-author-info{
                ${({ theme }) => (theme.rtl ? 'margin-right' : 'margin-left')}: 12px;
                h4{
                    font-size: 14px;
                    font-weight: 600;
                    margin-bottom: 2px;
                }
                p{
                    font-size: 13px;
                    color: #9297AF;
                    margin-bottom: 0;
                }
            }
        }
        .deal-amount{
            font-size: 14px;
            font-weight: 600;
            color: ${({ theme }) => theme['dark-color']};
        }
        .ant-table-tbody > tr > td{
            padding: 7px 25px;
            border-bottom: 0 none;
        }
    }
`;


const PortalBlock = Styled.div`
    background: #fff;
    padding: 0;
    border-radius: 1.5rem;
    margin: 24px 0px;
    border-bottom: 1px solid #f0f0f0;
    box-shadow: rgb(230, 233, 239) 0.8rem 0.8rem 1.4rem, rgb(244 245 247) -0.2rem -0.2rem 1.8rem;
    .portal-head {
        padding: 16px 34px;
        min-height: 48px;
        margin-bottom: -1px;
        border-radius: 1.5rem 1.5rem 0 0;
        border-bottom: 1px solid #f0f0f0;
        display: flex;
        align-items: center;
        h4 {
            margin-bottom: 0;
            color: #272B41;
            font-weight: 600;
            font-size: 18px;
            color:#fff
        }
    }
    .portal_head_absolute {
        position: absolute;
        .ribbon_label_style_3 {
            position: relative;
            font-weight: 700;
            font-size:16px;
            background: #e8e8e8;
            top: -15px;
            padding: 10px 24px;
            margin-left: 50px;
            color: #fff;
            border-radius: 0 0 0.5em 0.5em;
            
            &:before{
                content: "";
                position: absolute;
                left: -15px;
                right: 0;
                top: 0;
                bottom: 0;
                width: 0;
                height: 0;
                border-bottom: 15px solid;
                border-bottom-color: inherit;
                border-left: 16px solid transparent;
            }
        }
        
    }
    .portal-body {
        display: flex;
        flex-flow: wrap;
        padding: 24px;
    }
`;

const TextContainer = Styled.div`

    font-size: 2em;
    color: transparent;
    background: linear-gradient(45deg, #f06, transparent);
    -webkit-background-clip: text;
    background-clip: text;
`;



export {
    ChartContainer,
    EChartCard,
    CardBarChart,
    CardBarChart2,
    RevenueWrapper,
    PortalBlock,
    Map,
    SentEmailWrapper,
    RecentDealsWrapper,
    TextContainer,
};
