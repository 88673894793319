const getItem = (key) => {
    const data = typeof window !== 'undefined' ? localStorage.getItem(key) : '';
    try {
        return JSON.parse(data);
    } catch (err) {
        return data;
    }
};

const setItem = (key, value) => {
    const stringify = typeof value !== 'string' ? JSON.stringify(value) : value;
    return localStorage.setItem(key, stringify);
};

const removeItem = (key) => {
    localStorage.removeItem(key);
};


const capitalize = (str) => {
    return str ? str.split(/\s+|-|_/).map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ") : null;
}


const warrantyTextConvert = (value) => {
    const warrantyList = [
        {name: 'Brand - In Warranty', id:'brand-warranty'},
        {name: 'Brand - Out of Warranty', id:'brand-out-warranty'},
        {name: 'PAI - EW', id:'pai-ew'},
        {name: 'Tata AIG - EW', id:'tata-aig-ew'},
        {name: 'PAI Service - Out of Warranty', id:'pai-out-w'},
    ];

    const warrantyItem = warrantyList.find(item => item.id === value);
    return warrantyItem ? warrantyItem.name : '';
}

export { getItem, setItem, removeItem, capitalize, warrantyTextConvert };
