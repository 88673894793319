import Styled from 'styled-components';

const Background = Styled.div`
    width: 100%;
    height: 100vh;
    position: relative;
    background-image: url(${props => props.imageUrl});
    background-repeat: no-repeat;
    background-attachment: initial;
    background-position: left top;
    background-size:cover;
    @media only screen and (max-width: 767px){
        height: 100vh;
    }
    .logo-position { 
        text-align: right;
        margin-right:15%;
        @media only screen and (max-width: 767px){
            margin-right:0%;
        }
    }
`;

// const AuthWrapper = Styled.div`
//     height: 100%;
//     padding: 40px;
//     @media only screen and (max-width: 1599px){
//         padding: 25px;
//     }

//     @media only screen and (max-width: 767px){
//         text-align: center;
//     }
//     .auth-notice{
//         text-align: right;
//         font-weight: 500;
//         color: ${({ theme }) => theme['gray-color']};
//         @media only screen and (max-width: 767px){
//             text-align: center;
//             margin-bottom: 10px;
//         }
//     }
//     button{
//         &.btn-signin{
//             min-width: 185px;
//         }
//         &.btn-create{
//             border-radius: 8px;
//             min-width: 205px;
//         }
//         &.btn-reset{
//             border-radius: 8px;
//             min-width: 260px;
//         }
//         &.ant-btn-lg{
//             font-size: 14px;
//             font-weight: 500;
//             height: 48px;
//         }
//     }
//     .auth-contents{
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         form{
//             width: 420px;
//             h1{
//                 font-size: 24px;
//                 font-weight: 600;
//                 margin-bottom: 45px;
//                 @media only screen and (max-width: 767px){
//                     margin-bottom: 28px;
//                 }
//                 input::placeholder{
//                     color: ${({ theme }) => theme['extra-light-color']};
//                 }
//             }
//             .auth-form-action{
//                 margin-bottom: 20px;
//                 display: flex;
//                 justify-content: space-between;
//                 @media only screen and (max-width: 379px){
//                     flex-flow: column;
//                     .forgot-pass-link{
//                         margin-top: 15px;
//                     }
//                 }
//             }
//             .auth-form-action-error{
//                 margin-bottom: 20px;
//             }
//             .forgot-pass-link{
//                 color:#323334;
//                 font-weight:600;
//             }

//         }
//         #forgotPass{
//             .forgot-text{
//                 margin-bottom: 25px;
//             }
//             .return-text{
//                 margin-top: 0px;
//             }
//         }
//         .form-divider{
//             font-size: 13px;
//             color: ${({ theme }) => theme['gray-solid']};
//             text-align: center;
//             position: relative;
//             margin-bottom: 25px;
//             &:before{
//                 content: '';
//                 position: absolute;
//                 width: 100%;
//                 left: 0;
//                 top: 50%;
//                 transform: translateY(-50%);
//                 z-index: 1;
//                 height: 1px;
//                 background: ${({ theme }) => theme['border-color-light']};
//             }
//             span{
//                 background: #fff;
//                 padding: 0 15px;
//                 display: inline-block;
//                 position: relative;
//                 z-index: 2;
//             }
//         }
//     }
// }
// `;

const AuthWrapper = Styled.div`
    height: 100%;
    .auth-contents{
        margin-top:2%;
        margin-right:5%;
        display: flex;
        align-items: center;
        justify-content: center;
        background:#fff;
        float:right;
        padding: 40px 20px;
        border-radius:20px;
        @media only screen and (max-width: 767px){
            float:none;
            margin-right:0%;
        }
        form{
            width: 360px;
            h1{
                font-size: 24px;
                font-weight: 600;
                input::placeholder{
                    color: ${({ theme }) => theme['extra-light-color']};
                }
                .color-secondary {
                    color:#F5821F;
                }
            }

            .ant-form-item-control-input { height:36px; }
            .auth-form-action{
                margin-bottom: 20px;
                display: flex;
                justify-content: space-between;
                @media only screen and (max-width: 379px){
                    flex-flow: column;
                    .forgot-pass-link{
                        margin-top: 15px;
                    }
                }
            }
            .auth-form-action-otp{
                margin-top: 20px;
                margin-bottom: 20px;
                display: flex;
                justify-content: space-between;
                @media only screen and (max-width: 379px){
                    flex-flow: column;
                    .forgot-pass-link{
                        margin-top: 15px;
                    }
                }
            }
            .btn-signin {
                background:#2E3192;
            }
            .auth-form-action-error{
                margin-bottom: 20px;
            }
            .forgot-pass-link{
                color:#323334;
                font-weight:600;
            }

        }
        #forgotPass{
            .forgot-text{
                // margin-bottom: 25px;
            }
            .return-text{
                margin-top: 0px;
            }
        }
        .form-divider{
            font-size: 13px;
            color: ${({ theme }) => theme['gray-solid']};
            text-align: center;
            position: relative;
            margin-bottom: 25px;
            &:before{
                content: '';
                position: absolute;
                width: 100%;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                z-index: 1;
                height: 1px;
                background: ${({ theme }) => theme['border-color-light']};
            }
            span{
                background: #fff;
                padding: 0 15px;
                display: inline-block;
                position: relative;
                z-index: 2;
            }
        }
    }

    @media only screen and (max-width: 767px){
        text-align: center;
        
    }
    
    // .auth-notice{
    //     text-align: right;
    //     font-weight: 500;
    //     color: ${({ theme }) => theme['gray-color']};
    //     @media only screen and (max-width: 767px){
    //         text-align: center;
    //         margin-bottom: 10px;
    //     }
    // }
    
    button{
        &.btn-signin{
            min-width: 185px;
        }
        &.btn-create{
            border-radius: 8px;
            min-width: 205px;
        }
        &.btn-reset{
            border-radius: 8px;
            min-width: 260px;
        }
        &.ant-btn-lg{
            font-size: 14px;
            font-weight: 500;
            height: 48px;
        }
    }
    
}
`;




export { Background, AuthWrapper,  }; //Aside,Content,
