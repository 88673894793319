const actions = {
    DATA_BEGIN: 'DATA_BEGIN',
    DATA_SUCCESS: 'DATA_SUCCESS',
    DATA_ERR: 'DATA_ERR',

    FILTER_PROJECT_BEGIN: 'FILTER_PROJECT_BEGIN',
    FILTER_PROJECT_SUCCESS: 'FILTER_PROJECT_SUCCESS',
    FILTER_PROJECT_ERR: 'FILTER_PROJECT_ERR',

    SORTING_PROJECT_BEGIN: 'SORTING_PROJECT_BEGIN',
    SORTING_PROJECT_SUCCESS: 'SORTING_PROJECT_SUCCESS',
    SORTING_PROJECT_ERR: 'SORTING_PROJECT_ERR',

    SALES_REPORT_DATA_BEGIN : 'SALES_REPORT_DATA_BEGIN',
    SALES_REPORT_DATA_SUCCESS: 'SALES_REPORT_DATA_SUCCESS',
    SALES_REPORT_DATA_ERR : 'SALES_REPORT_DATA_ERR',

    SALES_REPORT_SAVE_DATA_BEGIN : 'SALES_REPORT_SAVE_DATA_BEGIN',
    SALES_REPORT_SAVE_DATA_SUCCESS: 'SALES_REPORT_SAVE_DATA_SUCCESS',
    SALES_REPORT_SAVE_DATA_ERR : 'SALES_REPORT_SAVE_DATA_ERR',

    SALES_REPORT_DOWNLOAD_DATA_BEGIN : 'SALES_REPORT_DOWNLOAD_DATA_BEGIN',
    SALES_REPORT_DOWNLOAD_DATA_SUCCESS: 'SALES_REPORT_DOWNLOAD_DATA_SUCCESS',
    SALES_REPORT_DOWNLOAD_DATA_ERR : 'SALES_REPORT_DOWNLOAD_DATA_ERR',

    dataBegin: () => {
        return {
            type: actions.DATA_BEGIN,
        };
    },

    dataSuccess: (data) => {
        return {
            type: actions.DATA_SUCCESS,
            data,
        };
    },

    dataErr: (err) => {
        return {
            type: actions.DATA_ERR,
            err,
        };
    },

    filterProjectBegin: () => {
        return {
            type: actions.FILTER_PROJECT_BEGIN,
        };
    },

    filterProjectSuccess: (data) => {
        return {
            type: actions.FILTER_PROJECT_SUCCESS,
            data,
        };
    },

    filterProjectErr: (err) => {
        return {
            type: actions.FILTER_PROJECT_ERR,
            err,
        };
    },

    sortingProjectBegin: () => {
        return {
            type: actions.SORTING_PROJECT_BEGIN,
        };
    },

    sortingProjectSuccess: (data) => {
        return {
            type: actions.SORTING_PROJECT_SUCCESS,
            data,
        };
    },

    sortingProjectErr: (err) => {
        return {
            type: actions.SORTING_PROJECT_ERR,
            err,
        };
    },


    salesReportDataBegin: () => {
        return {
            type: actions.SALES_REPORT_DATA_BEGIN,
        };
    },

    salesReportDataSuccess: (data) => {
        return {
            type: actions.SALES_REPORT_DATA_SUCCESS,
            data,
        };
    },

    salesReportDataErr: (err) => {
        return {
            type: actions.SALES_REPORT_DATA_ERR,
            err,
        };
    },

    salesReportSaveDataBegin: () => {
        return {
            type: actions.SALES_REPORT_SAVE_DATA_BEGIN,
        };
    },

    salesReportSaveDataSuccess: (data) => {
        return {
            type: actions.SALES_REPORT_SAVE_DATA_SUCCESS,
            data,
        };
    },

    salesReportSaveDataErr: (err) => {
        return {
            type: actions.SALES_REPORT_SAVE_DATA_ERR,
            err,
        };
    },

    salesReportDownloadDataBegin: () => {
        return {
            type: actions.SALES_REPORT_DOWNLOAD_DATA_BEGIN,
        };
    },

    salesReportDownloadDataSuccess: (data) => {
        return {
            type: actions.SALES_REPORT_DOWNLOAD_DATA_SUCCESS,
            data,
        };
    },

    salesReportDownloadDataErr: (err) => {
        return {
            type: actions.SALES_REPORT_DOWNLOAD_DATA_ERR,
            err,
        };
    },

};

export default actions;
