const actions = {

    PURCHASE_DASHBOARD_LEAD_SOURCE_DATA_BEGIN : 'PURCHASE_DASHBOARD_LEAD_SOURCE_DATA_BEGIN',
    PURCHASE_DASHBOARD_LEAD_SOURCE_DATA_SUCCESS: 'PURCHASE_DASHBOARD_LEAD_SOURCE_DATA_SUCCESS',
    PURCHASE_DASHBOARD_LEAD_SOURCE_DATA_ERR : 'PURCHASE_DASHBOARD_LEAD_SOURCE_DATA_ERR',

    PURCHASE_DASHBOARD_CATEGORY_DATA_BEGIN : 'PURCHASE_DASHBOARD_CATEGORY_DATA_BEGIN',
    PURCHASE_DASHBOARD_CATEGORY_DATA_SUCCESS: 'PURCHASE_DASHBOARD_CATEGORY_DATA_SUCCESS',
    PURCHASE_DASHBOARD_CATEGORY_DATA_ERR : 'PURCHASE_DASHBOARD_CATEGORY_DATA_ERR',

    PURCHASE_DASHBOARD_TICKET_DATA_BEGIN : 'PURCHASE_DASHBOARD_TICKET_DATA_BEGIN',
    PURCHASE_DASHBOARD_TICKET_DATA_SUCCESS: 'PURCHASE_DASHBOARD_TICKET_DATA_SUCCESS',
    PURCHASE_DASHBOARD_TICKET_DATA_ERR : 'PURCHASE_DASHBOARD_TICKET_DATA_ERR',

    PURCHASE_DASHBOARD_SUMMARY_DATA_BEGIN : 'PURCHASE_DASHBOARD_SUMMARY_DATA_BEGIN',
    PURCHASE_DASHBOARD_SUMMARY_DATA_SUCCESS: 'PURCHASE_DASHBOARD_SUMMARY_DATA_SUCCESS',
    PURCHASE_DASHBOARD_SUMMARY_DATA_ERR : 'PURCHASE_DASHBOARD_SUMMARY_DATA_ERR',

    PURCHASE_DASHBOARD_REGION_DATA_BEGIN : 'PURCHASE_DASHBOARD_REGION_DATA_BEGIN',
    PURCHASE_DASHBOARD_REGION_DATA_SUCCESS: 'PURCHASE_DASHBOARD_REGION_DATA_SUCCESS',
    PURCHASE_DASHBOARD_REGION_DATA_ERR : 'PURCHASE_DASHBOARD_REGION_DATA_ERR',

    PURCHASE_QUERIES_DATA_BEGIN : 'PURCHASE_QUERIES_DATA_BEGIN',
    PURCHASE_QUERIES_DATA_SUCCESS: 'PURCHASE_QUERIES_DATA_SUCCESS',
    PURCHASE_QUERIES_DATA_ERR : 'PURCHASE_QUERIES_DATA_ERR',

    PURCHASE_QUERIES_STATUS_DATA_BEGIN : 'PURCHASE_QUERIES_STATUS_DATA_BEGIN',
    PURCHASE_QUERIES_STATUS_DATA_SUCCESS: 'PURCHASE_QUERIES_STATUS_DATA_SUCCESS',
    PURCHASE_QUERIES_STATUS_DATA_ERR : 'PURCHASE_QUERIES_STATUS_DATA_ERR',

    PURCHASE_REASON_DATA_BEGIN : 'PURCHASE_REASON_DATA_BEGIN',
    PURCHASE_REASON_DATA_SUCCESS: 'PURCHASE_REASON_DATA_SUCCESS',
    PURCHASE_REASON_DATA_ERR : 'PURCHASE_REASON_DATA_ERR',

    PURCHASE_LEAD_SOURCE_DATA_BEGIN : 'PURCHASE_LEAD_SOURCE_DATA_BEGIN',
    PURCHASE_LEAD_SOURCE_DATA_SUCCESS: 'PURCHASE_LEAD_SOURCE_DATA_SUCCESS',
    PURCHASE_LEAD_SOURCE_DATA_ERR : 'PURCHASE_LEAD_SOURCE_DATA_ERR',

    PURCHASE_HISTORY_DATA_BEGIN : 'PURCHASE_HISTORY_DATA_BEGIN',
    PURCHASE_HISTORY_DATA_SUCCESS: 'PURCHASE_HISTORY_DATA_SUCCESS',
    PURCHASE_HISTORY_DATA_ERR : 'PURCHASE_HISTORY_DATA_ERR',

    PURCHASE_REPORT_DATA_BEGIN : 'PURCHASE_REPORT_DATA_BEGIN',
    PURCHASE_REPORT_DATA_SUCCESS: 'PURCHASE_REPORT_DATA_SUCCESS',
    PURCHASE_REPORT_DATA_ERR : 'PURCHASE_REPORT_DATA_ERR',

    PURCHASE_REPORT_SAVE_DATA_BEGIN : 'PURCHASE_REPORT_SAVE_DATA_BEGIN',
    PURCHASE_REPORT_SAVE_DATA_SUCCESS: 'PURCHASE_REPORT_SAVE_DATA_SUCCESS',
    PURCHASE_REPORT_SAVE_DATA_ERR : 'PURCHASE_REPORT_SAVE_DATA_ERR',

    PURCHASE_REPORT_DOWNLOAD_DATA_BEGIN : 'PURCHASE_REPORT_DOWNLOAD_DATA_BEGIN',
    PURCHASE_REPORT_DOWNLOAD_DATA_SUCCESS: 'PURCHASE_REPORT_DOWNLOAD_DATA_SUCCESS',
    PURCHASE_REPORT_DOWNLOAD_DATA_ERR : 'PURCHASE_REPORT_DOWNLOAD_DATA_ERR',

    PURCHASE_ADD_NOTES_DATA_BEGIN : 'PURCHASE_ADD_NOTES_DATA_BEGIN',
    PURCHASE_ADD_NOTES_DATA_SUCCESS: 'PURCHASE_ADD_NOTES_DATA_SUCCESS',
    PURCHASE_ADD_NOTES_DATA_ERR : 'PURCHASE_ADD_NOTES_DATA_ERR',

    purchaseDashboardLeadSourceDataBegin: () => {
        return {
            type: actions.PURCHASE_DASHBOARD_LEAD_SOURCE_DATA_BEGIN,
        };
    },

    purchaseDashboardLeadSourceDataSuccess: (data,start,end) => {
        return {
            type: actions.PURCHASE_DASHBOARD_LEAD_SOURCE_DATA_SUCCESS,
            data,
            start,
            end,
        };
    },

    purchaseDashboardLeadSourceDataErr: (err) => {
        return {
            type: actions.PURCHASE_DASHBOARD_LEAD_SOURCE_DATA_ERR,
            err,
        };
    },

    purchaseDashboardRegionDataBegin: () => {
        return {
            type: actions.PURCHASE_DASHBOARD_REGION_DATA_BEGIN,
        };
    },

    purchaseDashboardRegionDataSuccess: (data,start,end) => {
        return {
            type: actions.PURCHASE_DASHBOARD_REGION_DATA_SUCCESS,
            data,
            start,
            end,
        };
    },

    purchaseDashboardRegionDataErr: (err) => {
        return {
            type: actions.PURCHASE_DASHBOARD_REGION_DATA_ERR,
            err,
        };
    },

    purchaseDashboardCategoryDataBegin: () => {
        return {
            type: actions.PURCHASE_DASHBOARD_CATEGORY_DATA_BEGIN,
        };
    },

    purchaseDashboardCategoryDataSuccess: (data,start,end) => {
        return {
            type: actions.PURCHASE_DASHBOARD_CATEGORY_DATA_SUCCESS,
            data,
            start,
            end,
        };
    },

    purchaseDashboardCategoryDataErr: (err) => {
        return {
            type: actions.PURCHASE_DASHBOARD_CATEGORY_DATA_ERR,
            err,
        };
    },


    purchaseDashboardTicketDataBegin: () => {
        return {
            type: actions.PURCHASE_DASHBOARD_TICKET_DATA_BEGIN,
        };
    },

    purchaseDashboardTicketDataSuccess: (data,start,end) => {
        return {
            type: actions.PURCHASE_DASHBOARD_TICKET_DATA_SUCCESS,
            data,
            start,
            end,
        };
    },

    purchaseDashboardTicketDataErr: (err) => {
        return {
            type: actions.PURCHASE_DASHBOARD_TICKET_DATA_ERR,
            err,
        };
    },

    purchaseDashboardSummaryDataBegin: () => {
        return {
            type: actions.PURCHASE_DASHBOARD_SUMMARY_DATA_BEGIN,
        };
    },

    purchaseDashboardSummaryDataSuccess: (data) => {
        return {
            type: actions.PURCHASE_DASHBOARD_SUMMARY_DATA_SUCCESS,
            data,
        };
    },

    purchaseDashboardSummaryDataErr: (err) => {
        return {
            type: actions.PURCHASE_DASHBOARD_SUMMARY_DATA_ERR,
            err,
        };
    },

    purchaseQueriesDataBegin: () => {
        return {
            type: actions.PURCHASE_QUERIES_DATA_BEGIN,
        };
    },

    purchaseQueriesDataSuccess: (data) => {
        return {
            type: actions.PURCHASE_QUERIES_DATA_SUCCESS,
            data,
        };
    },

    purchaseQueriesDataErr: (err) => {
        return {
            type: actions.PURCHASE_QUERIES_DATA_ERR,
            err,
        };
    },

    purchaseQueriesStatusDataBegin: () => {
        return {
            type: actions.PURCHASE_QUERIES_STATUS_DATA_BEGIN,
        };
    },

    purchaseQueriesStatusDataSuccess: (data) => {
        return {
            type: actions.PURCHASE_QUERIES_STATUS_DATA_SUCCESS,
            data,
        };
    },

    purchaseQueriesStatusDataErr: (err) => {
        return {
            type: actions.PURCHASE_QUERIES_STATUS_DATA_ERR,
            err,
        };
    },

    purchaseReasonDataBegin: () => {
        return {
            type: actions.PURCHASE_REASON_DATA_BEGIN,
        };
    },

    purchaseReasonDataSuccess: (data) => {
        return {
            type: actions.PURCHASE_REASON_DATA_SUCCESS,
            data,
        };
    },

    purchaseReasonDataErr: (err) => {
        return {
            type: actions.PURCHASE_REASON_DATA_ERR,
            err,
        };
    },

    purchaseLeadSourceDataBegin: () => {
        return {
            type: actions.PURCHASE_LEAD_SOURCE_DATA_BEGIN,
        };
    },

    purchaseLeadSourceDataSuccess: (data) => {
        return {
            type: actions.PURCHASE_LEAD_SOURCE_DATA_SUCCESS,
            data,
        };
    },

    purchaseLeadSourceDataErr: (err) => {
        return {
            type: actions.PURCHASE_LEAD_SOURCE_DATA_ERR,
            err,
        };
    },

    purchaseHistoryDataBegin: () => {
        return {
            type: actions.PURCHASE_HISTORY_DATA_BEGIN,
        };
    },

    purchaseHistoryDataSuccess: (data) => {
        return {
            type: actions.PURCHASE_HISTORY_DATA_SUCCESS,
            data,
        };
    },

    purchaseHistoryDataErr: (err) => {
        return {
            type: actions.PURCHASE_HISTORY_DATA_ERR,
            err,
        };
    },

    purchaseReportDataBegin: () => {
        return {
            type: actions.PURCHASE_REPORT_DATA_BEGIN,
        };
    },

    purchaseReportDataSuccess: (data) => {
        return {
            type: actions.PURCHASE_REPORT_DATA_SUCCESS,
            data,
        };
    },

    purchaseReportDataErr: (err) => {
        return {
            type: actions.PURCHASE_REPORT_DATA_ERR,
            err,
        };
    },

    purchaseReportSaveDataBegin: () => {
        return {
            type: actions.PURCHASE_REPORT_SAVE_DATA_BEGIN,
        };
    },

    purchaseReportSaveDataSuccess: (data) => {
        return {
            type: actions.PURCHASE_REPORT_SAVE_DATA_SUCCESS,
            data,
        };
    },

    purchaseReportSaveDataErr: (err) => {
        return {
            type: actions.PURCHASE_REPORT_SAVE_DATA_ERR,
            err,
        };
    },

    purchaseReportDownloadDataBegin: () => {
        return {
            type: actions.PURCHASE_REPORT_DOWNLOAD_DATA_BEGIN,
        };
    },

    purchaseReportDownloadDataSuccess: (data) => {
        return {
            type: actions.PURCHASE_REPORT_DOWNLOAD_DATA_SUCCESS,
            data,
        };
    },

    purchaseReportDownloadDataErr: (err) => {
        return {
            type: actions.PURCHASE_REPORT_DOWNLOAD_DATA_ERR,
            err,
        };
    },
    
    purchaseSubmitNotesDataBegin: () => {
        return {
            type: actions.PURCHASE_ADD_NOTES_DATA_BEGIN,
        };
    },

    purchaseSubmitNotesDataSuccess: (data) => {
        return {
            type: actions.PURCHASE_ADD_NOTES_DATA_SUCCESS,
            data,
        };
    },

    purchaseSubmitNotesDataErr: (err) => {
        return {
            type: actions.PURCHASE_ADD_NOTES_DATA_ERR,
            err,
        };
    },
};

export default actions;
