import React, { lazy } from 'react';
import { Switch, Route,Redirect,useRouteMatch } from 'react-router-dom';
import withAdminLayout from '../../layout/withAdminLayout';

const Dashboard = lazy(() => import('../../container/customer/Dashboard'));
const CustomerList = lazy(() => import('../../container/customer/CustomerList'));
const CustomerView = lazy(() => import('../../container/customer/CustomerView'));
const CustomerGroup = lazy(() => import('../../container/customer/CustomerGroup'));
const Report = lazy(() => import('../../container/customer/Report'));
const DownloadReport = lazy(() => import('../../container/customer/DownloadReport'));

function NotFound() {
    return <Redirect to="/admin" />;
}

function CustomerRoutes() {
    const { path } = useRouteMatch();
    return (
        <Switch>
			<Route exact path={`${path}/dashboard`} component={Dashboard} />
            <Route exact path={`${path}/customer_list`} component={CustomerList} />
            <Route exact path={`${path}/view`} component={CustomerView} />
            <Route exact path={`${path}/customer_group`} component={CustomerGroup} />
            <Route exact path={`${path}/report`} component={Report} />
            <Route exact path={`${path}/available_report`} component={DownloadReport} />
            <Route path="*" exact={true} component={NotFound} />
        </Switch>
    );
}

export default withAdminLayout(CustomerRoutes);
