import React from 'react';
import { NavLink } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import PropTypes from 'prop-types';
import { CardFrame } from './style';
import Heading from '../../heading/heading';
import { Dropdown } from '../../dropdown/dropdown';

const Cards = props => {
    const { title, children, more, moreText, size, headless, caption, isbutton, bodyStyle, headStyle, border, bodypadding, } = props;
    
    return (
        <>
            {!headless ? (
                <CardFrame
                    size={size}
                    title={title}
                    bodyStyle={bodyStyle && bodyStyle}
                    headStyle={headStyle && headStyle}
                    bordered={border}
                    bodypadding={bodypadding && bodypadding}
                    extra={
                        <>
                        {more && (
                            <Dropdown content={more} placement="bottomCenter">
                            <NavLink to="#">{!moreText ? <FeatherIcon icon="more-horizontal" /> : 'More'}</NavLink>
                            </Dropdown>
                        )}
                        {isbutton && isbutton}
                        </>
                    }
                    style={{ width: '100%' }}
                >
                    {children}
                </CardFrame>
            ) : (
                <CardFrame
                bodypadding={bodypadding && bodypadding}
                bodyStyle={bodyStyle && bodyStyle}
                size={size}
                style={{ width: '100%' }}
                bordered={border}
                >
                    {title && <Heading as="h4">{title} </Heading>}
                    {caption && <p>{caption}</p>}
                    {children}
                </CardFrame>
            )}
        </>
    );
};

Cards.defaultProps = {
    border: false,
};

Cards.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node]),
    size: PropTypes.string,
    more: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node]),
    bodyStyle: PropTypes.object,
    headStyle: PropTypes.object,
    isbutton: PropTypes.node,
    headless: PropTypes.bool,
    border: PropTypes.bool,
    caption: PropTypes.string,
    bodypadding: PropTypes.string,
    moreText: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.node]),
};

export { Cards };
