import actions from './actions';

const { 
    DATA_BEGIN, DATA_SUCCESS, DATA_ERR,
    STORE_REPORT_DATA_BEGIN, STORE_REPORT_DATA_SUCCESS, STORE_REPORT_DATA_ERR,
    STORE_REPORT_SAVE_DATA_BEGIN, STORE_REPORT_SAVE_DATA_SUCCESS, STORE_REPORT_SAVE_DATA_ERR, 
    STORE_REPORT_DOWNLOAD_DATA_BEGIN, STORE_REPORT_DOWNLOAD_DATA_SUCCESS, STORE_REPORT_DOWNLOAD_DATA_ERR, 
} = actions;

const initialState = { data: [], loading: false, error: null, };

const storeReducer = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };
            
        default:
            return state;
    }
};

const storeReportReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case STORE_REPORT_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case STORE_REPORT_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case STORE_REPORT_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const storeReportSaveReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case STORE_REPORT_SAVE_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case STORE_REPORT_SAVE_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case STORE_REPORT_SAVE_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const storeReportDownloadReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case STORE_REPORT_DOWNLOAD_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case STORE_REPORT_DOWNLOAD_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case STORE_REPORT_DOWNLOAD_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

export { storeReducer, storeReportReducers, storeReportSaveReducers,  storeReportDownloadReducers };
