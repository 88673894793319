import React, { useCallback, useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Heading from '../../heading/heading';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Scrollbars } from 'react-custom-scrollbars';
import { AtbdTopDropdwon1 } from './auth-info-style';
import { Popover } from '../../popup/popup';

import unavailableIcon from '../../../static/img/icon/unavailable.svg';
import availableIcon from '../../../static/img/icon/available.svg';
import breakIcon from '../../../static/img/icon/break.svg';
import lunchIcon from '../../../static/img/icon/lunch.svg';
import meetingIcon from '../../../static/img/icon/meeting.svg';
import trainingIcon from '../../../static/img/icon/training.svg';
import { updateAgentStatus } from '../../../redux/agent/actionCreator';
function CallStatus() {

    const dispatch = useDispatch();

    const [status, setStatus] = useState();

    const statusArray = [
        {
            title:"Available",
            img:availableIcon,
        },
        {
            title:"Unavailable",
            img:unavailableIcon,
        },
        {
            title:"Break",
            img:breakIcon,
        },
        {
            title:"Lunch",
            img:lunchIcon,
        },
        {
            title:"Meeting",
            img:meetingIcon,
        },
        {
            title:"Training",
            img:trainingIcon,
        }
    ]

    const agentStatus = useSelector((state) => state.agentStatus.data);

    console.log(agentStatus);

    useEffect(() => { 

        var iconName = '';
        if(agentStatus?.current_status){
            switch (agentStatus?.current_status) {
                case 'Available':
                    iconName = availableIcon;
                    break;
                case 'Unavailable':
                    iconName = unavailableIcon;
                    break;
                case 'Break':
                    iconName = breakIcon;
                    break;
                case 'Lunch':
                    iconName = lunchIcon;
                    break;
                case 'Meeting':
                    iconName = meetingIcon;
                    break;
                case 'Training':
                    iconName = trainingIcon;
                    break;
                default:
                    iconName = availableIcon;
                    break;
            }
            
            const data = {
                title:agentStatus?.current_status,
                slug:'unavailable',
                img:iconName,
            }
            setStatus(data);
        }
    },[agentStatus])

    //react-hooks/exhaustive-dep
    const handleChange = useCallback((value) => {
        console.log(value);
        setStatus(value);
        dispatch(updateAgentStatus({type:value.title}));
        //next
    },[dispatch])





    const content = (
        <>
            <AtbdTopDropdwon1 >
                <Scrollbars autoHeight autoHeightMax={320} >
                    <div className="atbd-top-dropdwon-menu">
                        <ul className="atbd-top-dropdwon__nav">

                            { statusArray && statusArray.length > 0  &&statusArray.map((value,key) => (
                                <li key={key}>
                                    <Link to="#" className={status?.title === value.title ? 'active' : ''}   onClick={() => handleChange(value)}>
                                        <figure className="atbd-top-dropdwon__content">
                                            <img src={value.img} alt=""  />
                                            <figcaption>
                                                <div className='atbd-top-dropdwonText'>
                                                    <Heading as="h5">
                                                        {value.title === 'Unavailable' ? 'Short Break' : value.title}
                                                    </Heading>
                                                </div>
                                                { status?.title === value.title && (
                                                    <span>
                                                        <FontAwesomeIcon icon="fa-solid fa-check" />
                                                    </span>
                                                )}
                                            </figcaption>
                                        </figure>
                                    </Link>
                                </li>

                            ))}
                        </ul>
                    </div>
                </Scrollbars>
            </AtbdTopDropdwon1>
        </>
    );

    return (
        <div className="support">
            <Popover placement="bottomRight" content={content} action="click">
                <Link to="#" className="head-example" title={status?.title} >
                    {/* <FontAwesomeIcon icon="fa-solid fa-phone-volume" /> */}
                    <img src={status?.img} alt="" width='30' />
                </Link>
            </Popover>
        </div>
    );
}

CallStatus.propTypes = {
    rtl: PropTypes.bool,
};

export default CallStatus;
