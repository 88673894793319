import React, { lazy } from 'react';
import { Switch, Route,useRouteMatch, Redirect } from 'react-router-dom';
import withAdminLayout from '../../layout/withAdminLayout';

const Dashboard = lazy(() => import('../../container/maintenance_employee/Dashboard'));
const WalkoutList = lazy(() => import('../../container/maintenance_employee/TicketList'));

function NotFound() {
    return <Redirect to="/admin" />;
}

function MaintenanceEmployeeRoutes() {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={`${path}`} component={Dashboard} />
			<Route exact path={`${path}/dashboard`} component={Dashboard} />
            <Route exact path={`${path}/mt_tickets`} component={WalkoutList} />
            <Route path="*" exact={true} component={NotFound} />
        </Switch>
    );
}

export default withAdminLayout(MaintenanceEmployeeRoutes);
