import React from 'react';
import { Switch, Route,useRouteMatch } from 'react-router-dom';
import withAdminLayout from '../../layout/withAdminLayout';
import PermissionDenied from '../../layout/PermissionDenied';
import NotFound from '../../layout/NotFound';
import { useSelector } from 'react-redux';

import Dashboard           from '../../container/employee/Dashboard';
import EmployeeList        from '../../container/employee/EmployeeList';
import ISDEmployeeList     from '../../container/employee/ISDEmployeeList';
import CustomEmployeeList  from '../../container/employee/CustomEmployeeList';
import EmployeeView        from '../../container/employee/EmployeeView';
// import EmployeeControl     from '../../container/employee/EmployeeControl';
import Report              from '../../container/employee/Report';
import DownloadReport      from '../../container/employee/DownloadReport';




const checkPermission = (role, requiredRole) => role === requiredRole;

const EmployeeRoutes = (props) => {
    const { path } = useRouteMatch();

    const loginDetails = useSelector(state => state.auth.loginData);
    console.log('Index',path);

    console.log('check',checkPermission(loginDetails?.loginType, 'superadmin'));


    return (
        <Switch>
            {/* New Format */}
            <Route exact path={`${path}/dashboard`} render={(props) => <Dashboard {...props} />} />
            <Route exact path={`${path}/employee_list`} render={(props) =>(<EmployeeList {...props} />) } />
            <Route exact path={`${path}/isd_employee_list`} render={(props) => (<ISDEmployeeList {...props} />) } />
            <Route exact path={`${path}/custom_employee_list`} render={(props) => checkPermission(loginDetails?.loginType, 'superadmin') ? (<CustomEmployeeList {...props} />) : (<PermissionDenied />)}/>
            <Route exact path={`${path}/view`}  render={(props) => (<EmployeeView {...props} />)} />
            {/* <Route exact path={`${path}/employee_control`} render={(props) => (<EmployeeControl {...props} />) } /> */}
            <Route exact path={`${path}/report`} render={(props) => (<Report {...props} />) } />
            <Route exact path={`${path}/available_report`} render={(props) => (<DownloadReport {...props} />) } />
            <Route path="*" exact={true} render={(props) => (<NotFound {...props} />)} />
            
            {/* Old Format */}
            {/* <Route exact path={`${path}/dashboard`} component={Dashboard} />
            <Route exact path={`${path}/employee_list`} component={EmployeeList} />
            <Route exact path={`${path}/isd_employee_list`} component={ISDEmployeeList} />
            <Route exact path={`${path}/custom_employee_list`} component={CustomEmployeeList} />
            <Route exact path={`${path}/view`} component={EmployeeView} />
            <Route exact path={`${path}/employee_control`} component={EmployeeControl} />
            <Route exact path={`${path}/report`} component={Report} />
            <Route exact path={`${path}/available_report`} component={DownloadReport} />
            <Route path="*" exact={true} component={NotFound} /> */}
        </Switch>
    );
}

export default withAdminLayout(EmployeeRoutes);
