import actions from './actions';
import { DataService } from '../../config/data/dataService';
const { 
    categoryDataBegin, categoryDataSuccess, categoryDataErr, 
    brandDataBegin, brandDataSuccess, brandDataErr,
    storeListDataBegin, storeListDataSuccess, storeListDataErr, 
    storePincodeDataBegin, storePincodeDataSuccess, storePincodeDataErr, storePincodeDataReset,
    modelListDataBegin, modelListDataSuccess, modelListDataErr,  
    stockEmployeListDateBegin, stockEmployeListDataSuccess, stockEmployeListDataErr,  
} = actions;

const getCategoryList = (data) => {
    return async (dispatch) => {
        try {
            dispatch(categoryDataBegin());
            const response = await DataService.get('/getCategoryList');
            if (response.status === 200 && response.data.status === true) {
                dispatch(categoryDataSuccess(response.data.data));
            } else {
                dispatch(categoryDataErr());
            }
        } catch (err) {
            dispatch(categoryDataErr(err));
        }
    };
}

const getNewCategoryList = (data) => {
    return async (dispatch) => {
        try {
            dispatch(categoryDataBegin());
            const response = await DataService.get('/getNewCategoryList');
            if (response.status === 200 && response.data.status === true) {
                dispatch(categoryDataSuccess(response.data.data));
            } else {
                dispatch(categoryDataErr());
            }
        } catch (err) {
            dispatch(categoryDataErr(err));
        }
    };
}

const getBrandList = (data) => {
    return async (dispatch) => {
        try {
            dispatch(brandDataBegin());
            const response = await DataService.get('/getBrandList');
            if (response.status === 200 && response.data.status === true) {
                dispatch(brandDataSuccess(response.data.data));
            } else {
                dispatch(brandDataErr());
            }
        } catch (err) {
            dispatch(brandDataErr(err));
        }
    };
}

const getBranchList = (data) => {
    return async (dispatch) => {
        try {
            dispatch(storeListDataBegin());
            const response = await DataService.get('/getBranchList');
            if (response.status === 200 && response.data.status === true) {
                dispatch(storeListDataSuccess(response.data.data));
            } else {
                dispatch(storeListDataErr());
            }
        } catch (err) {
            dispatch(storeListDataErr(err));
        }
    };
}

const getStorePincode = (data) => {
    return async (dispatch) => {
        try {
            dispatch(storePincodeDataBegin());
            const response = await DataService.post('/getStoreListBasedonPincode',{pincode:data});
            if (response.status === 200 && response.data.status === true) {
                dispatch(storePincodeDataSuccess(response.data.data));
            } else {
                dispatch(storePincodeDataErr());
            }
        } catch (err) {
            dispatch(storePincodeDataErr(err));
        }
    };
}

const resetStorePincode = () => {
    return async (dispatch) => {
        dispatch(storePincodeDataReset());
    }
}

const getModelDetails = (data)  => {
    return async (dispatch) => {
        try {
            dispatch(modelListDataBegin());
            const response = await DataService.post('/getModelListBasedonCategoryBrand',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(modelListDataSuccess(response.data.data));
            } else {
                dispatch(modelListDataErr());
            }
        } catch (err) {
            dispatch(modelListDataErr(err));
        }
    };
}

const getNewModelDetails = (data)  => {
    return async (dispatch) => {
        try {
            dispatch(modelListDataBegin());
            const response = await DataService.post('/getNewModelListBasedonCategoryBrand',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(modelListDataSuccess(response.data.data));
            } else {
                dispatch(modelListDataErr());
            }
        } catch (err) {
            dispatch(modelListDataErr(err));
        }
    };
}


const getStockEmployeeList = (data) =>{
    return async (dispatch) => {
        try {
            dispatch(stockEmployeListDateBegin());
            const response = await DataService.post('/getStockEmployeeList',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(stockEmployeListDataSuccess(response.data.data));
            } else {
                dispatch(stockEmployeListDataErr());
            }
        } catch (err) {
            dispatch(stockEmployeListDataErr(err));
        }
    };
}

export { getCategoryList, getNewCategoryList, getBrandList, getBranchList, getStorePincode, resetStorePincode,  getModelDetails, getNewModelDetails, getStockEmployeeList };
