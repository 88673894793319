import React, { lazy } from 'react';
import { Switch, Route,useRouteMatch,Redirect } from 'react-router-dom';
import withAdminLayout from '../../layout/withAdminLayout';

const Dashboard                 = lazy(() => import('../../container/stock/Dashboard'));


const Report                    = lazy(() => import('../../container/stock/Report'));
const DownloadReport            = lazy(() => import('../../container/stock/DownloadReport'));


function NotFound() {
    return <Redirect to="/admin" />;
}


function StockRoutes() {
    const { path } = useRouteMatch();
    
    return (
        <Switch>
            <Route exact path={`${path}`} component={Dashboard} />
            <Route exact path={`${path}/dashboard`} component={Dashboard} />
            {/* <Route exact path={`${path}/check_customer`} component={SearchCustomer} /> */}
            
            <Route exact path={`${path}/report`} component={Report} />
            <Route exact path={`${path}/available_report`} component={DownloadReport} />
            <Route path="*" exact={true} component={NotFound} />
        </Switch>
    );
}

export default withAdminLayout(StockRoutes);
