import actions from './actions';
import { DataService } from '../../config/data/dataService';
const { 
    employeeDataBegin, employeeDataSuccess, employeeDataErr, 
    employeePermissionDataBegin,employeePermissionDataSuccess,employeePermissionDataErr,
    employeeLoginStatusBegin,employeeLoginStatusSuccess,employeeLoginStatusErr, 
    employeeStatusBegin, employeeStatusSuccess, employeeStatusErr, 
    employeePasswordBegin, employeePasswordSuccess, employeePasswordErr, 
    employeeDashboardDataBegin,employeeDashboardDataSuccess,employeeDashboardDataErr,
    employeeReportDataBegin, employeeReportDataSuccess, employeeReportDataErr, 
    employeeReportSaveDataBegin, employeeReportSaveDataSuccess, employeeReportSaveDataErr, 
    employeeReportDownloadDataBegin, employeeReportDownloadDataSuccess, employeeReportDownloadDataErr, 
    employeeSyncDataBegin, employeeSyncDataSuccess, employeeSyncDataErr, 
    employeeCustomStatusBegin,employeeCustomStatusSuccess,employeeCustomStatusErr
} = actions;

const employeeFetchData = (data) => {
    return async (dispatch) => {
        try {
            dispatch(employeeDataBegin());
            const response = await DataService.post('/getAllEmployee',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(employeeDataSuccess(response.data.data));
            } else {
                dispatch(employeeDataErr());
            }
        } catch (err) {
            dispatch(employeeDataErr(err));
        }
    };
};

const updateUserType = (data) =>{
    return async (dispatch) => {
        try {
            dispatch(employeePermissionDataBegin());
            const response = await DataService.post('/updateEmployeePermission',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(employeePermissionDataSuccess(response.data));
            } else {
                dispatch(employeePermissionDataErr());
            }
        } catch (err) {
            dispatch(employeePermissionDataErr(err));
        }
    };
} 

const updateEmployeeLoginStatus = (data) => {
    const modifiedData = { checked:data.is_login_status,id:data.employee_id};

    return async (dispatch) => {
        try {
            dispatch(employeeLoginStatusBegin());
            const response = await DataService.post('/updateEmployeeLoginStatus',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(employeeLoginStatusSuccess(modifiedData));
            } else {
                dispatch(employeeLoginStatusErr());
            }
        } catch (err) {
            dispatch(employeeLoginStatusErr(err));
        }
    };
} 

const updateEmployeeStatus = (data) =>{
    const modifiedData = { checked:data.status,id:data.employee_id};

    return async (dispatch) => {
        try {
            dispatch(employeeStatusBegin());
            const response = await DataService.post('/updateEmployeeStatus',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(employeeStatusSuccess(modifiedData));
            } else {
                dispatch(employeeStatusErr());
            }
        } catch (err) {
            dispatch(employeeStatusErr(err));
        }
    };
} 

const updateEmployeePassword = (data) =>{
    return async (dispatch) => {
        try {
            dispatch(employeePasswordBegin());
            const response = await DataService.post('/updateEmployeePassword',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(employeePasswordSuccess(response.data));
            } else {
                dispatch(employeePasswordErr());
            }
        } catch (err) {
            dispatch(employeePasswordErr(err));
        }
    };
} 

const getEmployeeDashboard = (data) => {
    return async (dispatch) => {
        try {
            dispatch(employeeDashboardDataBegin());
            const response = await DataService.post('/dashboardGetEmployee',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(employeeDashboardDataSuccess(response.data.data,response.data.start,response.data.end));
            } else {
                dispatch(employeeDashboardDataErr());
            }
        } catch (err) {
            dispatch(employeeDashboardDataErr(err));
        }
    };
};

const getEmployeeReport = (data) => {
    return async (dispatch) => {
        try {
            dispatch(employeeReportDataBegin());
            const response = await DataService.post('/getEmployeeReport',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(employeeReportDataSuccess(response.data.data));
            } else {
                dispatch(employeeReportDataErr());
            }
        } catch (err) {
            dispatch(employeeReportDataErr(err));
        }
    };
};

const getEmployeeReportSave = (data) => {
    return async (dispatch) => {
        try {
            dispatch(employeeReportSaveDataBegin());
            const response = await DataService.post('/getEmployeeReportSave',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(employeeReportSaveDataSuccess(response.data.data));
            } else {
                dispatch(employeeReportSaveDataErr());
            }
        } catch (err) {
            dispatch(employeeReportSaveDataErr(err));
        }
    };
};

const getEmployeeReportDownload = (data) => {
    return async (dispatch) => {
        try {
            dispatch(employeeReportDownloadDataBegin());
            const response = await DataService.post('/getEmployeeReportDownload',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(employeeReportDownloadDataSuccess(response.data.data));
            } else {
                dispatch(employeeReportDownloadDataErr());
            }
        } catch (err) {
            dispatch(employeeReportDownloadDataErr(err));
        }
    };
};


const manualEmployeeSync = () => {
    return async (dispatch) => {
        try {
            dispatch(employeeSyncDataBegin());
            const response = await DataService.get('/getManulaSyncEmployee');
            if (response.status === 200 && response.data.status === true) {
                dispatch(employeeSyncDataSuccess(response.data.data));
            } else {
                dispatch(employeeSyncDataErr());
            }
        } catch (err) {
            dispatch(employeeSyncDataErr(err));
        }
    };
}

const createCustomEmployee = (data) =>{
    const modifiedData = { checked:data.status,id:data.employee_id};

    return async (dispatch) => {
        try {
            dispatch(employeeCustomStatusBegin());
            const response = await DataService.post('/createCustomEmployee',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(employeeCustomStatusSuccess(modifiedData));
            } else {
                dispatch(employeeCustomStatusErr());
            }
        } catch (err) {
            dispatch(employeeCustomStatusErr(err));
        }
    };
} 


export { getEmployeeDashboard, employeeFetchData,updateUserType,updateEmployeeStatus,updateEmployeeLoginStatus, updateEmployeePassword, getEmployeeReport, getEmployeeReportSave, getEmployeeReportDownload, manualEmployeeSync, createCustomEmployee  }; //filterByStatus, sortingByCategory
