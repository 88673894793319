const actions = {

    EMPLOYEE_DASHBOARD_DATA_BEGIN: 'EMPLOYEE_DASHBOARD_DATA_BEGIN',
    EMPLOYEE_DASHBOARD_DATA_SUCCESS: 'EMPLOYEE_DASHBOARD_DATA_SUCCESS',
    EMPLOYEE_DASHBOARD_DATA_ERR: 'EMPLOYEE_DASHBOARD_DATA_ERR',

    EMPLOYEE_DATA_BEGIN: 'EMPLOYEE_DATA_BEGIN',
    EMPLOYEE_DATA_SUCCESS: 'EMPLOYEE_DATA_SUCCESS',
    EMPLOYEE_DATA_ERR: 'EMPLOYEE_DATA_ERR',

    EMPLOYEE_USER_TYPE_DATA_BEGIN : 'EMPLOYEE_USER_TYPE_DATA_BEGIN',
    EMPLOYEE_USER_TYPE_DATA_SUCCESS: 'EMPLOYEE_USER_TYPE_DATA_SUCCESS',
    EMPLOYEE_USER_TYPE_DATA_ERR: 'EMPLOYEE_USER_TYPE_DATA_ERR',

    EMPLOYEE_LOGIN_STATUS_BEGIN : 'EMPLOYEE_LOGIN_STATUS_BEGIN',
    EMPLOYEE_LOGIN_STATUS_SUCCESS: 'EMPLOYEE_LOGIN_STATUS_SUCCESS',
    EMPLOYEE_LOGIN_STATUS_ERR: 'EMPLOYEE_LOGIN_STATUS_ERR',

    EMPLOYEE_STATUS_BEGIN : 'EMPLOYEE_STATUS_BEGIN',
    EMPLOYEE_STATUS_SUCCESS: 'EMPLOYEE_STATUS_SUCCESS',
    EMPLOYEE_STATUS_ERR: 'EMPLOYEE_STATUS_ERR',

    EMPLOYEE_PASSWORD_BEGIN : 'EMPLOYEE_PASSWORD_BEGIN',
    EMPLOYEE_PASSWORD_SUCCESS: 'EMPLOYEE_PASSWORD_SUCCESS',
    EMPLOYEE_PASSWORD_ERR: 'EMPLOYEE_PASSWORD_ERR',

    EMPLOYEE_REPORT_DATA_BEGIN : 'EMPLOYEE_REPORT_DATA_BEGIN',
    EMPLOYEE_REPORT_DATA_SUCCESS: 'EMPLOYEE_REPORT_DATA_SUCCESS',
    EMPLOYEE_REPORT_DATA_ERR : 'EMPLOYEE_REPORT_DATA_ERR',

    EMPLOYEE_REPORT_SAVE_DATA_BEGIN : 'EMPLOYEE_REPORT_SAVE_DATA_BEGIN',
    EMPLOYEE_REPORT_SAVE_DATA_SUCCESS: 'EMPLOYEE_REPORT_SAVE_DATA_SUCCESS',
    EMPLOYEE_REPORT_SAVE_DATA_ERR : 'EMPLOYEE_REPORT_SAVE_DATA_ERR',

    EMPLOYEE_REPORT_DOWNLOAD_DATA_BEGIN : 'EMPLOYEE_REPORT_DOWNLOAD_DATA_BEGIN',
    EMPLOYEE_REPORT_DOWNLOAD_DATA_SUCCESS: 'EMPLOYEE_REPORT_DOWNLOAD_DATA_SUCCESS',
    EMPLOYEE_REPORT_DOWNLOAD_DATA_ERR : 'EMPLOYEE_REPORT_DOWNLOAD_DATA_ERR',

    EMPLOYEE_MANUAL_DATA_BEGIN: 'EMPLOYEE_MANUAL_DATA_BEGIN',
    EMPLOYEE_MANUAL_DATA_SUCCESS: 'EMPLOYEE_MANUAL_DATA_SUCCESS',
    EMPLOYEE_MANUAL_DATA_ERR: 'EMPLOYEE_MANUAL_DATA_ERR',

    EMPLOYEE_CREATE_CUSTOM_DATA_BEGIN: 'EMPLOYEE_CREATE_CUSTOM_DATA_BEGIN',
    EMPLOYEE_CREATE_CUSTOM_DATA_SUCCESS: 'EMPLOYEE_CREATE_CUSTOM_DATA_SUCCESS',
    EMPLOYEE_CREATE_CUSTOM_DATA_ERR: 'EMPLOYEE_CREATE_CUSTOM_DATA_ERR',


    employeeDashboardDataBegin : () => {
        return {
            type: actions.EMPLOYEE_DASHBOARD_DATA_BEGIN,
        };
    },
    employeeDashboardDataSuccess: (data) => {
        return {
            type: actions.EMPLOYEE_DASHBOARD_DATA_SUCCESS,
            data,
        };
    },
    employeeDashboardDataErr: (err) => {
        return {
            type: actions.EMPLOYEE_DASHBOARD_DATA_ERR,
            err,
        };
    },

    employeeDataBegin: () => {
        return {
            type: actions.EMPLOYEE_DATA_BEGIN,
        };
    },

    employeeDataSuccess: (data) => {
        return {
            type: actions.EMPLOYEE_DATA_SUCCESS,
            data,
        };
    },

    employeeDataErr: (err) => {
        return {
            type: actions.EMPLOYEE_DATA_ERR,
            err,
        };
    },


    employeePermissionDataBegin: () => {
        return {
            type: actions.EMPLOYEE_USER_TYPE_DATA_BEGIN,
        };
    },

    employeePermissionDataSuccess: (data) => {
        return {
            type: actions.EMPLOYEE_USER_TYPE_DATA_SUCCESS,
            data,
        };
    },

    employeePermissionDataErr: (err) => {
        return {
            type: actions.EMPLOYEE_USER_TYPE_DATA_ERR,
            err,
        };
    },


    employeeLoginStatusBegin: () => {
        return {
            type: actions.EMPLOYEE_LOGIN_STATUS_BEGIN,
        };
    },

    employeeLoginStatusSuccess: (data) => {
        return {
            type: actions.EMPLOYEE_LOGIN_STATUS_SUCCESS,
            data,
        };
    },

    employeeLoginStatusErr: (err) => {
        return {
            type: actions.EMPLOYEE_LOGIN_STATUS_ERR,
            err,
        };
    },

    employeeStatusBegin: () => {
        return {
            type: actions.EMPLOYEE_STATUS_BEGIN,
        };
    },

    employeeStatusSuccess: (data) => {
        return {
            type: actions.EMPLOYEE_STATUS_SUCCESS,
            data,
        };
    },

    employeeStatusErr: (err) => {
        return {
            type: actions.EMPLOYEE_STATUS_ERR,
            err,
        };
    },

    employeePasswordBegin: () => {
        return {
            type: actions.EMPLOYEE_PASSWORD_BEGIN,
        };
    },

    employeePasswordSuccess: (data) => {
        return {
            type: actions.EMPLOYEE_PASSWORD_SUCCESS,
            data,
        };
    },

    employeePasswordErr: (err) => {
        return {
            type: actions.EMPLOYEE_PASSWORD_ERR,
            err,
        };
    },


    employeeReportDataBegin: () => {
        return {
            type: actions.EMPLOYEE_REPORT_DATA_BEGIN,
        };
    },

    employeeReportDataSuccess: (data) => {
        return {
            type: actions.EMPLOYEE_REPORT_DATA_SUCCESS,
            data,
        };
    },

    employeeReportDataErr: (err) => {
        return {
            type: actions.EMPLOYEE_REPORT_DATA_ERR,
            err,
        };
    },

    employeeReportSaveDataBegin: () => {
        return {
            type: actions.EMPLOYEE_REPORT_SAVE_DATA_BEGIN,
        };
    },

    employeeReportSaveDataSuccess: (data) => {
        return {
            type: actions.EMPLOYEE_REPORT_SAVE_DATA_SUCCESS,
            data,
        };
    },

    employeeReportSaveDataErr: (err) => {
        return {
            type: actions.EMPLOYEE_REPORT_SAVE_DATA_ERR,
            err,
        };
    },

    employeeReportDownloadDataBegin: () => {
        return {
            type: actions.EMPLOYEE_REPORT_DOWNLOAD_DATA_BEGIN,
        };
    },

    employeeReportDownloadDataSuccess: (data) => {
        return {
            type: actions.EMPLOYEE_REPORT_DOWNLOAD_DATA_SUCCESS,
            data,
        };
    },

    employeeReportDownloadDataErr: (err) => {
        return {
            type: actions.EMPLOYEE_REPORT_DOWNLOAD_DATA_ERR,
            err,
        };
    },

    employeeSyncDataBegin: () => {
        return {
            type: actions.EMPLOYEE_MANUAL_DATA_BEGIN,
        };
    },

    employeeSyncDataSuccess: (data) => {
        return {
            type: actions.EMPLOYEE_MANUAL_DATA_SUCCESS,
            data,
        };
    },

    employeeSyncDataErr: (err) => {
        return {
            type: actions.EMPLOYEE_MANUAL_DATA_ERR,
            err,
        };
    },

    employeeCustomStatusBegin: () => {
        return {
            type: actions.EMPLOYEE_CREATE_CUSTOM_DATA_BEGIN,
        };
    },

    employeeCustomStatusSuccess: (data) => {
        return {
            type: actions.EMPLOYEE_CREATE_CUSTOM_DATA_SUCCESS,
            data,
        };
    },

    employeeCustomStatusErr: (err) => {
        return {
            type: actions.EMPLOYEE_CREATE_CUSTOM_DATA_ERR,
            err,
        };
    },
    
};

export default actions;
