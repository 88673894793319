import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Background } from './overview/style';

const AuthLayout = (WrapperContent) => {
    
    const WrappedComponent = () => {

        const banner = [
            require('../../static/img/auth/banner-2k.png'),
            require('../../static/img/auth/banner1-2k.png'),
        ]; 

        const [randomImage, setRandomImage] = useState('');
            
        const generateRandomImage = () => {
            const randomIndex = Math.floor(Math.random() * banner.length);
            const randomImageUrl = banner[randomIndex];
            setRandomImage(randomImageUrl);
        };
        
        useEffect(() => {
            generateRandomImage();
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        return(
            <Background  imageUrl={randomImage} >
                <WrapperContent />
            </Background>
        );
    }

    return WrappedComponent;
};

// PropTypes validation for AuthLayout component
AuthLayout.propTypes = {
    WrapperContent: PropTypes.elementType.isRequired, // Ensure WrapperContent is a valid React element
};

export default AuthLayout;