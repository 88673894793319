const actions = {

    INITIAL: 'INITIAL',
    LOGIN_BEGIN: 'LOGIN_BEGIN',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_ERR: 'LOGIN_ERR',
    LOGOUT_BEGIN: 'LOGOUT_BEGIN',
    LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
    LOGOUT_ERR: 'LOGOUT_ERR',


    FORGOT_BEGIN: 'FORGOT_BEGIN',
    FORGOT_SUCCESS: 'FORGOT_SUCCESS',
    FORGOT_ERR: 'FORGOT_ERR',

    OTP_BEGIN: 'OTP_BEGIN',
    OTP_SUCCESS: 'OTP_SUCCESS',
    OTP_ERR: 'OTP_ERR',

    CHANGE_BEGIN: 'CHANGE_BEGIN',
    CHANGE_SUCCESS: 'CHANGE_SUCCESS',
    CHANGE_ERR: 'CHANGE_ERR',

    initial:() => {
        return {
            type: actions.INITIAL,
        };
    },

    loginBegin: () => {
        return {
            type: actions.LOGIN_BEGIN,
        };
    },

    loginSuccess: (data,loginData) => {
        return {
            type: actions.LOGIN_SUCCESS,
            data:data,
            login:data,
            loginData:loginData,
        };
    },

    loginErr: (err) => {
        return {
            type: actions.LOGIN_ERR,
            err,
        };
    },

    logoutBegin: () => {
        return {
            type: actions.LOGOUT_BEGIN,
        };
    },

    logoutSuccess: (data) => {
        return {
            type: actions.LOGOUT_SUCCESS,
            data,
            login:false,
            loginData:'',
        };
    },

    logoutErr: (err) => {
        return {
            type: actions.LOGOUT_ERR,
            err,
        };
    },

    forgotBegin: () => {
        return {
            type: actions.FORGOT_BEGIN,
        };
    },

    forgotSuccess: (data,username) => {
        return {
            type: actions.FORGOT_SUCCESS,
            data:data,
            username:username,
        };
    },

    forgotErr: (err) => {
        
        return {
            type: actions.FORGOT_ERR,
            err,
        };
    },

    otpBegin: () => {
        return {
            type: actions.OTP_BEGIN,
        };
    },

    otpSuccess: (data) => {
        return {
            type: actions.OTP_SUCCESS,
            data:data,
            error:''
        };
    },

    otpErr: (err) => {
        return {
            type: actions.OTP_ERR,
            err,
        };
    },

    changeBegin: () => {
        return {
            type: actions.CHANGE_SUCCESS,
        };
    },

    changeSuccess: (data) => {
        return {
            type: actions.OTP_SUCCESS,
            data:data,
        };
    },

    changeErr: (err) => {
        return {
            type: actions.CHANGE_ERR,
            err,
        };
    },

};

export default actions;
