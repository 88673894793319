import React, { lazy } from 'react';
import { Switch, Route,useRouteMatch, Redirect } from 'react-router-dom';
import withAdminLayout from '../../layout/withAdminLayout';

const Dashboard = lazy(() => import('../../container/maintenance/Dashboard'));
const TicketList = lazy(() => import('../../container/maintenance/TicketList'));
const IssueType = lazy(() => import('../../container/maintenance/IssueType'));
const EmployeeList = lazy(() => import('../../container/maintenance/EmployeeList'));
const Report = lazy(() => import('../../container/maintenance/Report'));
const DownloadReport = lazy(() => import('../../container/maintenance/DownloadReport'));

function NotFound() {
    return <Redirect to="/admin" />;
}

function MaintenanceRoutes() {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={`${path}`} component={Dashboard} />
			<Route exact path={`${path}/dashboard`} component={Dashboard} />
            <Route exact path={`${path}/mt_tickets`} component={TicketList} />
            <Route exact path={`${path}/mt_employee`} component={EmployeeList} />
            <Route exact path={`${path}/mt_issues_type`} component={IssueType} />
            <Route exact path={`${path}/report`} component={Report} />
            <Route exact path={`${path}/available_report`} component={DownloadReport} />
            <Route path="*" exact={true} component={NotFound} />
        </Switch>
    );
}

export default withAdminLayout(MaintenanceRoutes);
